import React from 'react';
import { Link } from 'react-router-dom';
var loadingTimer;

export default class ConnectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            connectMessage: false
        }
    }

    async signMessage() {
        let wallet;
        if (window) {
            if (window.web3) {
                if (this.props.drizzleState) {
                    wallet = this.props.drizzleState.accounts[0].toLowerCase();
                    let z = await this.props.drizzle.web3.eth.personal.sign("Join Game", wallet);
                    this.setState({ connectMessage: `0clm${z}00000O0000${wallet}` })
                }
            }
        }
    }

    render() {
        return (
            <>
                <h1>Connect Page</h1>

                <br />
                <br />
                <br />

                {this.state.connectMessage ?
                    <h1 style={{ color: "black", fontSize: 20 }}>{this.state.connectMessage}</h1 >
                    :
                    <div className="w-button" style={{ float: 'left', minWidth: 0, paddingLeft: 10, paddingRight: 10, marginLet: 20, width: 200 }} onClick={() => { this.signMessage() }}>
                        Generate Code to Connect Discord
                    </div>
                }
            </>
        );
    }
}
