import React from "react";
import AppRouter from './AppRouter';
import { DrizzleContext } from "drizzle-react";

export default class MyDrizzle extends React.Component {
    render() {
        if (this.props.isLocked) {
            return <AppRouter
                isLocked={this.props.isLocked}
                unlockAcct={() => this.props.unlockAcct()} />;
        } else {
            return (
                <DrizzleContext.Consumer>
                    {drizzleContext => {
                        const { drizzle,
                            drizzleState,
                            initialized } = drizzleContext;
                        if (!initialized) {
                            return <AppRouter
                                drizzle={drizzle}
                                isLocked={this.props.isLocked}
                                inCorrectNetwork={true}
                                drizzleState={drizzleState} unlockAcct={() => this.props.unlockAcct()} />;
                        } else {
                            return <AppRouter
                                drizzle={drizzle}
                                isLocked={this.props.isLocked}
                                inCorrectNetwork={false}
                                drizzleState={drizzleState} unlockAcct={() => this.props.unlockAcct()} />;
                        }
                    }}
                </DrizzleContext.Consumer>
            )
        }
    }
}