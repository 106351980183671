import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Helmet } from "react-helmet";
// import * as lang from '../../lang';
import cookie from 'react-cookies'


var user_configuration = {
    shape: "circle", //could be "circle" or "square"
    initial_size: "1px", //initial size of the stars
    final_size: "5px", //final size of the stars after expansion
    expand_speed: "1s", //how fast the stars get bigger, in milliseconds
    fade_delay: "1s", //how long until the star fades out
    fade_duration: "1s", //how long the star fades for
    colors: ["hsla(62, 50%,50%, 0.5)", "rgba(255,255,255,0.5)", "hsla(180, 72%, 52%, 0.5)"], //The variety of colors of the stars. Can be any CSS complient color (eg. HEX, rgba, hsl)
    frequency: 100, //how often a new wave of stars pop-out (in milliseconds. Bigger==longer)
    density: 15,//how many stars pop out per wave
    keep_lit: false, //whether the stars disappear after they are created
    rotation: true, //whether the stars rotate through out their expansion
    coverage: 1, //how much of the element's area the stars will show up in (0-1)
    target_class: '.starlight', //the elements the script will target based on the class name
    custom_svg: "" //if you want to use a custom svg with a shape of a star instead (not supported yet)
};

var advanced_configuration = {
    expand_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    expand_delay: "1s",  //how long until the star starts to expand
    rotation_transition_timing: "linear",  //could be ease, ease-in, ease-out, etc
    rotation_angle: "360deg", //up to how much to rotate to
    rotation_duration: "1s", //how long the rotation will take place
    rotation_delay: "0s", //how long until rotation starts
    fade_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    z_index: 0 //the stars are absolutely positioned, so you can give them a z-index of whatever you wish
};

function Star(width, height) {
    //the offsets are required so that when a user specifies a coverage, the coverage is based around the center of the div, and not the top left
    let leftOffset = Math.round((width - width * user_configuration.coverage) / 2);
    let topOffset = (height - Math.round(height * user_configuration.coverage)) / 2;
    this.xposition = Math.floor(Math.random() * width * user_configuration.coverage) + leftOffset;
    this.yposition = Math.floor(Math.random() * height * user_configuration.coverage) + topOffset;
}

Star.prototype.create = function (parent_element, id) {
    //The container is there so that when the stars expand they exapand around the center
    var star = $('<div></div>');
    var star_container = $('<div id="starlight-star' + id + '"></div>');
    // star_container.attr("id","star"+id);
    star_container.append(star);

    //so the star stays centered as its container expands
    star.css({
        position: "absolute",
        top: "-50%",
        left: "-50%",
        width: "100%",
        height: "100%"
    });

    //the initial CSS properties of the star, including color, position, and size
    star_container.css({
        width: user_configuration.initial_size,
        height: user_configuration.initial_size,
        position: 'absolute',
        top: this.yposition,
        left: this.xposition,
        "z-index": advanced_configuration.z_index
    });

    //sets transition css properties of the star
    setTimeout(function () {
        star_container.css({ //size expand properties
            transition: "height " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay + "," +
                "width " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay,
            width: user_configuration.final_size,
            height: user_configuration.final_size
        });

        //because transition properties override each other, have to create a variable for transition and append transitions on to it
        if (user_configuration.rotation) { //rotation properties
            star.css({
                transform: "rotate(" + advanced_configuration.rotation_angle + ")"
            });
            var transition = advanced_configuration.rotation_duration + " " + advanced_configuration.rotation_transition_timing + " " + advanced_configuration.rotation_delay;
        }

        if (!user_configuration.keep_lit) {//fading properties
            star.css({
                opacity: 0
            });
            if (transition) {
                transition += ",opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }
            else {
                transition = "opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }

            //removes the element from the dom after it fades out
            setTimeout(function () {
                $("#starlight-star" + id).remove();
            }, css_time_to_milliseconds(user_configuration.fade_duration) + css_time_to_milliseconds(user_configuration.fade_delay));
        }

        if (transition) {
            star.css({
                transition: transition
            });
        }

    }, 10);

    //sets shape and color of the star
    if (user_configuration.shape === 'circle') {
        star.css('border-radius', '50%');
    }
    if (user_configuration.custom_svg === '' || user_configuration.custom_svg === false) {
        star.css('background-color', user_configuration.colors[Math.floor(Math.random() * user_configuration.colors.length)]); //picks one of the colors
    }
    parent_element.append(star_container);
};

function css_time_to_milliseconds(time_string) {
    var num = parseFloat(time_string, 10),
        unit = time_string.match(/m?s/),
        milliseconds;

    if (unit) {
        unit = unit[0];
    }

    switch (unit) {
        case "s": // seconds
            milliseconds = num * 1000;
            break;
        case "ms": // milliseconds
            milliseconds = num;
            break;
        default:
            milliseconds = 0;
            break;
    }

    return milliseconds;
}


export default class WhiteListSubmitPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faction: "sun",
            eggs: "1",
            discord: null,
            wallet: null,
            hasErr: false,
            hasSuc: false,
        }
    }

    componentDidMount() {
        var id = 0;
        //traverses all of the elements with a class of 'starlight'
        $(user_configuration.target_class).each(function (index) {
            var currentElement = $(this);
            var width = currentElement.width();
            var height = currentElement.height();
            setInterval(function () { //creates the stars based on the frequency and desired density
                for (var i = 0; i < user_configuration.density; i++) {
                    var newStar = new Star(width, height);
                    newStar.create(currentElement, id);
                    newStar = null; //just in case so the garbage collector clears this value up
                    id++;
                }
            }, user_configuration.frequency);
        });
    }

    captureSign = async () => {
        let f = await this.props.unlockAcct();
        if (!f) {
            alert('tell thenm to connect proper');
            this.setState({ hasErr: true, hasSuc: false })
        } else {
            this.setState({ wallet: f[0], hasErr: false })
        }
    }

    changeFacEgg(e, tar) {
        if (tar === "faction") {
            this.setState({
                faction: e.target.value
            })
        } else if (tar === "eggs") {
            this.setState({
                eggs: e.target.value
            })
        }
    }

    something = async () => {
        let code = false;
        if (this.props.routeProps.location) {
            if (this.props.routeProps.location.search) {
                if (this.props.routeProps.location.search.length > 6) {
                    code = this.props.routeProps.location.search.substring(6)
                }
            }
        }
        let oldCookie = cookie.load('r');
        let hasCookie = false;
        if (oldCookie !== null && oldCookie !== undefined) {
            hasCookie = oldCookie;
        }
        fetch('https://us-central1-woe-2022.cloudfunctions.net/whitelistreg',
            // fetch('http://localhost:3000/faf',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': "application/x-www-form-urlencoded"
                },
                method: "POST",
                body: new URLSearchParams({
                    code: code,
                    faction: this.state.faction,
                    eggs: this.state.eggs,
                    wallet: this.state.wallet,
                    ref: hasCookie // to keep track of who refered the user
                })
            })
            .then(async (res) => {
                console.log(`inside then`);
                console.log(res)
                if (res.status === 200) {
                    this.setState({ hasSuc: true })
                }
            })
            .catch(async (res) => {
                console.log(`inside catch`);
                console.log(res)
            })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>World of Ether | Whitelist</title>
                </Helmet>
                <div id="jumboimgz" className="altJumb">
                    <img id="shipz" src="https://woe2.s3.amazonaws.com/spaceship22.png" alt="Space-ship with smoke" />
                    <img id="moonz" src="https://woe2.s3.amazonaws.com/twoplanets.png" alt="Two moons" />
                    <div id="roadmap">
                        <div className="starlight">
                            <div>
                                <div className="wrap">
                                    <div id="navigation">
                                        <div id="logo">
                                            <Link to="/">
                                                <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                                            </Link>
                                        </div>
                                        <div id="menu-right">
                                            <Link to="/roadmap">
                                                <div className={`mask3 ${this.props.lang}`}>
                                                    <div className="mask-inner">
                                                        ROADMAP
                                                        {/* {lang.collection[this.props.lang]} */}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div id="roadwrap">
                                        <div id="roady3" style={{ marginTop: 75 }} className="thin-wrap">
                                            <h1 className="maintext">
                                                WHITELIST REGISTRY
                                            </h1>
                                            <h1 className="submaintext alternate1">
                                                Gain an advantage by being one of the first players to mint in our multiverse.
                                                Our mint starts free but increases per purchase! So being early is important!
                                            </h1>
                                            {this.state.hasErr ?
                                                <div className="errortext">
                                                    Please Connect a Web3 Wallet
                                                </div>
                                                :
                                                null
                                            }
                                            {this.state.hasSuc ?
                                                <div className="succtext">
                                                    Thanks For Submitting!
                                                </div>
                                                :
                                                null
                                            }
                                            <div id="registrybox">
                                                <div className="af-1">
                                                    <div className="af-label-text">CHOOSE FACTION</div>
                                                    <select name="cars" id="select-drop" onChange={(e) => this.changeFacEgg(e, "faction")}>
                                                        <option value="sun">Sun</option>
                                                        <option value="ocean">Ocean</option>
                                                        <option value="life">Life</option>
                                                        <option value="death">Death</option>
                                                        <option value="astral">Astral</option>
                                                    </select>
                                                </div>
                                                <div className="af-2">
                                                    <div className="af-label-text">EGGS DESIRED</div>
                                                    <select name="cars" id="select-drop" onChange={(e) => this.changeFacEgg(e, "eggs")}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value=">5">{'> 5'}</option>
                                                    </select>
                                                </div>
                                                {this.state.wallet ?
                                                    <div className="reg-but- reg-but-1 green" onClick={() => { }}>CONNECTED</div>
                                                    :
                                                    <div className="reg-but- reg-but-1" onClick={() => this.captureSign()}>REGISTER WALLET</div>
                                                }
                                                {this.state.wallet ?
                                                    <div className="reg-but- reg-but-2" onClick={() => { this.something() }}>SUBMIT</div>
                                                    :
                                                    <div className="reg-but- reg-but-2 noclicky">SUBMIT</div>
                                                }
                                            </div>
                                            <div id="noticeBox">
                                                <img src="https://woe2.s3.amazonaws.com/cInfoicon.png" alt="info icon" />
                                                <div className="someszh">
                                                    Please login to metamask connect to this website and press submit.
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                    <div id="footer" className="imageless">
                                        <div className="footleft">
                                            <Link to="/"><img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" /></Link>
                                        </div>
                                        <div className="footright">
                                            © Copyright 2022
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }


}