import React from "react";
import { _globals } from './GlobalVars';
import { Helmet } from "react-helmet";
import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
// import { getFirestore } from 'firebase/firestore/lite';
import { getFirestore } from 'firebase/firestore'
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import cookie from 'react-cookies'
import BuyPage from './buy/BuyPage';
import HomePage from './home/HomePage';
import FaqPage from './faq/FaqPage';
import WoeDexPage from './woedex/WoeDexPage';
// import TermsPage from './terms/TermsPage';
import NoMatchPage from './noMatch/NoMatchPage';
import InventoryPage from './inventory/InventoryPage';
// import PrivacyPage from './privacy/PrivacyPage';
import RoadmapPage from './roadmap/RoadmapPage';
import WhiteListPage from './whitelist/WhiteListPage';
import WhiteListSubmitPage from './whitelistsubmit/WhiteListSubmitPage';
import LegacyClaimPage from './legacyClaim/LegacyClaimPage';
import PrivatePage from './private/PrivatePage';
import PageList from './pagelist/PageList';
import StakingPage from './staking/StakingPage';
import ActivityPage from "./activity/ActivityPage";
import CreationPage from "./creation/CreationPage";
import PvpPage from "./pvp/PvpPage";
import VaultPage from "./vault/VaultPage";
import AdminPage from "./admin/AdminPage";
import EtherianViewPage from "./etherianView/EtherianViewPage";
import ConnectPage from './connect/ConnectPage';

var createHistory = require("history").createBrowserHistory;

const history = createHistory()
history.listen(location => {
    if (!window.gtag) return
    // console.log('location:', location.hash.substr(2))
    window.gtag('config', 'G-BLG9GJLWZ7', { page_path: location.hash.substr(2) })
})

// track 2022 whitelist
var config = {
    apiKey: "AIzaSyBAMJQxlM2nnM62Bkj0elZysRbs9m9mpVw",
    authDomain: "woe-2022.firebaseapp.com",
    databaseURL: "https://woe-2022-default-rtdb.firebaseio.com",
    projectId: "woe-2022",
    storageBucket: "woe-2022.appspot.com",
    messagingSenderId: "994621457586",
    appId: "1:994621457586:web:bd81e6cbed4e328f40f5ab"
};
// var db;
var config2 = {
    apiKey: "AIzaSyBHBIREzjpdno0mWCLlCnBR6JBFoHo-IEM",
    authDomain: "woe-data-main-bf8d9.firebaseapp.com",
    databaseURL: "https://woe-data-main-bf8d9.firebaseio.com",
    projectId: "woe-data-main-bf8d9",
    storageBucket: "woe-data-main-bf8d9.appspot.com",
    messagingSenderId: "767093458370",
    // appId: "1:994621457586:web:bd81e6cbed4e328f40f5ab"
};

const app = initializeApp(config);
const db = getFirestore(app);

const app2 = initializeApp(config2, "app2");
const db2 = getDatabase(app2);



// const db = getFirestore();
// var config2 = {
//     // user info tracker
//     apiKey: "",
//     authDomain: ".firebaseapp.com",
//     databaseURL: "firebaseio.com",
//     storageBucket: "bucket.appspot.com",
//     projectId: ""
// };

// var usernames = firebase.initializeApp(config2, "usernames");

export default class MyDapp extends React.Component {


    constructor({ match, ...props }) {
        super();
        let queryLang = "en";
        console.log("Detecting navigator language", window.navigator.language.slice(0, 2));
        if (window.navigator.language.slice(0, 2) === 'en') {
            queryLang = "en"
        } else if (window.navigator.language.slice(0, 2) === 'ru') {
            queryLang = "ru";
        } else if (window.navigator.language.slice(0, 2) === 'es') {
            queryLang = "sp"
        } else if (window.navigator.language.slice(0, 2) === 'ja') {
            queryLang = "jp"
        } else if (window.navigator.language.slice(0, 2) === 'zh') {
            queryLang = "ch"
        } else if (window.navigator.language.slice(0, 2) === 'ko') {
            queryLang = "kr"
        }

        let oldCookie = cookie.load('lang');
        if (oldCookie) {
            console.log("loading cookie", oldCookie);
            queryLang = oldCookie
        }

        var url_string = window.location.href;
        var url = new URL(url_string);
        var lang = url.searchParams.get("lang");
        if (lang !== null || lang !== undefined) {
            if (lang === "ru" || lang === "sp" || lang === "jp" || lang === "en" || lang === "ch" || lang === "kr") {
                console.log('loading url parameter for lang')
                queryLang = lang;
                cookie.save('lang', lang);
            }
        }

        var rsource = url.searchParams.get("r");
        if (rsource !== null && rsource !== undefined) {
            cookie.save('r', rsource);
        }

        if (window.gtag) {
            window.gtag('set', 'user_properties', {
                lang: queryLang,
            });
        } else {
            // console.log('cannot set user props for lang as tehre is no window gtag...')
        }
        this.state = {
            showHeader: true,
            lang: queryLang
            // lang: "ru"
            // lang: "sp"
            // lang: "jp"
            // lang: "en"
            // lang: "ch"
            // lang: "kr"

        }
    }


    changeLanguage = (value) => {
        this.setState({
            lang: value
        })

        if (value === "ru" || value === "sp" || value === "jp" || value === "en" || value === "ch" || value === "kr") {
            cookie.save('lang', value);
        }
    }

    dynamicCacheCall(opt, contract, localName, contractVariable, isPrice) {
        if (!opt.drizzle) {
            return "";
        }
        if (opt.state.dataKeys[localName]) {
            let value = opt.drizzleState.contracts[contract][contractVariable][opt.state.dataKeys[localName]];
            if (value !== undefined) {
                if (typeof value.value === "boolean") {
                    if (value.value) {
                        return "true"
                    }
                    return "false"
                } else if (isPrice) {
                    if (value.value === null || value.value === undefined) {
                        return null;
                    }
                    return opt.drizzle.web3.utils.fromWei(value.value, 'ether')
                } else {
                    return value.value
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    render() {
        let dataLocale = process.env.REACT_APP_WOE + "/";
        const pageProps = {
            inCorrectNetwork: this.props.inCorrectNetwork,
            isLocked: this.props.isLocked,
            unlockAcct: this.props.unlockAcct,
            _globals: _globals,
            dataLocale: dataLocale,
            drizzle: this.props.drizzle,
            drizzleState: this.props.drizzleState,
            // usernameDB: usernames,
            dcall: this.dynamicCacheCall,
            lang: this.state.lang,
            changeLanguage: this.changeLanguage,
            db: db,
            db2: db2
        };
        return (
            <>
                <Helmet>
                    <title>World of Ether | A collectible NFT Game!</title>
                </Helmet>
                {/* <Header {...pageProps} showHeader={this.state.showHeader} changeLanguage={this.changeLanguage} /> */}
                <Switch history={history}>
                    {/* <Route exact path="/" component={HomePage} /> */}
                    <Route
                        exact path='/'
                        render={(routeProps) => <HomePage {...pageProps} routeProps={routeProps} />}
                    />
                    <Route
                        exact path='/connect'
                        render={(routeProps) => <ConnectPage {...pageProps} routeProps={routeProps} />}
                    />
                    {/* <Route
                        exact path='/home'
                        render={(routeProps) => <HomePage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    <Route
                        path='/roadmap'
                        render={(routeProps) => <RoadmapPage {...pageProps} routeProps={routeProps} />}
                    />
                    <Route
                        path='/woedex'
                        render={(routeProps) => <WoeDexPage {...pageProps} routeProps={routeProps} />}
                    />
                    <Route
                        path='/woedex/:id'
                        render={(routeProps) => <WoeDexPage {...pageProps} routeProps={routeProps} />}
                    />
                    {/* <Route
                        path='/faq'
                        render={(routeProps) => <FaqPage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    <Route
                        path='/whitelist'
                        render={(routeProps) => <WhiteListPage {...pageProps} routeProps={routeProps} />}
                    />
                    <Route
                        path='/whitelistsubmit'
                        render={(routeProps) => <WhiteListSubmitPage {...pageProps} routeProps={routeProps} />}
                    />
                    <Route
                        path='/legacy'
                        render={(routeProps) => <LegacyClaimPage {...pageProps} routeProps={routeProps} />}
                    />
                    <Route
                        path='/priv'
                        render={(routeProps) => <PrivatePage {...pageProps} routeProps={routeProps} />}
                    />
                    {/* <Route
                        path='/inventory'
                        render={(routeProps) => <InventoryPage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    {/* <Route
                        path='/buy'
                        render={(routeProps) => <BuyPage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    {/* <Route
                        path='/staking'
                        render={(routeProps) => <StakingPage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    {/* <Route
                        path='/activity'
                        render={(routeProps) => <ActivityPage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    <Route
                        path='/creation'
                        render={(routeProps) => <CreationPage {...pageProps} routeProps={routeProps} />}
                    />
                    <Route
                        path='/pvp'
                        render={(routeProps) => <PvpPage {...pageProps} routeProps={routeProps} />}
                    />
                    {/* <Route
                        path='/vault'
                        render={(routeProps) => <VaultPage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    <Route
                        path='/admin'
                        render={(routeProps) => <AdminPage {...pageProps} routeProps={routeProps} />}
                    />
                    {/* <Route
                        path='/etherian/:id'
                        render={(routeProps) => <EtherianViewPage {...pageProps} routeProps={routeProps} />}
                    /> */}
                    {/* 
                        <Route
                            path='/terms'
                            render={(routeProps) => <TermsPage {...pageProps} routeProps={routeProps} />}
                        />
                        <Route
                            path='/privacy'
                            render={(routeProps) => <PrivacyPage {...pageProps} routeProps={routeProps} />}
                        /> 
                    */}
                    <Route
                        render={(routeProps) => <NoMatchPage {...pageProps} routeProps={routeProps} />}
                    />
                    <Redirect to="/" />
                </Switch>
                <NotificationContainer />
            </>
        );
    }
}