import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import * as lang from '../../lang';
import $ from 'jquery';

let changeDropInState = () => { };

let onClickOutside = (e, afunc) => {
    if (!e.target.className.includes('langcontrol')) {
        $('.isActive').removeClass('isActive');
        changeDropInState();
    }
};

export default class FaqPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false
        }
        changeDropInState = () => {
            this.setState({
                isDropdownOpen: false
            })
        }
    }

    componentDidMount() {
        $(function () {
            window.addEventListener("click", onClickOutside);
        })
    }

    componentWillUnmount() {
        window.removeEventListener("click", onClickOutside);
    }

    onTouched(lang) {
        this.props.changeLanguage(lang);
        this.setState({
            isDropdownOpen: false
        })
    }

    openLang() {
        if (this.state.isDropdownOpen) {
            return;
        }
        this.setState({
            isDropdownOpen: true
        })
    }

    renderLang() {
        return null;
        let isActive = "langcontrol ";
        if (this.state.isDropdownOpen) {
            isActive = "langcontrol isActive";
        }
        let arr = ['en', 'ch', 'jp', 'ru', 'kr'];
        let z = this.props.lang;
        let temp = arr.indexOf(z);
        arr.splice(temp, 1);
        arr.unshift(z);

        return (
            <div id="lang-drop" className={isActive} onClick={() => this.openLang()}>
                {
                    arr.map(d => {
                        return (
                            <img className="langcontrol " src={`https://woe2.s3.amazonaws.com/${d}.png`} onClick={() => { this.onTouched(d) }} alt="language icon" />
                        )
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>World of Ether | FAQ</title>
                </Helmet>
                <div className="jumbo smalljumbo allowoverflow">
                    <div className="starlight">
                        <div className="wrap">
                            <div id="navigation">
                                <div id="logo">
                                    <Link to="/">
                                        <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                                    </Link>
                                </div>
                                <div id="menu-right">
                                    {this.renderLang()}
                                    <Link to="/roadmap">
                                        <div className={`mask ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                {lang.roadmap[this.props.lang]}
                                            </div>
                                        </div>
                                    </Link>
                                    {/* <img src="https://woe2.s3.amazonaws.com/metamask.png" alt="metamask icon" /> */}
                                    <Link to="/woedex">
                                        <div className={`mask2 ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                {lang.collection[this.props.lang]}
                                            </div>
                                            {/* <img src="https://woe2.s3.amazonaws.com/metamask.png" alt="metamask icon" /> */}
                                        </div>
                                    </Link>
                                    {/* <div id="mask" className={` ${this.props.lang}`}>
                                        <div id="mask-inner">
                                            {lang.connected[this.props.lang]}
                                        </div>
                                        <img src="https://woe2.s3.amazonaws.com/metamask.png" alt="metamask icon" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="bigztext" className="faqEdit">
                    <div className="wrap">

                        <h1 id="hcenter" className={`${this.props.lang}`}>{lang.helpcenter[this.props.lang]}</h1>
                        <p id="hsubcent" className={`${this.props.lang}`}>{lang.needtoknow[this.props.lang]}</p>
                    </div>
                </div>

                <div id="centerFaq">
                    <div className="containwrapp">
                        <div id="leftFaq">
                            <div className="bubbleBox">
                                <p id="biggah" className={`${this.props.lang}`}>{lang.faqq[this.props.lang]} </p>
                                <p className={`${this.props.lang}`}>{lang.faqexplain[this.props.lang]}</p>
                            </div>
                        </div>
                        <div id="rightFaq">
                            <div className={`question ${this.props.lang}`}><b>{lang.faq_q1[this.props.lang]}</b></div>
                            <div className={`answer ${this.props.lang}`}>{lang.faq_a1[this.props.lang]}</div>

                            <div className={`question ${this.props.lang}`}><b>{lang.faq_q2[this.props.lang]}</b></div>
                            <div className={`answer ${this.props.lang}`}>{lang.faq_a2[this.props.lang]}</div>

                            <div className={`question ${this.props.lang}`}><b>{lang.faq_q3[this.props.lang]}</b></div>
                            <div className={`answer ${this.props.lang}`}>{lang.faq_a3[this.props.lang]}</div>

                            <div className={`question ${this.props.lang}`}><b>{lang.faq_q4[this.props.lang]}</b></div>
                            <div className={`answer ${this.props.lang}`}>{lang.faq_a4[this.props.lang]}</div>

                        </div>
                    </div>
                </div>
                <div id="footer">
                    <div className="footleft">
                        <Link to="/"><img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" /></Link>
                    </div>
                    <div className="footright">
                        © Copyright 2022
                    </div>
                </div>
            </div >
        )
    }
}