import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Helmet } from "react-helmet";

var user_configuration = {
    shape: "circle", //could be "circle" or "square"
    initial_size: "1px", //initial size of the stars
    final_size: "5px", //final size of the stars after expansion
    expand_speed: "1s", //how fast the stars get bigger, in milliseconds
    fade_delay: "1s", //how long until the star fades out
    fade_duration: "1s", //how long the star fades for
    colors: ["hsla(62, 50%,50%, 0.5)", "rgba(255,255,255,0.5)", "hsla(180, 72%, 52%, 0.5)"], //The variety of colors of the stars. Can be any CSS complient color (eg. HEX, rgba, hsl)
    frequency: 100, //how often a new wave of stars pop-out (in milliseconds. Bigger==longer)
    density: 15,//how many stars pop out per wave
    keep_lit: false, //whether the stars disappear after they are created
    rotation: true, //whether the stars rotate through out their expansion
    coverage: 1, //how much of the element's area the stars will show up in (0-1)
    target_class: '.starlight', //the elements the script will target based on the class name
    custom_svg: "" //if you want to use a custom svg with a shape of a star instead (not supported yet)
};

var advanced_configuration = {
    expand_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    expand_delay: "1s",  //how long until the star starts to expand
    rotation_transition_timing: "linear",  //could be ease, ease-in, ease-out, etc
    rotation_angle: "360deg", //up to how much to rotate to
    rotation_duration: "1s", //how long the rotation will take place
    rotation_delay: "0s", //how long until rotation starts
    fade_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    z_index: 0 //the stars are absolutely positioned, so you can give them a z-index of whatever you wish
};

function Star(width, height) {
    //the offsets are required so that when a user specifies a coverage, the coverage is based around the center of the div, and not the top left
    let leftOffset = Math.round((width - width * user_configuration.coverage) / 2);
    let topOffset = (height - Math.round(height * user_configuration.coverage)) / 2;
    this.xposition = Math.floor(Math.random() * width * user_configuration.coverage) + leftOffset;
    this.yposition = Math.floor(Math.random() * height * user_configuration.coverage) + topOffset;
}

Star.prototype.create = function (parent_element, id) {
    //The container is there so that when the stars expand they exapand around the center
    var star = $('<div></div>');
    var star_container = $('<div id="starlight-star' + id + '"></div>');
    // star_container.attr("id","star"+id);
    star_container.append(star);

    //so the star stays centered as its container expands
    star.css({
        position: "absolute",
        top: "-50%",
        left: "-50%",
        width: "100%",
        height: "100%"
    });

    //the initial CSS properties of the star, including color, position, and size
    star_container.css({
        width: user_configuration.initial_size,
        height: user_configuration.initial_size,
        position: 'absolute',
        top: this.yposition,
        left: this.xposition,
        "z-index": advanced_configuration.z_index
    });

    //sets transition css properties of the star
    setTimeout(function () {
        star_container.css({ //size expand properties
            transition: "height " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay + "," +
                "width " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay,
            width: user_configuration.final_size,
            height: user_configuration.final_size
        });

        //because transition properties override each other, have to create a variable for transition and append transitions on to it
        if (user_configuration.rotation) { //rotation properties
            star.css({
                transform: "rotate(" + advanced_configuration.rotation_angle + ")"
            });
            var transition = advanced_configuration.rotation_duration + " " + advanced_configuration.rotation_transition_timing + " " + advanced_configuration.rotation_delay;
        }

        if (!user_configuration.keep_lit) {//fading properties
            star.css({
                opacity: 0
            });
            if (transition) {
                transition += ",opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }
            else {
                transition = "opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }

            //removes the element from the dom after it fades out
            setTimeout(function () {
                $("#starlight-star" + id).remove();
            }, css_time_to_milliseconds(user_configuration.fade_duration) + css_time_to_milliseconds(user_configuration.fade_delay));
        }

        if (transition) {
            star.css({
                transition: transition
            });
        }

    }, 10);

    //sets shape and color of the star
    if (user_configuration.shape === 'circle') {
        star.css('border-radius', '50%');
    }
    if (user_configuration.custom_svg === '' || user_configuration.custom_svg === false) {
        star.css('background-color', user_configuration.colors[Math.floor(Math.random() * user_configuration.colors.length)]); //picks one of the colors
    }
    parent_element.append(star_container);
};

function css_time_to_milliseconds(time_string) {
    var num = parseFloat(time_string, 10),
        unit = time_string.match(/m?s/),
        milliseconds;

    if (unit) {
        unit = unit[0];
    }

    switch (unit) {
        case "s": // seconds
            milliseconds = num * 1000;
            break;
        case "ms": // milliseconds
            milliseconds = num;
            break;
        default:
            milliseconds = 0;
            break;
    }

    return milliseconds;
}

var originalEggs = {
    "0xbb3fb3b9b73a48ba86de794a04b706c6948e2792": 1,
    "0x0bc7d1d00e30afc674e6eb92f371f116f35f2562": 1,
    "0x0f9a6feecdcd6fa7143b0f57db651dac1cecc035": 1,
    "0x1a21196c09b05878932a79492bd0a89b0678c41e": 1,
    "0x2b405f0337647b7e687da9c1d0b9c77b61fd5800": 1,
    "0x8d9df8ea8c6730de05a36e8a082d471bd51cd9ff": 1,
    "0x18ba18655f99d4882d1fde2db2a230e11f838d69": 1,
    "0x22ba64b9dc6480d19cdf54f0d700359759a0a618": 1,
    "0x32e2b1bd1d091df5ad8d3942d653d5fc6e90f596": 1,
    "0x34cd8a21e92b0abd558ff02d6cc7a9e12daf0ff1": 1,
    "0x57c74a0a2af3d9256bef9eb8a826eead8f12274e": 1,
    "0x82fb29c741f6131b9290cd579b789d10a4ae6820": 1,
    "0x398ca5cf715201c8c3ebf301ce8f0ed577a3f258": 1,
    "0x6318cc7b453a0f579dcfbb0967e5de2084ee7250": 1,
    "0x9658524b0130c15172c60e18ebdc8efb615c13c6": 1,
    "0x71750982010577b76a7ec565a8c45d477abca5a6": 1,
    "0xa577d41a1b92a5f4041d1a7d046a4cef3065001f": 1,
    "0xaaa8a090e78724c8e3f3de407205fff160017568": 1,
    "0xbca79742dc51406634491f2c4cee04db69f766f2": 1,
    "0xc5b25b53ceb227f11d4ca44364df4b0c579bb9bb": 1,
    "0xc70b44b5c7abc082863eefa0373506eb31c6f962": 1,
    "0xc445b8d3da4aefd6d593938ce62d0a68f7653ca9": 1,
    "0xcc0626a0144a5f7d05f386b13c28f46b00d44e20": 1,
    "0xcf7176c44ccccebb75ddd709c54f7c3b5813e69e": 1,
    "0xd75807b73c780b3c11e284cb79a8578f8a1431c6": 1,
    "0xde9034487891c474535ae746c5f02a3c7939e09f": 1,
    "0xe04cee2f5466548c72cce6376ab6c9bad7a71dbc": 1,
    "0xec31176d4df0509115abc8065a8a3f8275aaff2b": 1,
    "0xf7ffce2de402d90f8f70468428b09fa89c74a8a8": 1,
    "0xf25fb1e3149982f917eafe033313f0bcf4801268": 1,
    "0xf218ed7e25b2521934d4cfee23c69a2b49ef742c": 1,
    "0xf66261b99d988d51709dc90373917a25ce5dc9cd": 1,
    "0xfb4f025ed50e3a200d520c2cf60a198ecb584d6c": 1,
    "0x0a5c73d3993f64c37a751caf6a4cf3643f0664da": 1,
    "0x0b483e3949d70348a8a661caa5764d454a62b139": 1,
    "0x0b10180d96c87c1e9a57cb570294ed27fb5c8eb8": 1,
    "0x0ef7b57ad55e2dd49d62429c7be05b4f527e64ca": 1,
    "0x0ff9b4a3161cf478e45278a3f649bcda123aaa45": 1,
    "0x01fc1b01beeea6a1bda3bd726214a0ef1a086b1a": 1,
    "0x1ae815c7637b01d472d0760f9031342b528a10b7": 1,
    "0x1b8445b15cdc2a626e47004d7a42161938914391": 1,
    "0x1bf8eb044ab24d3b9cad94816e12c68cff4e820e": 1,
    "0x1ca69321126b5b381c56d4cd5d6a5050b0f77dd6": 1,
    "0x1e3f62d1c1d4c1a048a4c61f84f43fc6a3d8610d": 1,
    "0x1fa68aa5d8458bc5bbfd5082f55b0d749d5edc22": 1,
    "0x2b5b309e6c233c06e766178ec02864d15fc49195": 1,
    "0x2ec0b5e3e208f9d2c7914ca01678af19c35bbeed": 1,
    "0x3b8221a8aa5d5d2dbacc5eb55b6644464dacb7a1": 1,
    "0x3c743e36a11867da560695cedb40792877aefbe7": 1,
    "0x3c9525784badf892f24cf9a252c06db97679986e": 1,
    "0x3e71ff906c276d2ff007412293f98bfdf9f03e5d": 1,
    "0x3ecd6956b50a22b29f619391cba52a6fc05f5dc6": 1,
    "0x3f57d8aa53a8bb9f6ea319a318b60da6101a0bde": 1,
    "0x3f0931a998dff351cf8665d5b02b189af01d211c": 1,
    "0x3f9968796cacb103b1cd35b97280cbbadfa9ceeb": 1,
    "0x4a647cfe38c2d5b45458f123cb918c15f2a6da2b": 1,
    "0x4aa5f5befd2f6d4920c5b5cf0b08e687321bb364": 1,
    "0x4ac974c72be77c1be859942808277d58b27a01f1": 1,
    "0x4b2c74e370347f5568b476fe088cf048cf912cee": 1,
    "0x4b317830e6bd1956b13161e9f73b2f758f4a933f": 1,
    "0x4e3eb6400dac3ca02ce667154e8c4ed2a953c3f5": 1,
    "0x5a11f19949cfc3d6272e83513bbf073841b0933d": 1,
    "0x5bebb3c29c14459b35a35dc6cd91bf1833d52755": 1,
    "0x5d17b17a0c1a08c1923b69f0b8ba6c40da6883d4": 1,
    "0x5e4a19a1762774ac9e53c7a232dea2f768bc6abf": 1,
    "0x5ec7c388045a736d468f8b2449e0f0d84840c7ed": 1,
    "0x5f1dbfa6ef0e0d72f154e89bc177333d59d0ff78": 1,
    "0x6a4ee381d3f4f9491e0a3fafe053c0b6a5cc9c06": 1,
    "0x6a6fd86f0b1cf21271ef69c554e1dad0f892ba07": 1,
    "0x6b0c3acb61f3d634a6a6ba8fdc9425ccbbcc4a7c": 1,
    "0x6c6cd2c672e2f8a89e5bfc6595f1df841db71292": 1,
    "0x6f2900715edcad13e7cdb8eb9603703c6e9021e1": 1,
    "0x7ae29bc6fd3af339228c9a454d0072ab78798561": 1,
    "0x7d5e57b7e17c555342d920b10342399d70810cc6": 1,
    "0x7e76036393c9bc6ffac3ad41a4f4da65013df39b": 1,
    "0x7ecce1ec4a976b92d2d01afb2dd9a52506735cbc": 1,
    "0x7f5128633a40422627ac29776a485be020b8ddc6": 1,
    "0x7fe3dce531424210ca88dc3382c5c863ca7709b8": 1,
    "0x8ee03ddd6e36e0777626f29cc86168e273e28d25": 1,
    "0x8ff54ef017cb43fce0ad82836966c8b9543af1b3": 1,
    "0x09a472c2d494ea174e575e254346020d4bb43430": 1,
    "0x09d568c376be4b8a9905e3693b3ea6dce617c52d": 1,
    "0x9badcaaa7264e410d680497f10545fad81e7a538": 1,
    "0x9c93c4575402195775d4ab5d83877c079723382a": 1,
    "0x9ccc56122233d4d798ca4756a0b9dee20eb075f2": 1,
    "0x9d85a06cb3d5c6e8cfd7674223a346368105d761": 1,
    "0x9d0873afad1a0f8d3a1d822bfeebcca06d9982ae": 1,
    "0x9dc231ec4ac535cee96c9695f285f3daac030fb7": 1,
    "0x9e6f502ae5b0a120a28a76e58fc657576d01eedc": 1,
    "0x9fe4c18674e5ea59fd390ae534795f6d387f307e": 1,
    "0x15ccf022c1d504ee66b345156138178d9d2a0285": 1,
    "0x25f59655bf3ae6eb309c20e609d6320b10aede1b": 1,
    "0x30a8378a02cda749cbafb0023ff5e5fffe3aad74": 1,
    "0x31f2380f76d1393c9177ad3fe4b77c24421e8683": 1,
    "0x34ee65f69aa35732807fcf14ad018ed0f4190fdd": 1,
    "0x46e3224c783917d807058d8aa5acbcc7d025e15a": 1,
    "0x49a2ff214f679ff016424693596f58fa31c072b7": 1,
    "0x53e9cdfec8f40e7f21fd291aa4dcca8c0e760750": 1,
    "0x53fa8427ddc86c146973dfd2b10f8bc8dbba2699": 1,
    "0x55ae96ac1a533fcad4f5648b46961cf0ccecbf7d": 1,
    "0x56dbc5910c0a44e95fd9057a7218bd7799e6d8b0": 1,
    "0x56e1a3f4615544bed019a8b4fb69e27400d1d5ac": 1,
    "0x59a8a935967ef60e46e2039cfc35211f84e8eaaa": 1,
    "0x63a3384927d4b87ac8c704a87bd76b4f274d230a": 1,
    "0x064c1f73f668e7e2d6edacb139daef534c14da66": 1,
    "0x65fdad6fa5ff3d57564e084c9db5669d48b265b2": 1,
    "0x69d72982ec7ba018b68c003d74c135b7331f0e15": 1,
    "0x71ba730f4dfddb5a30d9ab378f73ff9fae363ebb": 1,
    "0x74ceb6230329e58aaba8f11fc10dca1873d0c513": 1,
    "0x74d349c0cb0c3b436b561430778676b1b07aef58": 1,
    "0x75fd825bf7ff908fe91ee62ddbd2b1833a0817e8": 1,
    "0x77cc70d4fba75bb97c70a7344e87e5e706bc1f83": 1,
    "0x078ce7771db31ef289e25df6cef4c8ccf4294840": 1,
    "0x78c97961f7a75b85b737b42769bb8ccca3fdc6b6": 1,
    "0x85f9a071b93a80869c1f672c4eb161df9623389d": 1,
    "0x90aab51a7f859e9d6534d5fa7becf217039fbf3d": 1,
    "0x92f396f09f8e5aaa75d25767c5a1d2326b321900": 1,
    "0x95cb6c11ca8f8cf711d06dc05e9f5eb2da7e8caf": 1,
    "0x96d092a25cf15a53760ea3e38255f29e7f56af39": 1,
    "0x099a7e4b6f305e85f5a7818d9fdc4f9fc0fbd0fd": 1,
    "0x150d91d0f4a335245e461714fa5b173cead21b9d": 1,
    "0x151f317f7b1cf10d3c1b11df73d5e5b0886f1232": 1,
    "0x262efb8e4389203fa0dce1e1d26f7dee6130eaa2": 1,
    "0x357a2f0eb4b388dbfaea61af3d61fba1f04966f6": 1,
    "0x399ed75bc8b59dc715163c52125ebf67cf15c305": 1,
    "0x404c9f3d065d34e6ed7f0269907fd0b5c6f886d1": 1,
    "0x436ee2bf58accecade640776276baa769d64b41d": 1,
    "0x594caf23aa91ed201976e0e05e759cd7f94bf1a2": 1,
    "0x617e8b7c70163a3cc626952ba3d7656e0d652447": 1,
    "0x639f4873d88357c57d70f1188d7752bff6de2a89": 1,
    "0x714d9e76ec5b0de01ad2faa9c61d6e5f47649599": 1,
    "0x839b997b987e27252a4e2e0ef1cbaef67c0b40c4": 1,
    "0x844d16bcfbff22662470a97f7cd1d3a8ef490cf9": 1,
    "0x851ac2c68c176182324159d0feae727ff59ed051": 1,
    "0x904b8e2552e2e3582a7b4b7842763e781f406d10": 1,
    "0x951d48864f7376ddbb34e7c0089fe0ce1236e109": 1,
    "0x992fd2b61539b70e0a6e2ed4a0c3aeab6a7275ea": 1,
    "0x2585bd1e46e43f39ca4e780a52f324433f0cd331": 1,
    "0x3531fd1049f926237943c6867ecbf9743a6e12d1": 1,
    "0x4500fe8ae8308f1af7e71660307a3c70b39fee75": 1,
    "0x6469b766e833c3795ecc184bb40af4fb322f0157": 1,
    "0x6559ec2f9144c581a79a321209521452cad91271": 1,
    "0x6906a5d282b5ae6533eb4b639094023a262ad084": 1,
    "0x7533c4c1d881aaa75d4b039325866b2f8b8c5ec9": 1,
    "0x9345bc010331511a4cecba325a5dbeaa4c771ea5": 1,
    "0x22605f824bc04b05974773edc9c770a1ac5b1992": 1,
    "0x24266d186dc99ec9cda2e6fe73ad357c8324c1b5": 1,
    "0x28227ad4469e75bc4a36decaf7eee0732b46262d": 1,
    "0x37075edade15b54ff21b3f6c4b04c78aa1f0f4d6": 1,
    "0x0048144d60c414c8c972784471646f0f0f3d5e2d": 1,
    "0x52278a9eb2efc0aeb7637863c47389d99843b314": 1,
    "0x70716f34089699bf8c55a3de998db679b5cf7731": 1,
    "0x228841c46c2d6d774d129902dd415b743e9dd284": 1,
    "0x301829f087ea85d2d3271d4a4169f90df1007100": 1,
    "0x392810ea8fa571988de841407b91bf219ff6b72a": 1,
    "0x415307ff644e76410ec2fa735fcf87219fe28f8d": 1,
    "0x722578cae2cced4602954930708ada06bba45bfd": 1,
    "0x733223ba90879180b0c2e2429ba01f42fa4b7043": 1,
    "0x813074b9a3c964c51ac66b841c2577a3ce1062f6": 1,
    "0x004398361d0fad60d7fcf5533d94398ee9fc8ddc": 1,
    "0x6057674feed19ea6219b1dab691cd5e092ede616": 1,
    "0x06799016e3f5aa5c30b84a98194a5b12f06dc8e0": 1,
    "0x7744404a721adc4494d9a0361062e15ec12cfb03": 1,
    "0x53603413d7098fce94dcb33a718d0a108781f166": 1,
    "0x878565357abd7a1134e83fb99367af40540092bc": 1,
    "0xa1b9972373db7321c61f6b0713fb6537219b4870": 1,
    "0xa1e3bb92a40e884f101727d998fbe020514ae3c2": 1,
    "0xa3a7cee5be17586fb0680b1640f4e6d342f7eb39": 1,
    "0xa3b0b9896fd26fbd7738c9f20c4bb247e8365a6d": 1,
    "0xa7a2656773d1252d85603231ebc9c1fbbb46a710": 1,
    "0xa8c12ef6a3fc69fe4e0eb9966adf8cb31cf04033": 1,
    "0xa9ccfcfceda22b98d6ab5729100b49e637326b2c": 1,
    "0xa10cb44ab8d5fcc751fd2830e82f8768e9614556": 1,
    "0xa15c23f98a9364846bff12732c22671163d5ae62": 1,
    "0xa50d7064f4db9f9fb143ef0b455451814c11a634": 1,
    "0xa115ea917db0798944296816ed0cffaed5b69cd7": 1,
    "0xa188dfdf73d5ee1497ed5231bf1f52a903036594": 1,
    "0xa5814c154fd610a773e08caddfae1e2429446d20": 1,
    "0xa9893d8f3d724e5f9a648e98ea53486d2574469e": 1,
    "0xa26387631b39efbac66bb834cf02605d3696e7fd": 1,
    "0xaab689101305a3ac9feb005549beac6be53c84cc": 1,
    "0xaae331012c611e67e11a18a1d31ef5d564967bdc": 1,
    "0xac7446d2e8eb96a98a801b599099d31b73fc3bbe": 1,
    "0xadb59eee4fe26027a8c2174f4eef199e7a67c214": 1,
    "0xaec539a116fa75e8bdcf016d3c146a25bc1af93b": 1,
    "0xafc651defcd26fa3b1a61df27e520fb5619647c1": 1,
    "0xafd73b9be6def8039afe421d376ed28e5add7c8a": 1,
    "0xb3a5443685f48dd26c99ca3d1245a7e25a171e55": 1,
    "0xb04a9ccbdb801c4ca0403344edb6ff0ecab07a8e": 1,
    "0xb4bdc6722014f17e1b866f1e3baf77aa1e59ca4c": 1,
    "0xb4be7b07492d6b09d84d1aa73063c5023d73d278": 1,
    "0xb5bca8e527fbe8222991753d0b703b5792cf4f9c": 1,
    "0xb7ffc4c0d68a92b17d56aae4e428145518b8a6c3": 1,
    "0xb60be47e9406d2676e5ad93b1e2e79552af711e0": 1,
    "0xb2342bd1412b1cd3b1d35fd88dd83dba823748b8": 1,
    "0xb6651ccb592c223df9813ace5db3b12553c115f1": 1,
    "0xb45170e5d618a3f615ebfc8518dc14ee8022d1ce": 1,
    "0xb55810d2bbf8a156f8c51874dd235fd0d2a88ed7": 1,
    "0xb6473440bef806fbe0521d4cc6e4f8ff54ce8844": 1,
    "0xba4ad9affd71f20df21290d35d716b5b09c10613": 1,
    "0xba84fd3a5d8e6108aa15ae6305b54ab0192f2419": 1,
    "0xba230ea8968a19ade1e08d3297dce101d409ebc3": 1,
    "0xbb76f960a804aa522da029e39859e17387044c60": 1,
    "0xbbdcfbf35b228d21af7f00e199de87671c1a4f12": 1,
    "0xbc93cf9eba3ee0030fd1df846a9d8b4170994329": 1,
    "0xbdd6b9d168c28b30cb7f8944ad51ec4df280bf9a": 1,
    "0xbdfe4ee6646c09f90e96d8dcbd9dc8f3588589c8": 1,
    "0xc1bb27b3999a9bcdf3b7f9bc9817835c3f8c171b": 1,
    "0xc4ed448e7d7bdd954e943954459017be63584f69": 1,
    "0xc4757e2c850bc47aa1efa32bc8612627e2f1cbb3": 1,
    "0xc5455fef7dc2131164f3400a1a8c7c14b8854242": 1,
    "0xc5919b0fef44dbcc4beb7d24a33f890907e9587d": 1,
    "0xc8733c099354091feff41d2b1986c7e031c1ec43": 1,
    "0xc10899f710d1572099ad0ed10248cdfe262b15ca": 1,
    "0xc88090a654ec288201957c4a10e9fc88611a6880": 1,
    "0xc98180d563aab2c57f9b9939b2f5dee046096d67": 1,
    "0xc3213980b75a527f88a17610f7a7f4b6f8f18284": 1,
    "0xc395701304e40178bb17b4dd479383dc1df7d76e": 1,
    "0xc421395587a07d32c4ac59120f879bed6de92dcd": 1,
    "0xc0478679246288e7fa04f6829f7052968e559a71": 1,
    "0xca6fa737c2d06778b86594b3841fd2b2428e77a2": 1,
    "0xca528d18a31d5b69124c0a45f4f861b7f70e001c": 1,
    "0xcb1ad8901f2aef32822e8f115111b3d87c261319": 1,
    "0xcc7a08455a5e2566d09575c327899b57c5597076": 1,
    "0xccc566bd5ad213789bb892b38d5c2bb2906caa4f": 1,
    "0xcdaca7be95af39a5652b3c705ef2a301d0379cd8": 1,
    "0xcdf9c5487dd1ffbdf9dc1591cbee75c9b1b9f011": 1,
    "0xce56a9d6dfaa724f816dd5c2958b0c8d61f15d9a": 1,
    "0xcf9cd96f231f17ebe0f3461c07d1364a25ef5f8e": 1,
    "0xcffa05fe7dd91014f8f659e747d178405478e40e": 1,
    "0xd5cc5536f5629c99b33b8b6c57472a8861c71f28": 1,
    "0xd66d4b029eb9b14aa6d0f6d739ac00e634f336fc": 1,
    "0xd80fb29c97f0e35079ec8b33266a2eb7885fedcf": 1,
    "0xd0292b1f870f6ca87fb1fecb89c7d5b20cf4d948": 1,
    "0xd967a962e3948d27909d8baae95816ef6709aab1": 1,
    "0xd8577b06962f88a9fdd6b0f51281ab900a210621": 1,
    "0xd405454b4ebf1d5e1f9106c55fa2cc07dd60d9ea": 1,
    "0xda6ac37aaf4bd41dde0a84c38b27e4764a3f7793": 1,
    "0xda7b2ae7d0ff4ce556eb9a2c882ff91c56073b96": 1,
    "0xdbb7ad2e0f2adb88b4ed8a000a822d73f3f2cad9": 1,
    "0xdc652dc553799c4b79d377a839214aa38ca4871c": 1,
    "0xdc2251dd52519be588b8978ad256217af26a38ab": 1,
    "0xddd9ee391a30bd705475143d3837f8c422db0842": 1,
    "0xde4ca8b99fe576f72c9fb00ef383e983b3eac57b": 1,
    "0xe0b898f30c2e8ca2b61794bba63d4c02ef20b13d": 1,
    "0xe0c7db7ab40d1a54ab92aae33f64ee8f4f150d1f": 1,
    "0xe2e6815388324e443399c838c5339e54b59d9a23": 1,
    "0xe6eb67f50448b0764d1b368c342b8fe85ae9c6fa": 1,
    "0xe6faa41ac39ffe0ef3a36f6e2e977696519cc526": 1,
    "0xe8d10bd02759a824dce125353aaa51dacb4f9f36": 1,
    "0xe09be2624795323b74811423ae9639ebdfd4c1f8": 1,
    "0xe21c22d861962eb0d062a8d943b86ff4972724e2": 1,
    "0xe33f45f7f4cd2815d4c15901efed5ef8caf103d1": 1,
    "0xe40c82d1b45dcfa8e8abee09c131b37a43fff456": 1,
    "0xe98c1fb29d029824109d2718e745648f5f90dd2f": 1,
    "0xe639c7f3038b481cfb4e8ccadb244e25e7bdfcc5": 1,
    "0xe851d96ddfe59fbcbde3371ae24363c559b22d21": 1,
    "0xe1027dbf0bc361be4c1e7b365d74f8b264f813cf": 1,
    "0xe3754f27f7d65e86e890e1764e04e940cddc7379": 1,
    "0xeb7f21885a32d41b4fe57ca06099f98e0b40ac9d": 1,
    "0xeb56de89dda1629467c184fcfb14e6b2af356ba7": 1,
    "0xebaf17dc8151e756ce4ffe632bb1db6f67e92fde": 1,
    "0xee436edc545471045b92fa6618fccaf5c0663e42": 1,
    "0xf0ab84e8fe7c5d9ebc23f1f2d704dfb4b093af5e": 1,
    "0xf2be9e1c1f0a9469f84ac87f6c54354be033dd07": 1,
    "0xf5b8b478245390f46f7fb595e2ad7034e285f3f2": 1,
    "0xf6e88d75b4f8372aad93ae0b1af114916b907834": 1,
    "0xf08b3f2e61506708a9da4211b861d014d3b9adf8": 1,
    "0xf13cd99de25aa19eb15854a21ee7f8e61201e7d3": 1,
    "0xf26a9c4873c60749cd36db526e0b3d99cabcaa60": 1,
    "0xf305c7a8e620e8a89a74268da2be11f1b159af38": 1,
    "0xf3617ee7e3b8cf2a4298cdd519296e36a1fc666d": 1,
    "0xf4312e5dbba81a00f98e15b6bd4956572554e8f8": 1,
    "0xf5565b4f9bc2047964c9082bbb42ed7e014e5d0e": 1,
    "0xff1daa71eca58dcaf175131edc6f9f5c15cc4ba4": 1,
    "0x64599605b3c37f1054b96c4ed7bc04976f7189c0": 2,
    "0x6721230ed86550a0e17bf0c8df8d50da54c8a559": 2,
    "0xdd1c11c22593e584678669167d80b4a497fca36a": 2,
    "0x3feed71991ec5bceb31bd51d135775e353f59881": 2,
    "0x876332f905e890aa70b482068eab11ea3894557d": 2,
    "0x4349251b5bc0930ca964d65649ac314ad89a64d6": 2,
    "0xdd9b61a76f119e0f142387881c63f45c8f8ab502": 2,
    "0x4dd9f64ed248c26c499a070af7cee21a12bd5b34": 2,
    "0xb4f41a3267f1169d916a232569f50fc6b8a419ee": 2,
    "0x4d9a2307733aa5be00a1ac3b133151351b73d6a1": 2,
    "0x4bb94493b13715af52115f0a1dfb47da96e0a6f5": 2,
    "0x102fa9f57f758a3c7aced828ea3cad4b7027d801": 2,
    "0x838d4eba84b2ac0efd24d2a35cb942293938b0ad": 2,
    "0xf1a1e3d912057d6fd703410cc4e4f1acf3176e18": 2,
    "0x980ccd173b02bb26dd72213ae557e50a658d60f3": 2,
    "0xd61cffc9efb71fdf81c5801747b33dc449678973": 2,
    "0x90dbed745d965bf7b757bdac6dd8573541c7749f": 2,
    "0xbac3745f0fb7181be61c4262554c78d5c90f87f9": 2,
    "0xd6cb09384c7e2768f04fd043541aada441cf0b2d": 2,
    "0x67aabc874768d906badecfaa4afa07bdd69f7ea4": 2,
    "0x82d92ce80db7d07d99d0a816a2409957dcbb15be": 2,
    "0x0b411e641a36be7a99f9a86c2fbb8eec3ee560f3": 2,
    "0x382ccf02b3e9a261ba134e442e5ad628f4c43491": 2,
    "0xd8893570a125b778eefd9e71390c8773bdf518f2": 2,
    "0x87dfb5504c708222a9fba254d8d49556b7adbf68": 2,
    "0x85f8b60def9064e09c1779ef1c1d4afe9e628a23": 2,
    "0x5768e444cab65831ff16eff46a85237c92ef5ddf": 2,
    "0xaf5905668a524673a78411515f74673d2cc5afd0": 2,
    "0xad553e4d9dbb2325f6b4c8e6da42479a6e8eaf5d": 2,
    "0xd4c11f913474b16933bb2699eb047465324d7ff1": 2,
    "0x72faeef0a0e1c5e2d1096e5eb670f36c153d0c6f": 2,
    "0x72c36c61fa8630b23f8b321fe3ff9e868de6e1c5": 2,
    "0xf793a6e2069cc431dd11223fcfd2acc10f48cc15": 2,
    "0xab14398717c3bc4f545351b39882892ef4b74e87": 2,
    "0xd04c5643f7c72ca43de55e41f640d445d541782f": 2,
    "0x66e096dabcec1b03789218862132629636b8f5d9": 2,
    "0xf29c19390ea6b999c781d3dc287690299b3729ba": 2,
    "0x59ba28c42ed28929e131e116b636acba6e03d103": 2,
    "0xf5813ea89f810b34cef54ebd4074ca587f05d4a3": 2,
    "0xa97577da5df827113140db54ae703f584b454f2b": 2,
    "0xa3082c94b5082ec2b40602c7abd9839bcf86dece": 2,
    "0x44cbf96f34bc4cf9902273090d9512e79f159c4a": 2,
    "0xd079f70a23f61fa6696ec55c2fdda1a93a99bcd3": 2,
    "0x29c0d423b2306a5df61d683cfc755072a84dd497": 2,
    "0x11112a35fef160f6453b600d288e8a918d0900ef": 2,
    "0xd7562f6b99f3dd10c070c1560c4746f3e71719cf": 2,
    "0xe9f5e3bf12ce0400ad44e1d2b1105d997705cf85": 2,
    "0x26a484c37fbed748b6849d9a71049613020ee777": 2,
    "0xee44e6c3299813c94a9389666860474b8b9645b7": 2,
    "0xd1480feb9ce7ebba7778ad1b926851f2cee85e2b": 2,
    "0xf0d17cac77c85832e7bbbf4cc909f322b4bdb725": 2,
    "0xa11fda9d1a162996c1c24801b66a27cb0ccab184": 2,
    "0xe884b475016d6374f88e7a71b2cfade2eb6b35fa": 2,
    "0x12bb56a11c13c79bf0b79eab0a8753c8d12c5405": 2,
    "0x34384fd26722a94cbdee5c72a8ac0baeb859d12c": 2,
    "0x11d753611c292a36875d766b651f0cd4bb27bf74": 2,
    "0xf1342257a80c34221cc4a28446fcf8a37198f2b7": 2,
    "0x595a18d0a687c35c70cf9caa05702e3317a76f25": 2,
    "0x9f276e3886b817ba6f81d7d7c8bd24e426f0b844": 2,
    "0xff6e9348de5013176b1f78a53ddbe5bf542c4320": 2,
    "0x37106b73eafbc605245b634a4b0fbab494fd82e5": 2,
    "0x592e5014b717dd9ecf92b5fb4261693d68d0f331": 2,
    "0x9f9f479bc0beacc0d635d652c50d03552700c28f": 2,
    "0x046541b788d5d97ece9a5bdf22a3b5a02e7cdc73": 2,
    "0x9deb222574963af172b8d0ab76ef47c26128f8a2": 2,
    "0x922a79ae1bdae6bed8c217455da4c8d997d774d7": 2,
    "0x519b3aed5f9f6097f17030328e54d3f395aba030": 2,
    "0x0fbba366f9a5bb9cedb135f782fd4533adb39148": 2,
    "0x52140ee7a8a1c9fb5d8d407c33c69a919f26122b": 2,
    "0xa3b4ce31ae375103ade8d8c4cb1f720813d2a69a": 2,
    "0xc9a45b33363a30b589806ef8bd2c7d8ca7023b2e": 2,
    "0xe0c0e8a610ea85d484476cce2db827998337855c": 2,
    "0xe44d06c0c47470748ef188b67d592acfe1e992f3": 2,
    "0xea9beab23f545e3f7eb4e9d178cf81d7134ebad8": 2,
    "0x264253235847744ee3b9d35c27c711be3ab8e68e": 2,
    "0x1c7f1fb35afc8562ca561297ed78c5ba33559ebc": 2,
    "0x8ad8dd7a5a1fe6fcb2cee37e3b7635978ab16b88": 2,
    "0x1804111464a198d8b0450bdbf7cb9fd65a903baf": 2,
    "0x147813fd2a13e29349d5031710a6c9b42b3d9bc6": 2,
    "0x7e2206885e1519d706b9db87f15d372d273e2a40": 2,
    "0xc42dd3782efe455c8aa5b84b9530dc548f0b66e3": 2,
    "0x1fc642c187ade156d9ae3583ef61b6802a105935": 2,
    "0x7e1549c26c6ea0d7af871483ca8ddcde44ab144a": 2,
    "0x160068434f3c81483f5a783e876a26cac783f9c1": 2,
    "0x7a5086d4ce659dd425c6416ad0c65daf3f7cde6b": 2,
    "0x6fd80b18d73cd2f5f972b3c2829456cc16d6d543": 2,
    "0x2e6f2d979dcfaaf3bf4e64a5e17837d96520e129": 2,
    "0x91831049fcb9fe95a700c8044e77062aab89bc9c": 2,
    "0x6f1eff4f6ec1eb3e2cb6512b65a6c1efb4b5031e": 2,
    "0x3a7dbfdb88201a102ab4d41e052e2b4979ac3cf1": 2,
    "0xc463d29f401bc189d5b8c4bebc306809c654a6f3": 2,
    "0x6eaea95e69c3036ae2b6ddae02da39a87e7917a8": 2,
    "0x6d868cdc59bab7bcb456684d6a6ba9f7f2e53674": 2,
    "0x6cbaccb098674dddc55a6b316ffe3cf96924dc50": 2,
    "0x6bcab2f906581036e10f32194b87ed1470a50e21": 2,
    "0x3d03f8ec826df6cef898add75e0bba67737c2375": 2,
    "0x3d4dfd7bef3a69acd4cca0f96649953e395d27db": 2,
    "0xd95d58cfe72c0d8aa170ea10992c00fe881e7e14": 2,
    "0xde1a93a250e77c14328c0b424ffccfdce4fcaf8f": 2,
    "0xd9605ea754245456fbfd061e48db6d73ea65b64e": 3,
    "0x954eab8fdb1b9f00e7d7b4fb7a2c4be636a594b7": 3,
    "0xc0d9724db05521b368320a1751678bdf8f7b0e80": 3,
    "0xef3becf2375b06a090f19c78acc125795b245075": 3,
    "0xdc8d9a4aac0c25ab97047f1c3868fcb1c3e0f53d": 3,
    "0x0b8e142c9fc71ae4c1b59ab77d309d9a5310cd5d": 3,
    "0x0d07b5c7bdc2719a9a716ca5dfbe220cc478fb4b": 3,
    "0xc7eaeae1f46406a9cde58db667eddd6887e4ff71": 3,
    "0xc10d470a3eb22415e6f1dfda1089e4bcf3eb9247": 3,
    "0x687571eac68af29faf73528380c63b4876e0954e": 3,
    "0x4b79b4c323692c7a5fc0d924b28d19c3605dee3f": 3,
    "0x08747409c90b210120ef46704db9b0d2596dd695": 3,
    "0x7c5ae53d18b36f3d7d1bfd2abaf63831558ea679": 3,
    "0x7e27c4b9720ee5bb167b654506c3bef77de6e8d7": 3,
    "0x19d73d526fec699f0afe2edfbcd9a96207378131": 3,
    "0x20c3f6d76c688c0a6a3ee5c30af5daff8fdd8b76": 3,
    "0x22e1d4e6a67b24aa1a7f77c05f67889ab4685167": 3,
    "0xf9be451b21f24f71f227e138d9bf94e88a855638": 3,
    "0xfcacdd40b9756e313fcbb044323cae1637e44af5": 3,
    "0x54cdc8751d1712ad0c444ae9dea2a18fbe655728": 3,
    "0xced4c7ff9bdc8af4108488b02d2b408a4fd7f558": 3,
    "0x59faf9cb2c18fd1e376255a0f8aa03ad3c3ec796": 3,
    "0xaee1589e79d64721e7567db9262adecea5b508c9": 3,
    "0xd07a8efe1a575d1b4a3d65709c3404ebc0b568c4": 3,
    "0x93c7359b2d7a09b85a1f42bc310b1a7d10e6d62c": 3,
    "0x238a6443f2c3d14eaaa24c825df7a470dcb8440c": 3,
    "0xb30d97cacfc6cb082bc4c91697e656b4ddc2df71": 3,
    "0x498d9f7d395f67ddebdfde8abc899b59209e15c9": 3,
    "0x008543428b63b874d70d9cb8367f15597d2e593d": 4,
    "0x4651c3555e2af798747b4c60397f0f9430921246": 4,
    "0xda67d8de492371259bf1f3cd4cd35f4e8d731ea3": 4,
    "0x584876efae5c1fcb8c5c55f9f3246431ceab1091": 4,
    "0x805138b0421b64fff3bf3e89722c009fdf92a35b": 4,
    "0x94b3bfdddd1017fef2d1b4dbc654c94f588b224a": 4,
    "0x23783f2d297555fdcbd3cda4b4cee5af9dd2a02b": 4,
    "0x47528d935c234304ec5b277b87cc2535736c3ab2": 4,
    "0xcbbe70b272db720b0d67f7d4f3fb5f9d6b6bec7a": 4,
    "0xe0e3dfa88d7b3b2d897b1d1cf311f2590306fd6b": 4,
    "0x538b90f4932838142dbd58612e8b9cad48369f10": 4,
    "0xa1fe4a3581dcd060087b2583ed329cc2cdd9c5b9": 4,
    "0xdea7d18fe2d740ef98fb150659babe9c25c51b32": 4,
    "0xfc38d55bcc34555af633d5c288eb1d297bb99e2e": 4,
    "0xc271972a6940016fd0e300a7ac71bd2a28909094": 4,
    "0x373227b43fe1efe8da9d30ed1ee45e7488f6cab3": 4,
    "0x3c3609b33776ba6c423c664322b4686dbaf5b09b": 4,
    "0xc60f7fb6bb79ff40358fb990c5517585c0e2a8f0": 5,
    "0x6a7ca5456f78c11c2766e66832b9643beda3febb": 5,
    "0x72459522b0be264a5a021238ec946751d3c06802": 5,
    "0x6b29380bb9955d5b2df1624c5c6a72a5e69a6b80": 5,
    "0x84960133fc4fc1791fa7b3c79597304e260868dd": 5,
    "0xf7cf65fa377288406bc22e33b405d01286a35a1c": 5,
    "0x3c5e54bd5a39a0c9574fecd9167d1a4387fa7ab5": 5,
    "0x2f9bfadb9b39e24e6db89e176f7a1d99d6ed9aa8": 5,
    "0x2b5da7497c8367931501948e1c6682c87aa6edd1": 5,
    "0x492842125e4c3a7d10cda0e0291ea22788e4ad60": 5,
    "0x6ac4a191e0a5268cda0188886dc178eb51f6356a": 5,
    "0x02f6ec03b26cd9b1ad904f22d948d2fdf672ec2d": 5,
    "0x96493c437488c8eeb60fcbac8b717c142e9f572b": 5,
    "0x1ca19180b997047e8f1bcaad52520d25fe2505cf": 5,
    "0x8b5da9ab8780a8e9d0d03051a0febe49c7b42252": 5,
    "0x86333c5be91a4a5136fa08fc4eab61b7cdf6fc03": 5,
    "0x09cd77d3c13208830ed93fb042d15324f156723d": 5,
    "0x9a7c67fa01dd46fec4c503c08d8b1a43c914b85f": 5,
    "0xea883d337acd9be6681a6922a13c25a33c265c39": 5,
    "0xe02c3cb8eb9992161deb9b12391378c2cf49b0c3": 5,
    "0x0b89dbcb31b0bb3b45406e9a4b61eabd034ec730": 5,
    "0xfed243615f5dbd338b1e50cc1c31687ac9a7b16b": 5,
    "0x34625a5c44e373203a9a3a756ce674b1964b1c0b": 5,
    "0x14b37f5ab9f8890807c5abd5b2f137261b0bc450": 5,
    "0x0472ae06ade8bd7af138f29991eb065579272eb6": 5,
    "0x473ba05ba60f5c67f2c7a95690819d738b67f0c7": 5,
    "0x22af27afa6a6cdd6942df8674ef160b46428978c": 5,
    "0x24e58ab17b5471759c8edf61f5b4e78c43250115": 5,
    "0x5e59da9b302f31f171a35a4616da0e412a6f9169": 5,
    "0xdb2e7e41019a56807cddf812738c0a1a9af73b21": 5,
    "0xf2d23282f53d1f1b97f97b3ecf6372d106b0b2f9": 5,
    "0x33ba1f33432d3d7fe203d55e301b05948ccaee4b": 5,
    "0x8332ab2043fc667ebfae07c6b0e24390fdd01d2b": 5,
    "0x46c7faec8853a32e26b01e518bc3a8c82927a092": 5,
    "0xebad78ae23957cc1ccb592f00a67cbaff24f5834": 5,
    "0x53b182d42b550b56a46379b0adeb7725f3f4c0e8": 5,
    "0xcf5ab3bfea5c6ea47650ff7160d480a2e2705ad9": 5,
    "0x53eb3c399628402b2d7714f988c7ecb9e48420f5": 5,
    "0xf21be03ada04e66703fe82a62622326fc1b20e3f": 5,
    "0xcb0c3b15505f8048849c1d4f32835bb98807a055": 5,
    "0x60cf3d6b9de300c14c6b58085a23afea267975b5": 5,
    "0x61f2b4e57a5d2ac5cbd4014b0bbf78cb6bb7340e": 5,
    "0xaaa132f0a00a53043b4c7ec07e626bd4ddc152c1": 5,
    "0xfb109a2b8d4c1aa47b333d115b124217c033383d": 5,
    "0x69f9f6fcfd7854427b86ba0df83b32980b689103": 5,
    "0xbc08dd3b7e8567fa5cc9179a7c2e86960d3ee650": 5,
    "0xd5baeae31cd5691d86f4ff437da07c3ed29ac6bc": 5,
    "0x4534d8bb253be1210199766344f2a5bf95d170f2": 5,
    "0x79a13f8ac0cd64b1560164cabc583b64efe67437": 5,
    "0x85c595e08704ea0c79a95cc26cc3cb16b21569aa": 5,
    "0x1833e3fc4bb079b0d1f388efdeae45c68c2cca9a": 5,
    "0xe92f6f1f8182d16e7efca0cf731f03f97ba7b533": 5,
    "0xbab3f5b74edab90fea48b146b4296214522c6f2c": 5,
    "0x140a824fe0c013aa969f681b5312ef5c3a39cdb4": 5,
    "0xfb0c57a8a5bf862dea8a8047285a6a8c652929a2": 5,
    "0x231f702070aacdbde867b323996a96fed8adca10": 5,
    "0x235d6ce688bfbf37bc08b0c62e3ef7e623f8542c": 5,
    "0x830ec70c686d10737c0bca11f030cc966883cd22": 5,
    "0x5763301c647f4ae2e912c23d2a0b30f436f1c47b": 5,
    "0x4778755f4c3973947a0ad02e6012fb90e23ae21c": 5,
    "0xe4715c8b3982eef62e8ac2b8a0ceeb40ab91c9a4": 5,
    "0x5bfaf470c07062dc43c124c81090af857a716f64": 5,
    "0x5c1e16b8bcb5bea23357bd8051d56754736722c1": 5,
    "0x5cfa3acef2cfaea33396f1ff8e2068765f887833": 5,
    "0xdca746e7ac7192e5d42fd2eba32d80309c3d6bb6": 5,
    "0x5d130883b303ec7dff801719a3bb7d367e6e4bec": 5,
    "0xb45f5bc17447b1d045527339ae977289b5505d76": 5,
    "0x24327845c17a4674f0fb9ef5ef0678c522bcf1ff": 5,
    "0xe56b91c299e965b43923d405b3123c5ba8202f96": 6,
    "0x1a5fe261e8d9e8efc5064eeccc09b531e6e24bd3": 6,
    "0x1ed6e4623c84d86a170b9a500a2e6ea1d29cd292": 6,
    "0xaa9aecbb721237e21a991dbc88131a6467e04458": 6,
    "0x6e6048503d4686def8ed5c968f653ce54f490e42": 6,
    "0xd423691d9c023eb21f24e76976e8bc3f10a95792": 6,
    "0x7021c88d1a4d68487485f52b0f87148972e3390f": 6,
    "0x847fe2e9a5445870e8685a58618b513ced39334d": 6,
    "0x387ad273721074fab88cdfef01a1803dcf8ebd02": 7,
    "0x01a35017c1475e9c4daa88771ee5bc9ee427c28f": 7,
    "0xcdc36c380ff121256cdc94c0fe30bd13e38fb95a": 7,
    "0x5cb9dabd3419ebe33d2e24fbc0935452aa623601": 8,
    "0x58ea97ee560264c5d10c598e60f61a5d9fbe13d8": 8,
    "0x6dfe44316c39132a4aa4603cb4cbb204ed5c428f": 8,
    "0x89edae96669017c4c8059e369750ac1a72a39e79": 8,
    "0x7f4241a39c92cbed636896d6c55b4c39ec1f1d2c": 9,
    "0x183febd8828a9ac6c70c0e27fbf441b93004fc05": 10,
    "0x2de4c4e43cca9e9db61019a3e13f932460e2e8d9": 10,
    "0x8ad6a860626da4c1aaf0a291e135c3699612524c": 10,
    "0x75c8e2dd57927eb0373e8e201ebf582406adcf45": 10,
    "0xcc99a82c7406ecf57270d7c204d888502b4c38a0": 10,
    "0x6ad6c9071a30a6ea71e4a12f91c13f241a4bac88": 10,
    "0xd144992c2906b7ccb810a09d91c528cc4d32934c": 10,
    "0x93bc362e94f57e5dd9b33108509639cc621f8585": 10,
    "0xd8b2c9f9c289abe9a49e04871b18306e469f6121": 10,
    "0x18bd8fd6015eecf3e516ea7547bb87b5644571a2": 10,
    "0x6c58766c850f5b1cce8f0c3b2b573b76f36291bc": 10,
    "0x6040002c6214dc2b60db8c47b6e6e05144f16423": 10,
    "0xa93b6419e2264127b4486cb902964bffa151e708": 11,
    "0x6d92958cd012cf6d2292d2226aa225bfa214a2d7": 11,
    "0x0de50f7df1294c7972f2ebf0cf51430aac59a304": 11,
    "0x4d53a95e98234f5f732146de8f0536b007276dc7": 11,
    "0x6150b4d7b46a9e7e72343e4b0bb4b9db64e6077f": 11,
    "0x81533081730c558ba5619151168fa2619d4d966c": 12,
    "0x762afecb5b189f2c9ad9a095462bd9fb0938c324": 12,
    "0x51b76da4aa0ea58878115a545bcb0244453f4c12": 12,
    "0xc4a821ce10d6da42d63937f0ea5581ace0e039cc": 13,
    "0xd6a2977b737f6ab6b1899cf0b094464ae6216b15": 14,
    "0x013e5accf6ccca896e730027fd4e3d5ed8e8dbb1": 15,
    "0x187cd087369b76d9e095895fce43861cacc4ff7e": 15,
    "0x7139ceeef20464fe9bd2ba713c8a68240f01711d": 15,
    "0x6847f6e3053cf20be0c87d6635cb63cbb879ca55": 15,
    "0x32d16ed04a4d4e650b2e0a7dc78a09c46a4e6a72": 15,
    "0xa16f32f8a812357a2ef9d63a8bf97f9430448f58": 20,
    "0xf7ee6c2f811b52c72efd167a1bb3f4adaa1e0f89": 20,
    "0x682611922a5eb4998b312a03b59fe973483337fe": 20,
    "0x4f7e8219f2ca71939dcaaf294237082fad5fd136": 20,
    "0xa2381223639181689cd6c46d38a1a4884bb6d83c": 20,
    "0x349fd761600d328f3e407871bcef29f60d323ec5": 21,
    "0x93002bbcfd89a04a8f9e9676febdcefb2944b8e7": 21,
    "0x3825c8ba07166f34ce9a2cd1e08a68b105c82cb9": 25,
    "0xb7d9a5df35b5f7304020197dbf4cb3c90a2cc006": 25,
    "0xe22f3a5c766edd75477caf76c19db260ae0c6414": 25,
    "0x52ed8dc9b675f341937e87e1f122eaa39136133d": 27,
    "0xba8c648cd65d602bec89fabf62c3c743c73db36b": 35,
    "0x801fe80dbe2b11c32f6f3c2ab0a3cf2811763ee4": 35,
    "0x5f7190b7943858b7bdd24f069241612091c6a986": 40,
    "0x9ba62573546b0fb4713037b5232395a6d6940681": 42,
    "0x426a79baa0383879c5ac9b805cf4c695fb830fb7": 45,
    "0x74f2392339427a6ea26a5f3764683f4118402263": 50,
    "0xa8d85cec1e9e9e6c3904b4d873cda459a0490243": 55,
    "0xe293390d7651234c6dfb1f41a47358b9377c004f": 60,
    "0x6918a5b07c2f79a4b272bb7653a43438ca96cd3f": 83,
    "0xde7666aabb06913f65da24610dbd056b0139634b": 100
}

export default class LegacyClaimPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faction: "sun",
            eggs: "1",
            discord: null,
            wallet: null,
            hasErr: false,
            hasSuc: false,
            targetWallet: "",
            etherians: {},
            playerEggs: 0
        }
    }

    async getData() {
        if (originalEggs[this.state.targetWallet]) {
            this.setState({ playerEggs: originalEggs[this.state.targetWallet] })
        }

        let ref = this.props.db2.ref(`net-1/app-0xfa560f929f72d6b14b848afb5d610eb89800a7dc-6753983/chain/accounts/${this.state.targetWallet}/etherians`);
        await ref.once('value', (snapshot) => {
            if (snapshot.val()) {
                this.setState({ etherians: snapshot.val() })
            }
        }, (errorObject) => {
            console.log('The read failed: ' + errorObject.name);
        });
    }

    async componentDidMount() {
        var id = 0;
        //traverses all of the elements with a class of 'starlight'
        $(user_configuration.target_class).each(function (index) {
            var currentElement = $(this);
            var width = currentElement.width();
            var height = currentElement.height();
            setInterval(function () { //creates the stars based on the frequency and desired density
                for (var i = 0; i < user_configuration.density; i++) {
                    var newStar = new Star(width, height);
                    newStar.create(currentElement, id);
                    newStar = null; //just in case so the garbage collector clears this value up
                    id++;
                }
            }, user_configuration.frequency);
        });
    }

    changeWallet(e) {
        let val = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        this.setState({ targetWallet: val.toLowerCase() })
    }

    findEtherians() {
        if (this.state.targetWallet.length > 7) {
            this.getData();
        }
    }

    renderEggs() {
        if (this.state.playerEggs) {
            return <div style={{ position: "relative", width: 70, marginLeft: 37 }}>
                <img src="https://woe2.s3.amazonaws.com/mainegg.png" style={{ height: 70, paddingBottom: 25, }} alt="egg" />
                <div className="countHolder analtz">{this.state.playerEggs}</div>
            </div>
        }
    }

    renderEtherians() {
        var ethCount = {};
        Object.keys(this.state.etherians).map(f => {
            let x = this.state.etherians[f]
            if (ethCount[x.definition.id]) {
                ethCount[x.definition.id] = ethCount[x.definition.id] + 1;
            } else {
                ethCount[x.definition.id] = 1;
            }
            return null;
        })
        var skipCount = {}
        return Object.keys(this.state.etherians).map(f => {
            let x = this.state.etherians[f]
            if (skipCount[x.definition.id]) {
                return null;
            } else {
                skipCount[x.definition.id] = 1;
            }
            let bubble = <div className="countHolder">x{ethCount[x.definition.id]}</div>;
            if (ethCount[x.definition.id] > 99) {
                bubble = <div className="countHolder">99</div>;
            }
            if (ethCount[x.definition.id] === 1) {
                bubble = null;
            }
            return (
                <div className="dumpetherianImg">
                    {bubble}
                    <img alt="monster" src={`https://woe2.s3.amazonaws.com/monsters/${String(x.definition.id).padStart(3, '0')}/avatar.png`} />
                </div>
            )
        })
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>World of Ether | Whitelist</title>
                </Helmet>
                <div id="jumboimgz" className="altJumb">
                    <img id="shipz" src="https://woe2.s3.amazonaws.com/spaceship22.png" alt="Space-ship with smoke" />
                    <img id="moonz" src="https://woe2.s3.amazonaws.com/twoplanets.png" alt="Two moons" />
                    <div id="roadmap">
                        <div className="starlight">
                            <div>
                                <div className="wrap">
                                    <div id="navigation">
                                        <div id="logo">
                                            <Link to="/">
                                                <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                                            </Link>
                                        </div>
                                        <div id="menu-right">
                                            <Link to="/roadmap">
                                                <div className={`mask3 ${this.props.lang}`}>
                                                    <div className="mask-inner">
                                                        ROADMAP
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div id="roadwrap">
                                        <div id="roady4" style={{ marginTop: 75 }} className="thin-wrap">
                                            <h1 className="maintext">
                                                LEGACY ETHERIANS
                                            </h1>
                                            <h1 className="submaintext alternate1">
                                                Here you can check what assets you owned in the prior iteration of the game and claim them for the new release. If you feel like you had something different from what is listed below please let us know.
                                            </h1>
                                            {this.state.hasErr ?
                                                <div className="errortext">
                                                    Please Connect a Web3 Wallet
                                                </div>
                                                :
                                                null
                                            }
                                            {this.state.hasSuc ?
                                                <div className="succtext">
                                                    Thanks For Submitting!
                                                </div>
                                                :
                                                null
                                            }
                                            <div id="registrybox">
                                                <div className="af-1" style={{ width: "100%" }}>
                                                    <div className="af-label-text">WALLET LOOKUP</div>
                                                    <input
                                                        placeHolder="0x123..."
                                                        id="select-drop2"
                                                        value={this.state.targetWallet}
                                                        style={{ fontSize: 11 }}
                                                        onChange={(e) => this.changeWallet(e)} />
                                                </div>
                                                <div className="reg-but- reg-but-1" onClick={() => this.findEtherians()}>FIND MY ETHERIANS</div>
                                            </div>
                                            <div className="etherianCap">
                                                {this.renderEggs()}
                                                {this.renderEtherians()}
                                            </div>
                                        </div>
                                    </div>
                                    <div id="footer" className="imageless">
                                        <div className="footleft">
                                            <Link to="/"><img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" /></Link>
                                        </div>
                                        <div className="footright">
                                            © Copyright 2022
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}