import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Helmet } from "react-helmet";
import * as lang from '../../lang';

var user_configuration = {
    shape: "circle", //could be "circle" or "square"
    initial_size: "1px", //initial size of the stars
    final_size: "5px", //final size of the stars after expansion
    expand_speed: "1s", //how fast the stars get bigger, in milliseconds
    fade_delay: "1s", //how long until the star fades out
    fade_duration: "1s", //how long the star fades for
    colors: ["hsla(62, 50%,50%, 0.5)", "rgba(255,255,255,0.5)", "hsla(180, 72%, 52%, 0.5)"], //The variety of colors of the stars. Can be any CSS complient color (eg. HEX, rgba, hsl)
    frequency: 100, //how often a new wave of stars pop-out (in milliseconds. Bigger==longer)
    density: 25,//how many stars pop out per wave
    keep_lit: false, //whether the stars disappear after they are created
    rotation: true, //whether the stars rotate through out their expansion
    coverage: 1, //how much of the element's area the stars will show up in (0-1)
    target_class: '.starlight', //the elements the script will target based on the class name
    custom_svg: "" //if you want to use a custom svg with a shape of a star instead (not supported yet)
};

var advanced_configuration = {
    expand_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    expand_delay: "1s",  //how long until the star starts to expand
    rotation_transition_timing: "linear",  //could be ease, ease-in, ease-out, etc
    rotation_angle: "360deg", //up to how much to rotate to
    rotation_duration: "1s", //how long the rotation will take place
    rotation_delay: "0s", //how long until rotation starts
    fade_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    z_index: 0 //the stars are absolutely positioned, so you can give them a z-index of whatever you wish
};

function Star(width, height) {
    //the offsets are required so that when a user specifies a coverage, the coverage is based around the center of the div, and not the top left
    let leftOffset = Math.round((width - width * user_configuration.coverage) / 2);
    let topOffset = (height - Math.round(height * user_configuration.coverage)) / 2;
    this.xposition = Math.floor(Math.random() * width * user_configuration.coverage) + leftOffset;
    this.yposition = Math.floor(Math.random() * height * user_configuration.coverage) + topOffset;
}

Star.prototype.create = function (parent_element, id) {
    //The container is there so that when the stars expand they exapand around the center
    var star = $('<div></div>');
    var star_container = $('<div id="starlight-star' + id + '"></div>');
    // star_container.attr("id","star"+id);
    star_container.append(star);

    //so the star stays centered as its container expands
    star.css({
        position: "absolute",
        top: "-50%",
        left: "-50%",
        width: "100%",
        height: "100%"
    });

    //the initial CSS properties of the star, including color, position, and size
    star_container.css({
        width: user_configuration.initial_size,
        height: user_configuration.initial_size,
        position: 'absolute',
        top: this.yposition,
        left: this.xposition,
        "z-index": advanced_configuration.z_index
    });

    //sets transition css properties of the star
    setTimeout(function () {
        star_container.css({ //size expand properties
            transition: "height " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay + "," +
                "width " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay,
            width: user_configuration.final_size,
            height: user_configuration.final_size
        });

        //because transition properties override each other, have to create a variable for transition and append transitions on to it
        if (user_configuration.rotation) { //rotation properties
            star.css({
                transform: "rotate(" + advanced_configuration.rotation_angle + ")"
            });
            var transition = advanced_configuration.rotation_duration + " " + advanced_configuration.rotation_transition_timing + " " + advanced_configuration.rotation_delay;
        }

        if (!user_configuration.keep_lit) {//fading properties
            star.css({
                opacity: 0
            });
            if (transition) {
                transition += ",opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }
            else {
                transition = "opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }

            //removes the element from the dom after it fades out
            setTimeout(function () {
                $("#starlight-star" + id).remove();
            }, css_time_to_milliseconds(user_configuration.fade_duration) + css_time_to_milliseconds(user_configuration.fade_delay));
        }

        if (transition) {
            star.css({
                transition: transition
            });
        }

    }, 10);

    //sets shape and color of the star
    if (user_configuration.shape === 'circle') {
        star.css('border-radius', '50%');
    }
    if (user_configuration.custom_svg === '' || user_configuration.custom_svg === false) {
        star.css('background-color', user_configuration.colors[Math.floor(Math.random() * user_configuration.colors.length)]); //picks one of the colors
    }
    parent_element.append(star_container);
};

function css_time_to_milliseconds(time_string) {
    var num = parseFloat(time_string, 10),
        unit = time_string.match(/m?s/),
        milliseconds;

    if (unit) {
        unit = unit[0];
    }

    switch (unit) {
        case "s": // seconds
            milliseconds = num * 1000;
            break;
        case "ms": // milliseconds
            milliseconds = num;
            break;
        default:
            milliseconds = 0;
            break;
    }

    return milliseconds;
}

let onClickOutside = (e) => {
    if (!e.target.className.includes('langcontrol')) {
        $('.isActive').removeClass('isActive');
        changeDropInState();

    }
};

let changeDropInState = () => { };


export default class RoadMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false
        }
        changeDropInState = () => {
            this.setState({
                isDropdownOpen: false
            })
        }
    }

    componentDidMount() {
        $(function () {
            window.addEventListener("click", onClickOutside);
        })
        var id = 0;
        //traverses all of the elements with a class of 'starlight'
        $(user_configuration.target_class).each(function (index) {
            var currentElement = $(this);
            var width = currentElement.width();
            var height = currentElement.height();
            setInterval(function () { //creates the stars based on the frequency and desired density
                for (var i = 0; i < user_configuration.density; i++) {
                    var newStar = new Star(width, height);
                    newStar.create(currentElement, id);
                    newStar = null; //just in case so the garbage collector clears this value up
                    id++;
                }
            }, user_configuration.frequency);
        });
    }

    dumpDate(str1, str2, str3) {
        let sec3;
        if (str3) {
            sec3 = <div className="thebody">{str3}</div>
        }
        return (
            <div className="belly">
                <div className="timez">
                    {str1}
                </div>
                <h1 className="thetitle">
                    {str2}
                </h1>
                {sec3}
            </div>
        )
    }
    onTouched(lang) {
        this.props.changeLanguage(lang);
        this.setState({
            isDropdownOpen: false
        })
    }


    openLang() {
        if (this.state.isDropdownOpen) {
            return;
        }
        this.setState({
            isDropdownOpen: true
        })
    }

    componentWillUnmount() {
        window.removeEventListener("click", onClickOutside);
    }

    renderLang() {
        // let isActive = "langcontrol ";
        // if (this.state.isDropdownOpen) {
        //     isActive = "langcontrol isActive";
        // }
        // let arr = ['en', 'ch', 'jp', 'ru', 'kr'];
        // let z = this.props.lang;
        // let temp = arr.indexOf(z);
        // arr.splice(temp, 1);
        // arr.unshift(z);
        // return (
        //     <div id="lang-drop2" className={isActive} onClick={() => this.openLang()}>
        //         {
        //             arr.map(d => {
        //                 return (
        //                     <img className="langcontrol" src={`https://woe2.s3.amazonaws.com/${d}.png`} onClick={() => { this.onTouched(d) }} alt="language icon" />
        //                 )
        //             })
        //         }
        //     </div>
        // )
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>World of Ether | Roadmap</title>
                </Helmet>
                <div id="roadmap">
                    <div className="starlight">
                        <div>
                            <div className="wrap">
                                <div id="navigation">
                                    <div id="logo">
                                        <Link to="/">
                                            <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                                        </Link>
                                    </div>
                                    <div id="menu-right">
                                        {this.renderLang()}
                                        <Link to="/whitelist">
                                            <div className={`mask3 ${this.props.lang}`}>
                                                <div className="mask-inner">
                                                    WHITELIST
                                                </div>
                                            </div>
                                        </Link>
                                        <Link to="/woedex">
                                            <div className={`mask3 ${this.props.lang}`}>
                                                <div className="mask-inner">
                                                    {lang.collection[this.props.lang]}
                                                </div>
                                            </div>
                                        </Link>
                                        {/* <Link to="/inventory">
                                        <div className={`rmmobile  mask2 ${this.props.lang}`} style={{ width: 117 }}>
                                            <div className="mask-inner">
                                                INVENTORY
                                            </div>
                                        </div>
                                    </Link> */}
                                        {/* <Link to="/buy">
                                        <div className={`rmmobile  mask2 ${this.props.lang}`} style={{ width: 117 }}>
                                            <div className="mask-inner">
                                                PURCHASE
                                            </div>
                                        </div>
                                    </Link> */}
                                    </div>
                                </div>
                                <div id="roadwrap">
                                    <div id="sigmagrind" />
                                    <div id="roady" style={{ marginTop: 75 }} className="thin-wrap">
                                        <h1 className="maintext">Roadmap</h1>
                                        <h1 className="submaintext">Follow the progress as we build a new experience</h1>
                                        <div id="circa">
                                            <div id="circa2">
                                                <div id="circa3">
                                                </div>
                                            </div>
                                        </div>
                                        {this.dumpDate("Mar 26th", "Whitelist Open", "Feel free to register for a whitelist spot.")}
                                        {this.dumpDate("Mar 31st", "Beta Gameplay", "Anybody can jump connect a metamask wallet to their discord and join a discord PVP and staking experience. This is f2p and a starter will be provided to anyone! Any etherian created during this process can be claimed on the blockchain anytime post launch.")}
                                        {this.dumpDate("May 6th", "Migration", "Everyone who tried to purchase on ethereum with a failed transaction will receive 1 etherian for their wasted gas. Everyone who did manage to mint will receive double the etherians!")}
                                        {this.dumpDate("May 7th", "Egg Sale Starts", "5000 eggs are available for purchase @ 6pm EDT. A large percent of the sale is retained for game growth as detailed below.")}
                                        {this.dumpDate("August", "Meta-expansion", "Allow players to delve into the World of Ether, Claim land and Build!!")}
                                        {this.dumpDate("Q4 2022", "WOE Digital Manga/Comic book", "The start of a series where each etherian holder receives dividends if his etherian is used in comic!")}
                                        {this.dumpDate("Q3 2023", "WOE Anime", "A brand new series based around the dense WOE lore.")}
                                    </div>
                                </div>
                                <div id="footer" className="imageless">
                                    <div className="footleft">
                                        <Link to="/"><img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" /></Link>
                                    </div>
                                    <div className="footright">
                                        © Copyright 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}