import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import * as lang from '../../lang';
import * as moment from 'moment';
const BigNumber = require('bignumber.js');

var loadingTimer;

export default class BuyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faction: "sun",
            playerFaction: 0,
            numberSold: 0,
            contractState: 0,
            isPaused: false,
            loading: true,
            power: 0,
            stakeState: 0,
            salePrice: 0,
            increase: 0,
            eth2USD: 2692.32,
            openSide: false,

        }
    }

    componentDidMount() {
        loadingTimer = setInterval(async () => {
            this.getData();
        }, 1000);

        fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',
        {
            headers: {
                'Accept': 'application/json'
            },
            method: "GET"
        })
        .then(async (res) => {
            let z = await res.json()
            this.setState({eth2USD: z.USD})
        })
        .catch(async (res) => {
            console.log(`inside catch`);
        })
    }

    async getData() {
        if (window) {
            if (window.web3) {
                if (window.ethereum) {
                    try {
                        let contractState = await this.props.drizzle.contracts.Woe.methods.state().call();
                        let isPaused = await this.props.drizzle.contracts.Woe.methods.paused().call();
                        let numberSold = await this.props.drizzle.contracts.Woe.methods.etherianCount().call();
                        let playerFaction = await this.props.drizzle.contracts.Woe.methods.faction(this.props.drizzleState.accounts[0]).call();
                        let power = await this.props.drizzle.contracts.Woe.methods.power(this.props.drizzleState.accounts[0]).call();
                        let stakeState = await this.props.drizzle.contracts.Woe.methods.stakeState(this.props.drizzleState.accounts[0]).call();
                        let salePrice = await this.props.drizzle.contracts.Woe.methods.salePrice().call();
                        let increase = await this.props.drizzle.contracts.Woe.methods.priceIncrease().call();
                        let faction = this.state.faction;

                        if(playerFaction === "1"){
                            faction = "sun"
                        }else if(playerFaction === "2"){
                            faction = "ocean"
                        }else if(playerFaction === "3"){
                            faction = "life"
                        }else if(playerFaction === "4"){
                            faction = "death"
                        }else if(playerFaction === "5"){
                            faction = "astral"
                        }
                        this.setState({
                            faction: faction,
                            contractState: parseInt(contractState),
                            isPaused: isPaused,
                            numberSold: numberSold,
                            loading: false,
                            power: power,
                            playerFaction: playerFaction,
                            stakeState: stakeState,
                            salePrice: this.props._globals._toEth(new BigNumber(salePrice)),
                            increase: increase
                        })
                    } catch (error) {
                        this.setState({
                            playerFaction: "0",
                            loading: false,
                        })
                    }
                }
            }
        }
    }

    changeFaciton(x) {
        this.setState({ faction: x })
    }

    componentWillUnmount() {
        clearInterval(loadingTimer);
    }

    async aBuy(quant, buffer) {
        if(this.state.contractState === 0){
            alert("Sale didn't start yet");
            return;
        }
        let fac;
        if (this.state.playerFaction !== "0") {
            fac = this.state.playerFaction;
        } else {
            if (this.state.faction === "sun") {
                fac = 1;
            } else if (this.state.faction === "ocean") {
                fac = 2;
            } else if (this.state.faction === "life") {
                fac = 3;
            } else if (this.state.faction === "death") {
                fac = 4;
            } else if (this.state.faction === "astral") {
                fac = 5;
            }
        }
        let tarNum = new BigNumber(this.props._globals._toWei(this.state.salePrice)).times(quant);
        if (quant === 5){
            tarNum = tarNum.add(new BigNumber(this.state.increase).times(quant - 1)); 
        }
        let buff = new BigNumber(this.state.increase).times(buffer);
        tarNum = tarNum.add(buff);
        await this.props.drizzle.contracts.Woe.methods.purchase(quant, fac).send({ value: tarNum, });
    }

    changeFacEgg(e, tar) {
        if(this.state.playerFaction !== "0"){
            return;
        }
        if (tar === "faction") {
            this.setState({
                faction: e.target.value
            })
        } 
    }

    clickChangeFac(val){
        if(this.state.playerFaction !== "0"){
            return;
        }
        this.setState({
            faction: val
        })
    }

    renderTokenDescip(){
        let gendescrip;
        let tokdescrip;
        if(this.state.faction === "sun"){
            gendescrip = "The Preservation Faction. Great for defending and teamwork.";
            tokdescrip = "Sun tokens boost player defense for 24 hours.";
        }
        if(this.state.faction === "ocean"){
            gendescrip = "The Exchange Faction. Great for players who want type diversity.";
            tokdescrip = "Ocean tokens allow you to change the type of an etherian."
        }
        if(this.state.faction === "life"){
            gendescrip = "The Creation Faction.  Great for players who want to ramp up the etherian population.";
            tokdescrip = "Allow you to birth a new etherian within your faction."

        }
        if(this.state.faction === "death"){
            gendescrip = "The Aggression Faction. Great for players who love PVP and quick rewards.";
            tokdescrip = "Allow you to attack and steal tokens from other players."

        }
        if(this.state.faction === "astral"){
            gendescrip = "The Upgrade Faction. Great for players who want to min-max.";
            tokdescrip = "Allow you to power up your etherians and create rares or legendaries."

        }
        return (
            <div id="anightcap">
            <div className="arleft">
                <div className="lefttopz">
                    <h1>{this.state.faction.toUpperCase()} FACTION</h1>
                </div>
                <div className="leftmidz">
                    {gendescrip}
                </div>
            </div>
            <div className="arright">
                <div className="tokey">
                    <img alt="token" src={`https://woe2.s3.amazonaws.com/${this.state.faction}tok.png`}/>
                    <p>{this.state.faction.toUpperCase()} TOKEN</p>
                    <div className="subtokey">
                        {tokdescrip}
                    </div>
                </div>
            </div>
        </div>
        )
    }

    renderBackImg(){
        let cancelHov = "";
        let pf = this.state.playerFaction;
        if(pf !== "0"){
            cancelHov = " cancelhov "
        }
        let sun = "cubby"
        let ocean = "cubby"
        let life = "cubby"
        let death = "cubby"
        let astral = "cubby"
        let cf = "cubby cancelhov";
        if(pf === "1"){
            ocean = cf;
            life = cf;
            death = cf;
            astral = cf;
        }
        if(pf === "2"){
            sun = cf;
            life = cf;
            death = cf;
            astral = cf;
        }
        if(pf === "3"){
            sun = cf;
            ocean = cf;
            death = cf;
            astral = cf;
        }
        if(pf === "4"){
            sun = cf;
            ocean = cf;
            life = cf;
            astral = cf;
        }
        if(pf === "5"){
            sun = cf;
            ocean = cf;
            life = cf;
            death = cf;
        }
        return <>
            <div className="someimgcon">
                <img src={`https://woe2.s3.amazonaws.com/staticbacks/${this.state.faction}bg.jpg`} className={`adjj adjust_${this.state.faction}`} alt='mainegg' />
                <img src="https://woe2.s3.amazonaws.com/rubyegg.png" className="eggz" alt='mainegg' />
            </div>
            {/*  */}
            <div className="af-label-text subump">CHOOSE FACTION</div>
            <div className="cubbycontain">
                <div onClick={() => this.clickChangeFac('sun')} className={this.state.faction === "sun" ? `cubby ${cancelHov} activz` : `cubby${cancelHov}` }><img className="giffy" alt="gif"src="https://woe2.s3.amazonaws.com/trunc/sunbg.gif" />
                    <img className="cubbyimg altcubby" src="https://woe2.s3.amazonaws.com/newmonsters/013.png" alt="etherian"/>
                    <div>What</div>
                </div>
                <div  onClick={() => this.clickChangeFac('ocean')} className={this.state.faction === "ocean" ? `cubby ${cancelHov} activz` : `cubby${cancelHov}` }><img className="giffy" alt="gif" src="https://woe2.s3.amazonaws.com/trunc/oceanbg.gif" />
                    <img className="cubbyimg"  src="https://woe2.s3.amazonaws.com/newmonsters/057.png" alt="etherian"/>
                </div>
                <div  onClick={() => this.clickChangeFac('life')} className={this.state.faction === "life" ? `cubby ${cancelHov} activz` : `cubby${cancelHov}` }><img className="giffy" alt="gif" src="https://woe2.s3.amazonaws.com/trunc/lifebg.gif" />
                    <img className="cubbyimg"  src="https://woe2.s3.amazonaws.com/newmonsters/108.png" alt="etherian"/>
                </div>
                <div  onClick={() => this.clickChangeFac('death')} className={this.state.faction === "death" ? `cubby ${cancelHov} activz` : `cubby${cancelHov}` }><img className="giffy" alt="gif" src="https://woe2.s3.amazonaws.com/trunc/deathbg.gif" />
                    <img className="cubbyimg"  src="https://woe2.s3.amazonaws.com/newmonsters/135.png" alt="etherian"/>
                </div>
                <div  onClick={() => this.clickChangeFac('astral')} className={this.state.faction === "astral" ? `cubby ${cancelHov} activz` : `cubby${cancelHov}` }><img className="giffy" alt="gif" src="https://woe2.s3.amazonaws.com/trunc/astralbg.gif" />
                    <img className="cubbyimg"  src="https://woe2.s3.amazonaws.com/newmonsters/198.png" alt="etherian"/>
                </div>
            </div>
            <div className="cubbycontain" >
                <div className={sun} onClick={() => this.clickChangeFac('sun')}><div className="textedme">SUN</div></div>
                <div className={ocean} onClick={() => this.clickChangeFac('ocean')}><div className="textedme">OCEAN</div></div>
                <div className={life} onClick={() => this.clickChangeFac('life')}><div className="textedme">LIFE</div></div>
                <div className={death} onClick={() => this.clickChangeFac('death')}><div className="textedme">DEATH</div></div>
                <div className={astral} onClick={() => this.clickChangeFac('astral')}><div className="textedme">ASTRAL</div></div>
            </div>
        </>
    }
    
    opencloseadvanced(){
        this.setState({openSide: !this.state.openSide});
    }

    render() {
        let finalString = "SALE LIVE"
        
        let dropDown = <>
            <select name="cars" id="select-drop3" value={this.state.faction} onChange={(e) => this.changeFacEgg(e, "faction")}>
                <option value="sun">Sun</option>
                <option value="ocean">Ocean</option>
                <option value="life">Life</option>
                <option value="death">Death</option>
                <option value="astral">Astral</option>
            </select>
        </>
        if(this.state.playerFaction !== "0"){
            dropDown = <>
            <select disabled name="cars" id="select-drop3" value={this.state.faction} onChange={(e) => this.changeFacEgg(e, "faction")}>
                <option value="sun">Sun</option>
                <option value="ocean">Ocean</option>
                <option value="life">Life</option>
                <option value="death">Death</option>
                <option value="astral">Astral</option>
            </select>
        </>
        }
        return (
            <>
                <div className="jumbo2">
                    <div className="starlight">
                        <div className="wrap">
                            <div id="navigation">
                                <div id="logo">
                                    <Link to="/">
                                        <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                                    </Link>
                                </div>
                                <div id="menu-right">
                                    <Link to="/roadmap">
                                        <div className={`mask ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                {lang.roadmap[this.props.lang]}
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/woedex">
                                        <div className={`mask2 ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                {lang.collection[this.props.lang]}
                                            </div>
                                        </div>
                                    </Link>
                                    {/* <Link to="/inventory">
                                        <div className={`rmmobile  mask2 ${this.props.lang}`} style={{ width: 117 }}>
                                            <div className="mask-inner">
                                                INVENTORY
                                            </div>
                                        </div>
                                    </Link> */}
                                    {/* <Link to="/inventory">
                                        <div className={`mask2 ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                MY WALLET
                                            </div>
                                        </div>
                                    </Link> */}
                                </div>
                            </div>
                            <div id="main-sect" className="not-thin-wrap">
                                <div id="rightEgg">
                                    <div className={`topLane ${this.props.lang}`}>
                                        <div className={`creeps ${this.props.lang}`}>Sale At</div>
                                        <div className={`timur ${this.props.lang}`}><b>{finalString}</b></div>
                                    </div>
                                    <h1 className={`justBuy ${this.props.lang}`}>purchase eggs</h1>
                                    <p className={`mainbuytext ${this.props.lang}`}>
                                        Buy an egg to hatch an etherian. Every 1000th purchase is a guaranteed legendary.
                                        The price below is the LIVE price. 
                                        Click 'Advanced' if you want an increased buffer.
                                        The excess ethereum gets sent back to you.
                                    </p>
                                    <div id="noticeBox" className="anedi">
                                        <img src="https://woe2.s3.amazonaws.com/cInfoicon.png" alt="info icon" />
                                        <div className="someszh someszh2">
                                            Mint starts at 0 ETH but it increases 0.0001 each purchase.
                                        </div>
                                    </div>
                                    <div className={`pillowbox ${this.props.lang}`}>
                                        <div className={`atoptext ${this.props.lang}`}>egg price &nbsp; &nbsp; &nbsp;&nbsp; {this.state.numberSold} Minted</div>
                                        <div className={`midlane ${this.props.lang}`}>
                                            <h1 className={`priceETH ${this.props.lang}`}>{this.state.salePrice} eth</h1>
                                            <div className={`priceUSD ${this.props.lang}`}>${(this.state.salePrice * this.state.eth2USD).toFixed(2)} USD</div>
                                        </div>
                                        <div className={`botlane ${this.props.lang}`}>
                                        {this.state.openSide? 
                                        <>
                                        { this.state.contractState === 0 ? 
                                            <div className="reg-but- reg-but-4 noclicky" >CONNECT TO MAINNET</div>
                                            :
                                            <>
                                                <div className="reg-but- reg-but-4" onClick={() => this.aBuy(1, 100)}>BUY ONE 100X BUFFER</div>
                                                <div className="reg-but- reg-but-4" onClick={() => this.aBuy(5,  300)}>BUY FIVE 300X BUFFER</div>
                                                <div className="reg-but- reg-but-4" onClick={() => this.aBuy(5,  1000)}>BUY FIVE 1000X BUFFER</div>
                                            </>
                                        }
                                        </>
                                        : 
                                        <>
                                        <div className="af-label-text">CHOOSE FACTION</div>
                                        {dropDown}
                                        { this.state.contractState === 0 ? 
                                            <div className="reg-but- reg-but-4 noclicky" >CONNECT TO MAINNET</div>
                                            :
                                            <div className="reg-but- reg-but-4" onClick={() => this.aBuy(1,  0)}>PURCHASE 1 EGG</div>
                                        }
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        { this.state.contractState === 0 ? 
                                            null
                                            :
                                            <div className="reg-but- reg-but-3" onClick={() => this.aBuy(5,  0)}>PURCHASE 5 EGGS</div>
                                        }
                                        </>
                                        }
                                        

                                        </div>
                                    </div>
                                <div onClick={() => this.opencloseadvanced() }className="reg-but- reg-but-3 new-edito">Advanced</div>
                                </div>
                                <div id="leftEgg">
                                    {this.renderBackImg()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="undervie">
                    {this.renderTokenDescip()}
                </div>
            </>
        );
    }
}