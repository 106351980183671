const BigNumber = require('bignumber.js');

function _toEth(someNum) {
    return someNum.div(1000000000000000000).toNumber();
}

function _toWei(someNum) {
    return new BigNumber(someNum).times(1000000000000000000).toNumber();
}

function getName(num) {
    let name;
    switch (num) {
        case 0:
            name = 'Genesis';
            break;
        case 1:
            name = 'Chary';
            break;
        case 2:
            name = 'Lizen';
            break;
        case 3:
            name = 'Bitor';
            break;
        case 4:
            name = 'Pixie';
            break;
        case 5:
            name = 'Croakie';
            break;
        case 6:
            name = 'Pinnoria';
            break;
        case 7:
            name = 'Furriel';
            break;
        case 8:
            name = 'Deway';
            break;
        case 9:
            name = 'Solquent';
            break;
        case 10:
            name = 'Cindercat';
            break;
        case 11:
            name = 'Matchstick';
            break;
        case 12:
            name = 'Parradone';
            break;
        case 13:
            name = 'Radacopter';
            break;
        case 14:
            name = 'Zenon';
            break;
        case 15:
            name = 'Salahan';
            break;
        case 16:
            name = 'Spinsnak';
            break;
        case 17:
            name = 'Flameep';
            break;
        case 18:
            name = 'Hermes';
            break;
        case 19:
            name = 'Owlire';
            break;
        case 20:
            name = 'Dracolire';
            break;
        case 21:
            name = 'Estufara';
            break;
        case 22:
            name = 'Pyrefox';
            break;
        case 23:
            name = 'Maraptor';
            break;
        case 24:
            name = 'Fyrecrun';
            break;
        case 25:
            name = 'Malflame';
            break;
        case 26:
            name = 'Crait';
            break;
        case 27:
            name = 'Mcram';
            break;
        case 28:
            name = 'Corgei';
            break;
        case 29:
            name = 'Knifator';
            break;
        case 30:
            name = 'Poundro';
            break;
        case 31:
            name = 'Griffendal';
            break;
        case 32:
            name = 'Obsidian';
            break;
        case 33:
            name = 'Cerebros';
            break;
        case 34:
            name = 'Snared';
            break;
        case 35:
            name = 'Devilora';
            break;
        case 36:
            name = 'Spikernal';
            break;
        case 37:
            name = 'Tuem';
            break;
        case 38:
            name = 'Bloodfang';
            break;
        case 39:
            name = '火の龍 (Hi no ryū)';
            break;
        case 40:
            name = 'Ashbringer';
            break;
        case 41:
            name = 'Doefan';
            break;
        case 42:
            name = 'Bleab';
            break;
        case 43:
            name = 'Bobuggar';
            break;
        case 44:
            name = 'Seajell';
            break;
        case 45:
            name = 'CoralCandy';
            break;
        case 46:
            name = 'Floam';
            break;
        case 47:
            name = 'Piranum';
            break;
        case 48:
            name = 'Popfish';
            break;
        case 49:
            name = '监护人 (Jiān hù rén)';
            break;
        case 50:
            name = 'Mordreel';
            break;
        case 51:
            name = 'Deepsting';
            break;
        case 52:
            name = 'Lobtra';
            break;
        case 53:
            name = 'Muramasa';
            break;
        case 54:
            name = 'Sharapanel';
            break;
        case 55:
            name = 'Starvore';
            break;
        case 56:
            name = 'Whagnevrone';
            break;
        case 57:
            name = 'Emberon';
            break;
        case 58:
            name = 'Aqualos';
            break;
        case 59:
            name = 'Seafu';
            break;
        case 60:
            name = 'Baquosos';
            break;
        case 61:
            name = 'Amoeblu';
            break;
        case 62:
            name = 'Octograw';
            break;
        case 63:
            name = 'Goldfee';
            break;
        case 64:
            name = 'Whaleed';
            break;
        case 65:
            name = 'Seen';
            break;
        case 66:
            name = 'Kragvern';
            break;
        case 67:
            name = 'Unifish';
            break;
        case 68:
            name = 'Semount';
            break;
        case 69:
            name = 'Twindee';
            break;
        case 70:
            name = 'Puffist';
            break;
        case 71:
            name = 'Gragle';
            break;
        case 72:
            name = 'Cryozal';
            break;
        case 73:
            name = 'Donofrio';
            break;
        case 74:
            name = 'Scorphand';
            break;
        case 75:
            name = 'Illustrion';
            break;
        case 76:
            name = 'Fridgorn';
            break;
        case 77:
            name = 'Tyrafin';
            break;
        case 78:
            name = 'Banshee';
            break;
        case 79:
            name = 'Maxilla';
            break;
        case 80:
            name = 'Neleus';
            break;
        case 81:
            name = 'Baambe';
            break;
        case 82:
            name = 'Viperon';
            break;
        case 83:
            name = 'Lawnmauler';
            break;
        case 84:
            name = 'Deecamp';
            break;
        case 85:
            name = 'Rosie';
            break;
        case 86:
            name = 'Breegle';
            break;
        case 87:
            name = 'Droggo';
            break;
        case 88:
            name = 'Cauliagon';
            break;
        case 89:
            name = 'Catcus';
            break;
        case 90:
            name = 'Sirshone';
            break;
        case 91:
            name = 'Zeeburus';
            break;
        case 92:
            name = 'Cuteberry';
            break;
        case 93:
            name = 'Krythe';
            break;
        case 94:
            name = 'Flauron';
            break;
        case 95:
            name = 'Rindosan';
            break;
        case 96:
            name = 'Spade';
            break;
        case 97:
            name = 'Scolosect';
            break;
        case 98:
            name = 'Mossey';
            break;
        case 99:
            name = 'Clouwly';
            break;
        case 100:
            name = 'Chloropan';
            break;

        case 101:
            name = 'Verchiro';
            break;
        case 102:
            name = 'Gumbie';
            break;
        case 103:
            name = 'Snakocile';
            break;
        case 104:
            name = 'Mistadon';
            break;
        case 105:
            name = 'Carrotar';
            break;
        case 106:
            name = 'Mistpod';
            break;
        case 107:
            name = 'Compost';
            break;
        case 108:
            name = 'Radakitty';
            break;
        case 109:
            name = 'Savageoccoli';
            break;
        case 110:
            name = 'Woodmort';
            break;
        case 111:
            name = 'Slidesap';
            break;
        case 112:
            name = 'Cabbash';
            break;
        case 113:
            name = 'Demascus';
            break;
        case 114:
            name = 'Versillion';
            break;
        case 115:
            name = 'Terrafang';
            break;
        case 116:
            name = 'Toro';
            break;
        case 117:
            name = 'Serperupt';
            break;
        case 118:
            name = 'Tyranolantern';
            break;
        case 119:
            name = 'Leeninja';
            break;
        case 120:
            name = 'Slither';
            break;
        case 121:
            name = 'Bonemouth';
            break;
        case 122:
            name = 'Spinackle';
            break;
        case 123:
            name = 'Latroxx';
            break;
        case 124:
            name = 'Evicera';
            break;
        case 125:
            name = 'Deadsteed';
            break;
        case 126:
            name = 'Nightage';
            break;
        case 127:
            name = 'Dermathesis';
            break;
        case 128:
            name = 'Nepharaf';
            break;
        case 129:
            name = 'Akamaltachi';
            break;
        case 130:
            name = 'Agony';
            break;
        case 131:
            name = 'Unseen';
            break;
        case 132:
            name = 'Chilomori';
            break;
        case 133:
            name = 'Akamanto';
            break;
        case 134:
            name = 'Hemosite';
            break;
        case 135:
            name = 'Killamungo';
            break;
        case 136:
            name = 'Chort';
            break;
        case 137:
            name = 'Cauldromorte';
            break;
        case 138:
            name = 'Plight';
            break;
        case 139:
            name = 'Polpetta';
            break;
        case 140:
            name = 'Wyrm';
            break;
        case 141:
            name = 'Maggoth';
            break;
        case 142:
            name = 'Trilatinous';
            break;
        case 143:
            name = 'Osciliator';
            break;
        case 144:
            name = 'Konsruct';
            break;
        case 145:
            name = 'Kermy';
            break;
        case 146:
            name = 'Belzar';
            break;
        case 147:
            name = 'Sturm';
            break;
        case 148:
            name = 'Orcrotte';
            break;
        case 149:
            name = 'Azalee';
            break;
        case 150:
            name = 'Notre';
            break;
        case 151:
            name = 'Thane';
            break;
        case 152:
            name = 'Oratorum';
            break;
        case 153:
            name = 'Draith';
            break;
        case 154:
            name = 'Dreaden';
            break;
        case 155:
            name = 'Amalgamation';
            break;
        case 156:
            name = 'Necronomica';
            break;
        case 157:
            name = 'Blackwing';
            break;
        case 158:
            name = 'Rabid';
            break;
        case 159:
            name = 'Broodmother';
            break;
        case 160:
            name = 'Diskord';
            break;
        case 161:
            name = 'Cryslist';
            break;
        case 162:
            name = 'Smeeley';
            break;
        case 163:
            name = 'Flubi';
            break;
        case 164:
            name = 'Ephimrir';
            break;
        case 165:
            name = 'Andromador';
            break;
        case 166:
            name = 'Azimutt';
            break;
        case 167:
            name = 'Dritzy';
            break;
        case 168:
            name = 'Ora';
            break;
        case 169:
            name = 'Bazu';
            break;
        case 170:
            name = 'Klahk';
            break;
        case 171:
            name = 'Obeigh';
            break;
        case 172:
            name = 'Horus';
            break;
        case 173:
            name = 'Peliquinn';
            break;
        case 174:
            name = 'Frinn';
            break;
        case 175:
            name = 'Space-Saver 3000';
            break;
        case 176:
            name = 'Flameaw';
            break;
        case 177:
            name = 'Era';
            break;
        case 178:
            name = 'Theta';
            break;
        case 179:
            name = 'Shadowcree';
            break;
        case 180:
            name = 'Seemsam';
            break;
        case 181:
            name = 'Quilevo';
            break;
        case 182:
            name = 'Sheldon';
            break;
        case 183:
            name = 'Krung';
            break;
        case 184:
            name = 'Beholdant';
            break;
        case 185:
            name = 'Murdran';
            break;
        case 186:
            name = 'Seibon';
            break;
        case 187:
            name = 'Crouth';
            break;
        case 188:
            name = 'Knell';
            break;
        case 189:
            name = 'Mindsnire';
            break;
        case 190:
            name = 'Eon';
            break;
        case 191:
            name = 'Cymian';
            break;
        case 192:
            name = 'Magnivoid';
            break;
        case 193:
            name = 'Throddar';
            break;
        case 194:
            name = 'Collectra';
            break;
        case 195:
            name = 'Nebule';
            break;
        case 196:
            name = 'Garf';
            break;
        case 197:
            name = 'Egoh';
            break;
        case 198:
            name = 'Maldragon';
            break;
        case 199:
            name = 'Geosauria';
            break;
        case 200:
            name = 'Astrend';
            break;
        case 201:
            name = 'Jolide';
            break;
        case 202:
            name = 'Maxalar';
            break;
        default:
            name = '';
    }
    return name;
}

export var _globals = {
    _toWei,
    _toEth,
    getName
};