import React from 'react';
import { Link } from 'react-router-dom';
import { getDatabase, get, ref, child } from "firebase/database";
import * as moment from 'moment';

var loadingTimer;

export default class ActivityPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: {},
            loading: true,
        }
    }
    
    async getData() {
        if (window) {
            if (window.web3) {
                if (window.ethereum) {
                    if (this.props.drizzleState) {
                        try {
                            let tarLoc = this.props.dataLocale.toLowerCase() + "accounts/" + this.props.drizzleState.accounts[0].toLowerCase() + "/activity";
                            const dbRef = ref(getDatabase());
                            get(child(dbRef, tarLoc)).then((snapshot) => {
                            if (snapshot.exists()) {
                                    this.setState({
                                        activity: snapshot.val(),
                                        loading: false,
                                    })
                            } else {
                                this.setState({
                                    loading: false
                                })
                            }
                            }).catch((error) => {
                                console.error(error);
                            });
                            clearInterval(loadingTimer);
                        } catch (error) {
                            this.setState({
                                loading: false
                            })
                            clearInterval(loadingTimer);
                        }
                    }
                }
            }
        }
    }

    async componentDidMount() {
        loadingTimer = setInterval(async () => {
            this.getData();
        }, 100);
    }

    componentWillUnmount() {
        clearInterval(loadingTimer);
    }

    renderActivity(){
        var keyOrder = Object.keys(this.state.activity).sort((a, b) => {
            return this.state.activity[a].timestamp < this.state.activity[b].timestamp ? 1 : -1
        })
        return keyOrder.map(z => {
            let x = this.state.activity[z];
            let time = moment.unix(x.timestamp);
            time = time.format("MM/DD hh:MM A")
            let descrip; 
            if(x.activity === "AstralPower"){
                descrip = "Astral Power-up"
            }else if (x.activity === "EtherianBorn"){
                descrip = "Etherian Birth"
            }else if (x.activity === "OceanFactionChange"){
                descrip = "Ocean Type Switch"
            }
            return <div key={z}>
                {descrip} {time}
            </div>
        })
    }

    render() {
        return (
            <div className="whiteout">
                <Link to="/">Page list</Link>
                <h2 style={{ fontSize: 35, marginTop: 50 }}>Activity Page</h2>
                {this.state.loading ? 
                    <h2>Loading...</h2>
                :
                    <>
                    {this.renderActivity()}
                    </>
                }
                <div className="w-button" onClick={() => { console.log(this.state) }}>State Dump</div>
            </div>
        )
    }
}