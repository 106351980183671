import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import * as lang from '../../lang';
import parse from 'html-react-parser';

var someAnim;
let bg = 4; // background image
let etherian = [
    [1, 33, 8, 12, 17, 39], // sun etherians
    [49, 51, 54, 63, 73, 76], // ocean
    [81, 82, 84, 89, 96, 100, 105, 107, 117], // life
    [121, 122, 123, 125, 142, 147, 157], // death
    [161, 162, 179, 189, 199, 194] // astral
];

var user_configuration = {
    shape: "circle", //could be "circle" or "square"
    initial_size: "1px", //initial size of the stars
    final_size: "5px", //final size of the stars after expansion
    expand_speed: "1s", //how fast the stars get bigger, in milliseconds
    fade_delay: "1s", //how long until the star fades out
    fade_duration: "1s", //how long the star fades for
    colors: ["hsla(62, 50%,50%, 0.5)", "rgba(255,255,255,0.5)", "hsla(180, 72%, 52%, 0.5)"], //The variety of colors of the stars. Can be any CSS complient color (eg. HEX, rgba, hsl)
    frequency: 100, //how often a new wave of stars pop-out (in milliseconds. Bigger==longer)
    density: 15,//how many stars pop out per wave
    keep_lit: false, //whether the stars disappear after they are created
    rotation: true, //whether the stars rotate through out their expansion
    coverage: 1, //how much of the element's area the stars will show up in (0-1)
    target_class: '.starlight', //the elements the script will target based on the class name
    custom_svg: "" //if you want to use a custom svg with a shape of a star instead (not supported yet)
};

var advanced_configuration = {
    expand_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    expand_delay: "1s",  //how long until the star starts to expand
    rotation_transition_timing: "linear",  //could be ease, ease-in, ease-out, etc
    rotation_angle: "360deg", //up to how much to rotate to
    rotation_duration: "1s", //how long the rotation will take place
    rotation_delay: "0s", //how long until rotation starts
    fade_transition_timing: "linear", //could be ease, ease-in, ease-out, etc
    z_index: 0 //the stars are absolutely positioned, so you can give them a z-index of whatever you wish
};

function Star(width, height) {
    //the offsets are required so that when a user specifies a coverage, the coverage is based around the center of the div, and not the top left
    let leftOffset = Math.round((width - width * user_configuration.coverage) / 2);
    let topOffset = (height - Math.round(height * user_configuration.coverage)) / 2;
    this.xposition = Math.floor(Math.random() * width * user_configuration.coverage) + leftOffset;
    this.yposition = Math.floor(Math.random() * height * user_configuration.coverage) + topOffset;
}

Star.prototype.create = function (parent_element, id) {
    //The container is there so that when the stars expand they exapand around the center
    var star = $('<div></div>');
    var star_container = $('<div id="starlight-star' + id + '"></div>');
    // star_container.attr("id","star"+id);
    star_container.append(star);

    //so the star stays centered as its container expands
    star.css({
        position: "absolute",
        top: "-50%",
        left: "-50%",
        width: "100%",
        height: "100%"
    });

    //the initial CSS properties of the star, including color, position, and size
    star_container.css({
        width: user_configuration.initial_size,
        height: user_configuration.initial_size,
        position: 'absolute',
        top: this.yposition,
        left: this.xposition,
        "z-index": advanced_configuration.z_index
    });

    //sets transition css properties of the star
    setTimeout(function () {
        star_container.css({ //size expand properties
            transition: "height " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay + "," +
                "width " + user_configuration.expand_speed + " " + advanced_configuration.expand_transition_timing + " " + advanced_configuration.expand_delay,
            width: user_configuration.final_size,
            height: user_configuration.final_size
        });

        //because transition properties override each other, have to create a variable for transition and append transitions on to it
        if (user_configuration.rotation) { //rotation properties
            star.css({
                transform: "rotate(" + advanced_configuration.rotation_angle + ")"
            });
            var transition = advanced_configuration.rotation_duration + " " + advanced_configuration.rotation_transition_timing + " " + advanced_configuration.rotation_delay;
        }

        if (!user_configuration.keep_lit) {//fading properties
            star.css({
                opacity: 0
            });
            if (transition) {
                transition += ",opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }
            else {
                transition = "opacity " + user_configuration.fade_duration + " " + advanced_configuration.fade_transition_timing + " " + user_configuration.fade_delay;
            }

            //removes the element from the dom after it fades out
            setTimeout(function () {
                $("#starlight-star" + id).remove();
            }, css_time_to_milliseconds(user_configuration.fade_duration) + css_time_to_milliseconds(user_configuration.fade_delay));
        }

        if (transition) {
            star.css({
                transition: transition
            });
        }

    }, 10);

    //sets shape and color of the star
    if (user_configuration.shape === 'circle') {
        star.css('border-radius', '50%');
    }
    if (user_configuration.custom_svg === '' || user_configuration.custom_svg === false) {
        star.css('background-color', user_configuration.colors[Math.floor(Math.random() * user_configuration.colors.length)]); //picks one of the colors
    }
    parent_element.append(star_container);
};

function css_time_to_milliseconds(time_string) {
    var num = parseFloat(time_string, 10),
        unit = time_string.match(/m?s/),
        milliseconds;

    if (unit) {
        unit = unit[0];
    }

    switch (unit) {
        case "s": // seconds
            milliseconds = num * 1000;
            break;
        case "ms": // milliseconds
            milliseconds = num;
            break;
        default:
            milliseconds = 0;
            break;
    }

    return milliseconds;
}

let changeDropInState = () => { };

let onClickOutside = (e, afunc) => {
    if (!e.target.className.includes('langcontrol')) {
        $('.isActive').removeClass('isActive');
        changeDropInState();
    }
};

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false
        }
        changeDropInState = () => {
            this.setState({
                isDropdownOpen: false
            })
        }
    }

    animateMe() {
        $(function () {
            bg = bg + 1;
            // if (bg > 2) {
            if (bg > 4) {
                bg = 0;
            }
            let tar = etherian[bg][Math.floor(Math.random() * etherian[bg].length)]
            $('.monInside').stop(true, true).fadeOut(1200, () => {
                let interrior = String(tar).padStart(3, '0');
                $('.monInside').attr(`class`, `monInside e-${interrior}`);
                $('.monInside').delay(200).attr("src", `https://woe2.s3.amazonaws.com/newmonsters/${interrior}/mid.png`).delay(1700).fadeIn(1200)
            })
            $('.gifback').stop(true, true).delay(700).fadeOut(1000, () => {
                let z;
                if (bg === 0) {
                    z = "https://woe2.s3.amazonaws.com/trunc/sunbg.gif"
                } else if (bg === 1) {
                    z = "https://woe2.s3.amazonaws.com/trunc/oceanbg.gif"
                } else if (bg === 2) {
                    z = "https://woe2.s3.amazonaws.com/trunc/lifebg.gif"
                } else if (bg === 3) {
                    z = "https://woe2.s3.amazonaws.com/trunc/deathbg.gif"
                } else if (bg === 4) {
                    z = "https://woe2.s3.amazonaws.com/trunc/astralbg.gif"
                }
                $('.gifback').attr("src", z).fadeIn(700)
            })
        });
    }

    componentDidMount() {
        $(function () {
            window.addEventListener("click", onClickOutside);
        })
        someAnim = setInterval(() => {
            this.animateMe();
        }, 7000);

        var id = 0;
        //traverses all of the elements with a class of 'starlight'
        $(user_configuration.target_class).each(function (index) {
            var currentElement = $(this);
            var width = currentElement.width();
            var height = currentElement.height();
            setInterval(function () { //creates the stars based on the frequency and desired density
                for (var i = 0; i < user_configuration.density; i++) {
                    var newStar = new Star(width, height);
                    newStar.create(currentElement, id);
                    newStar = null; //just in case so the garbage collector clears this value up
                    id++;
                }
            }, user_configuration.frequency);
        });
    }

    componentWillUnmount() {
        clearInterval(someAnim);
        window.removeEventListener("click", onClickOutside);
    }

    onTouched(lang) {
        this.props.changeLanguage(lang);
        this.setState({
            isDropdownOpen: false
        })
    }

    openLang() {
        if (this.state.isDropdownOpen) {
            return;
        }
        this.setState({
            isDropdownOpen: true
        })
    }

    renderLang() {
        let isActive = "langcontrol ";
        if (this.state.isDropdownOpen) {
            isActive = "langcontrol isActive";
        }
        let arr = ['en', 'ch', 'jp', 'ru', 'kr'];
        let z = this.props.lang;
        let temp = arr.indexOf(z);
        arr.splice(temp, 1);
        arr.unshift(z);
        return null;
        return (
            <div id="lang-drop" className={isActive} onClick={() => this.openLang()}>
                {
                    arr.map(d => {
                        return (
                            <img className="langcontrol " src={`https://woe2.s3.amazonaws.com/${d}.png`} onClick={() => { this.onTouched(d) }} alt="language icon" />
                        )
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <>
                <div className="jumbo jumbump">
                    <div className="starlight">
                        <div className="wrap">
                            <div id="navigation">
                                <div id="logo">
                                    <Link to="/">
                                        <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                                    </Link>
                                </div>
                                <div id="menu-right">
                                    {this.renderLang()}
                                    <Link to="/roadmap">
                                        <div className={`rmmobile mask ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                {lang.roadmap[this.props.lang]}
                                            </div>
                                            {/* <img src="https://woe2.s3.amazonaws.com/metamask.png" alt="metamask icon" /> */}
                                        </div>
                                    </Link>
                                    <Link to="/woedex">
                                        <div className={`mask3 ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                {lang.collection[this.props.lang]}
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/whitelist">
                                        <div className={`mask3 ${this.props.lang}`}>
                                            <div className="mask-inner">
                                                WHITELIST
                                            </div>
                                        </div>
                                    </Link>
                                    {/* <Link to="/inventory">
                                        <div className={`rmmobile  mask2 ${this.props.lang}`} style={{ width: 117 }}>
                                            <div className="mask-inner">
                                                INVENTORY
                                            </div>
                                        </div>
                                    </Link> */}
                                    {/* <Link to="/buy">
                                        <div className={`rmmobile  mask2 ${this.props.lang}`} style={{ width: 117 }}>
                                            <div className="mask-inner">
                                                PURCHASE
                                            </div>
                                        </div>
                                    </Link> */}
                                    {/* <div id="mask" className={` ${this.props.lang}`}>
                                        <div id="mask-inner">
                                            {lang.connected[this.props.lang]}
                                        </div>
                                        <img src="https://woe2.s3.amazonaws.com/metamask.png" alt="metamask icon" />
                                    </div> */}
                                </div>
                            </div>
                            <div id="main-sect" className="thin-wrap">
                                <a href="https://discord.gg/worldofether" target="blank">
                                    <img className="joindisc joindisc2" src="https://woe2.s3.amazonaws.com/newdiscord.png" alt="discord" />
                                </a>
                                <div id="jumboimgz">
                                    <img id="shipz" src="https://woe2.s3.amazonaws.com/spaceship22.png" alt="Space-ship with smoke" />
                                    <img id="moonz" src="https://woe2.s3.amazonaws.com/twoplanets.png" alt="Two moons" />
                                </div>
                                <h1 className={`new-site-title ${this.props.lang}`}>
                                    {lang.newhome[this.props.lang]}
                                </h1>
                                <div className={`home-join-us ${this.props.lang}`}>
                                    {lang.joinus[this.props.lang]}
                                </div>
                                <div className={`home-mint-us ${this.props.lang}`}>
                                    {/* {lang.mintprice[this.props.lang]} */}
                                    Etherians mint will launch on May 7th 6pm EDT
                                </div>

                                <div id="homegrid" className="grid">
                                    <div className={`cell-t cell-t-1 ${this.props.lang}`}>
                                        {/* <h1 className={`site-title ${this.props.lang}`}>
                                            {lang.the_greatest[this.props.lang]}
                                        </h1> */}

                                        {/* <p className={`site-desc ${this.props.lang}`}>
                                            {parse(lang.land_descrip[this.props.lang])}
                                        </p> */}
                                        {/* <a href="https://discord.gg/worldofether" target="blank">
                                            <img className="joindisc" src="https://woe2.s3.amazonaws.com/newdiscord.png" alt="discord picture" />
                                        </a> */}
                                        {/* <div className={`button ${this.props.lang}`}>
                                                {lang.join_us[this.props.lang]}
                                                <img src="https://woe2.s3.amazonaws.com/discord.png" alt="metamask icon" />
                                            </div> */}
                                        {/* <div className={`button-alt ${this.props.lang}`}>
                                            {lang.buy_nft[this.props.lang]}
                                            <img src="https://woe2.s3.amazonaws.com/opensea.png" alt="metamask icon" />
                                        </div> */}
                                        {/* <div className="square-button">
                                            20 Days 00:30:33 until departure
                                        </div> */}
                                    </div>
                                    <div className={`cell-t ${this.props.lang}`}>
                                        {/* <div className="relativecontain">
                                            <img src="https://woe2.s3.amazonaws.com/trunc/oceanbg.gif" className="gifback" alt="Etherian background" />
                                            <img src="https://woe2.s3.amazonaws.com/monsters/041/mid.png" className="monInside e-041" alt="example etherian" />
                                        </div> */}
                                        {/* <p className="sub-img-text">{parse(lang.mint_price[this.props.lang])}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="midSection">
                    <h1 className={`specif ${this.props.lang}`}>
                        {lang.monsters[this.props.lang]}
                    </h1>
                    <p className={`midinfo ${this.props.lang}`}>
                        {lang.exp_1[this.props.lang]}
                    </p>
                    <div className="wrap ">
                        <div className="midgrid">
                            <div className="cell">
                                <img src="https://woe2.s3.amazonaws.com/monsters/013/mid.png" className="adjustmon adj1" alt="example etherian" />
                                <img src="https://woe2.s3.amazonaws.com/trunc/sunbg.gif" className="mud" alt="Etherian background" />
                            </div>
                            <div className="cell">
                                <img src="https://woe2.s3.amazonaws.com/monsters/057/mid.png" className="adjustmon adj2" alt="example etherian" />
                                <img src="https://woe2.s3.amazonaws.com/trunc/oceanbg.gif" className="mud" alt="Etherian background" />
                            </div>
                            <div className="cell">
                                <img src="https://woe2.s3.amazonaws.com/monsters/108/mid.png" className="adjustmon adj3" alt="example etherian" />
                                <img src="https://woe2.s3.amazonaws.com/trunc/lifebg.gif" className="mud" alt="Etherian background" />
                            </div>
                            <div className="cell">
                                <img src="https://woe2.s3.amazonaws.com/monsters/135/mid.png" className="adjustmon adj4" alt="example etherian" />
                                <img src="https://woe2.s3.amazonaws.com/trunc/deathbg.gif" className="mud" alt="Etherian background" />
                            </div>
                            <div className="cell">
                                <img src="https://woe2.s3.amazonaws.com/monsters/198/mid.png" className="adjustmon adj5" alt="example etherian" />
                                <img src="https://woe2.s3.amazonaws.com/trunc/astralbg.gif" className="mud" alt="Etherian background" />
                            </div>
                        </div>
                        {/* <Link to="/woedex">
                            <div className={`viewbutton ${this.props.lang}`}>
                                {lang.view_all[this.props.lang]}
                            </div>
                        </Link> */}
                    </div>
                    <div id="romanMid">
                        <div id="romanTop" className={`${this.props.lang}`}>
                            {/* {lang.eachtype[this.props.lang]} */}
                            {parse(lang.eachtype[this.props.lang])}
                        </div>
                        <div id="someImgHold">
                            <img src="https://woe2.s3.amazonaws.com/newmonsters/043.png" className="singlez" alt="an etherian" />
                            <img src="https://woe2.s3.amazonaws.com/newmonsters/069.png" className="singlez" alt="an etherian" />
                            <img src="https://woe2.s3.amazonaws.com/newmonsters/076.png" className="singlez" alt="an etherian" />
                            <img src="https://woe2.s3.amazonaws.com/newmonsters/080.png" className="singlez" alt="an etherian" />
                        </div>
                        <Link to="/woedex">
                            <div className="romanbutton">
                                {lang.vcollection[this.props.lang]}
                            </div>
                        </Link>
                    </div>
                    <div id="royalMid">
                        <div id="royalMidContain">
                            <div className="lf-side">
                                <div className="tp-side">
                                    {lang.monstake[this.props.lang]}
                                </div>
                                <div className="md-side">
                                    {lang.w_gamer[this.props.lang]}
                                </div>
                                <a target="blank" href="https://guide.worldofether.com">
                                    <div className="romanbutton">
                                        {lang.gameplaydeets[this.props.lang]}
                                    </div>
                                </a>
                            </div>
                            <div className="rt-side">
                                <img className="wall-img" src="https://woe2.s3.amazonaws.com/walletimg-min.png" alt="wallet pic" />
                                <img className="crys-img" src="https://woe2.s3.amazonaws.com/crys-min.png" alt="wallet pic" />
                            </div>
                        </div>
                    </div>
                    <div id="cynImg">
                        <div className="cynImgHold">
                            <h1>
                                {lang.fcpvp[this.props.lang]}
                            </h1>
                            <p>
                                {lang.pvpserver[this.props.lang]}
                            </p>
                        </div>
                    </div>
                    <div id="adhdsec">
                        <div id="bigOne">
                            <span className="adot">...</span>{lang.waitwtf1[this.props.lang]}<span className="acomma">, </span>{lang.waitwtf2[this.props.lang]}
                        </div>
                        <div className="subBigSect">
                            {lang.relaxf[this.props.lang]}
                        </div>
                        <div id="lightgrid">
                            <div className="shot filled">
                                <img src="https://woe2.s3.amazonaws.com/tarrotcards/suntarrot.png" className="tarrot" alt="tarrot card" />
                                <h1 className={`titlesec suntt ${this.props.lang}`}>SUN</h1>
                                <div className={`subb ${this.props.lang}`}>
                                    {lang.rep_sun[this.props.lang]}
                                </div>
                                <div className="roundClip">
                                    <div className="imgholder suntt">
                                        <img src="https://woe2.s3.amazonaws.com/suntok.png" alt="token" />
                                    </div>
                                </div>
                            </div>
                            <div className="shot filled">
                                <img src="https://woe2.s3.amazonaws.com/tarrotcards/oceantarrot.png" className="tarrot" alt="tarrot card" />
                                <h1 className={`titlesec ocean ${this.props.lang}`} >OCEAN</h1>
                                <div className={`subb ${this.props.lang}`}>
                                    {lang.rep_ocean[this.props.lang]}
                                </div>
                                <div className="roundClip">
                                    <div className="imgholder ocean">
                                        <img src="https://woe2.s3.amazonaws.com/oceantok.png" alt="token" />
                                    </div>
                                </div>
                            </div>
                            <div className="shot filled">
                                <img src="https://woe2.s3.amazonaws.com/tarrotcards/lifetarrot.png" className="tarrot" alt="tarrot card" />
                                <h1 className={`titlesec life ${this.props.lang}`} >LIFE</h1>
                                <div className={`subb ${this.props.lang}`}>
                                    {lang.rep_life[this.props.lang]}
                                </div>
                                <div className="roundClip">
                                    <div className="imgholder life">
                                        <img src="https://woe2.s3.amazonaws.com/lifetok.png" alt="token" />
                                    </div>
                                </div>
                            </div>
                            <div className="shot filled">
                                <img src="https://woe2.s3.amazonaws.com/tarrotcards/deathtarrot.png" className="tarrot" alt="tarrot card" />
                                <h1 className={`titlesec deathtt ${this.props.lang}`} >DEATH</h1>
                                <div className={`subb ${this.props.lang}`}>
                                    {lang.rep_death[this.props.lang]}
                                </div>
                                <div className="roundClip">
                                    <div className="imgholder deathtt">
                                        <img src="https://woe2.s3.amazonaws.com/deathtok.png" alt="token" />
                                    </div>
                                </div>
                            </div>
                            <div className="shot filled">
                                <img src="https://woe2.s3.amazonaws.com/tarrotcards/astraltarrot.png" className="tarrot" alt="tarrot card" />
                                <h1 className={`titlesec astral ${this.props.lang}`} >ASTRAL</h1>
                                <div className={`subb ${this.props.lang}`}>
                                    {lang.rep_astral[this.props.lang]}
                                </div>
                                <div className="roundClip">
                                    <div className="imgholder astral">
                                        <img src="https://woe2.s3.amazonaws.com/astraltok.png" alt="token" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="oceanlike">
                        <div id="cardinalWrap">
                            <div id="cardinalLeft">
                                <div id="cardinalTop" className={`${this.props.lang}`}>
                                    {lang.whyjoinj[this.props.lang]}
                                </div>
                                <p className={`cardinalMid ${this.props.lang}`}>
                                    {lang.aside_1[this.props.lang]}
                                </p>
                                <a target="blank" href="https://guide.worldofether.com/">
                                    <div className={`romanbutton fullkonmobile ${this.props.lang}`}>
                                        WHITEPAPER
                                    </div>
                                </a>
                            </div>
                            <img alt="seen etherian" id="oceanseen" src="https://tidesofmagic.s3.amazonaws.com/bodysmall.png" />
                            <div id="cardinalRight">
                                <div id="circ_1">
                                    <div id="circ_1_in">
                                        Holders can make descicions affecting the to the WOE multiverse
                                    </div>
                                </div>
                                <div id="circ_2">
                                    <div id="circ_2_in">
                                        Governance
                                    </div>
                                </div>
                                <div id="circ_3">
                                    <div id="circ_3_in">
                                        Our earliest members will be part of our lore
                                    </div>
                                </div>

                                <div id="circ_4">
                                    <div id="circ_4_in">
                                        And More!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="lightgrad">
                        <div className="light-top-new">
                            <div className="tita3">
                                THE FUTURE OF WOE
                            </div>
                            <div className="conta3">
                                New games are coming from an ever-growing highly technical<br /> WOE team. Our Lore will grow into it’s own property in the form of Comic Books and Anime. On our way to a World of Ether land sale.
                            </div>
                        </div>
                        <div id="mainTimeline">
                            <img id="mainbar_1" src="https://woe2.s3.amazonaws.com/fadebar.png" alt="fadebar" />
                            <img id="sidebar_1" src="https://woe2.s3.amazonaws.com/parradone.png" alt="paradone" />
                            <img id="sidebar_2" src="https://woe2.s3.amazonaws.com/sceneback.png" alt="fadebar" />
                            <img id="sidebar_3" src="https://woe2.s3.amazonaws.com/flame_HD+(1).gif" alt="chary" />
                            <img id="sidebar_4" src="https://woe2.s3.amazonaws.com/doegif.gif" alt="doefan" />
                            <img id="sidebar_5" src="https://woe2.s3.amazonaws.com/stageset.png" alt="portal" />
                            {/* 
                           

                         */}
                        </div>
                        <Link to="/whitelist">
                            <div className="romanbutton ageold">
                                GET WHITELISTED
                                {/* {lang.roadmap[this.props.lang]} */}
                            </div>
                        </Link>
                    </div>
                    <div id="teambox">
                        <div className="light-top-new">
                            <div className="tita3 t2z">
                                Original Team
                            </div>
                            <div className="conta3  t2z">
                                Creative Crypto Enthusiasts. OGs in the space. <br />Reach out on Discord for collaboration.
                            </div>
                        </div>
                        <div id="foundergrid">
                            <div className="founder_spot">
                                <img src="https://woe2.s3.amazonaws.com/pfps/alainpfp.png" alt="founder pic" />
                                <div className="founder_name">Alain the Creator</div>
                                <div className="founder_role">Game creation/Lore</div>
                            </div>
                            <div className="founder_spot">
                                <img src="https://woe2.s3.amazonaws.com/pfps/rampfp.png" alt="founder pic" />
                                <div className="founder_name">Ram</div>
                                <div className="founder_role">Product</div>
                            </div>
                            <div className="founder_spot">
                                <img src="https://woe2.s3.amazonaws.com/pfps/bryanpfp.png" alt="founder pic" />
                                <div className="founder_name">Bryan</div>
                                <div className="founder_role">Smart Contracts</div>
                            </div>
                            <div className="founder_spot">
                                <img src="https://woe2.s3.amazonaws.com/pfps/chrispfp.png" alt="founder pic" />
                                <div className="founder_name">Chris</div>
                                <div className="founder_role">Design</div>
                            </div>
                        </div>
                        <div id="foundergrid2">
                            <div></div>
                            <div className="founder_spot ">
                                <img src="https://woe2.s3.amazonaws.com/pfps/edwardpfp.png" alt="founder pic" />
                                <div className="founder_name">Edward</div>
                                <div className="founder_role">Marketing Strategist</div>
                            </div>
                            <div className="founder_spot ">
                                <img src="https://woe2.s3.amazonaws.com/pfps/cynpfp.png" alt="founder pic" />
                                <div className="founder_name">Cyn Monster Artist</div>
                                <div className="founder_role">Artist</div>
                            </div>
                            <div className="founder_spot ">
                                <img src="https://woe2.s3.amazonaws.com/pfps/danpfp.png" alt="founder pic" />
                                <div className="founder_name">Dan</div>
                                <div className="founder_role">OST Composer</div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div id="footer">
                        <div className="footleft">
                            <Link to="/"><img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" /></Link>
                        </div>
                        <a href="https://twitter.com/worldofether" target="blank">
                            <img className="aTwitterpic" src="https://woe2.s3.amazonaws.com/logotwitter1.png" alt="Twitter" />
                        </a>
                        <div className="footright">
                            © Copyright 2022
                        </div>
                    </div>
                </div>
            </>
        );
    }
}