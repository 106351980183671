import React from 'react';
import { Link } from 'react-router-dom';

var loadingTimer;
export default class StakingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stakeRewards: false,
            sunStake: false,
            oceanStake: false,
            lifeStake: false,
            deathStake: false, 
            astralStake: false
        }
    }
    
    async getData() {
        if (window) {
            if (window.web3) {
                if (window.ethereum) {
                    if (this.props.drizzleState) {
                        try {
                            let sunStake = await this.props.drizzle.contracts.Woe.methods.stakeRewards(this.props.drizzleState.accounts[0], 1).call();
                            let oceanStake = await this.props.drizzle.contracts.Woe.methods.stakeRewards(this.props.drizzleState.accounts[0], 2).call();
                            let lifeStake = await this.props.drizzle.contracts.Woe.methods.stakeRewards(this.props.drizzleState.accounts[0], 3).call();
                            let deathStake = await this.props.drizzle.contracts.Woe.methods.stakeRewards(this.props.drizzleState.accounts[0], 4).call();
                            let astralStake = await this.props.drizzle.contracts.Woe.methods.stakeRewards(this.props.drizzleState.accounts[0], 5).call();
                            
                            this.setState({
                                sunStake, 
                                oceanStake, 
                                lifeStake, 
                                deathStake, 
                                astralStake,
                                loading: false
                            })

                        } catch (error) {
                            this.setState({
                                loading: false
                            })
                            clearInterval(loadingTimer);
                        }
                    }
                }
            }
        }
    }

    async componentDidMount() {
        loadingTimer = setInterval(async () => {
            this.getData();
        }, 100);
    }
    
    render() {
        return (
            <div className="whiteout">
                <Link to="/">Page list</Link>
                <h2 style={{ fontSize: 35, marginTop: 50 }}>Staking Page</h2>
                {this.state.loading ? 
                <h2>Loading...</h2>:
                <>
                <br/>
                <h2>Staking Rewards Earned per 24 hours</h2>
                <br />
                <h2>
                    <span role="img" aria-label="Sun">☀️</span>
                    {this.state.sunStake} &nbsp;

                    <span role="img" aria-label="Tide">🌊</span>
                    {this.state.oceanStake} &nbsp;

                    <span role="img" aria-label="Broccoli">🥦</span>
                    {this.state.lifeStake} &nbsp;

                    <span role="img" aria-label="Skull">💀</span>
                    {this.state.deathStake} &nbsp;

                    <span role="img" aria-label="Crystal Ball">🔮</span>
                    {this.state.astralStake}</h2> &nbsp;

                <br />
                <br />
                </>
                }
                <div className="w-button" onClick={() => { console.log(this.state) }}>State Dump</div>

            </div>
        )
    }
}