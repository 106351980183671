import React from 'react';
import { Link } from 'react-router-dom';
import { getDatabase, get, ref, child } from "firebase/database";

export default class EtherianViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hasValue: true,
            etherian: {},
            targetWallet: "",
            tokenCount: 1
        }
    }

    componentDidMount() {
        let tarLoc = this.props.dataLocale.toLowerCase() + "etherians/" + this.props.routeProps.match.params.id.toString();
        const dbRef = ref(getDatabase());
        get(child(dbRef, tarLoc)).then((snapshot) => {
            if (snapshot.exists()) {
                    this.setState({
                        etherian: snapshot.val(),
                        hasValue: true,
                        loading: false
                    })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    changeCount(e) {
        let val = e.target.value.replace(/[^0-9]/g, '');
        this.setState({ tokenCount: val });
    }

    async safeXfer() {
        await this.props.drizzle.contracts.Woe.methods.safeTransferFrom(
            this.state.etherian.owner,
            this.state.targetWallet,
            this.props.routeProps.match.params.id
        ).send();
    }

    changeWallet(e) {
        let val = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        this.setState({ targetWallet: val.toLowerCase() })
    }

    async astralPowerUp() {
        await this.props.drizzle.contracts.Crystals.methods.astralPower(
            parseInt(this.props.routeProps.match.params.id),
            this.state.tokenCount
        ).send();
    }

    async changeType(x){
        await this.props.drizzle.contracts.Crystals.methods.ocean(
            (parseInt(this.props.routeProps.match.params.id) - 1),
            x
        ).send();
    }

    renderOceanButton(){
        if(this.state.etherian.id){
            let rarity;
            if (this.state.etherian.species === 0) {
                rarity = "Legendary"
            } else if (this.state.etherian.species < 22) {
                rarity = "Common"
            } else if (this.state.etherian.species < 33) {
                rarity = "Uncommon"
            } else if (this.state.etherian.species <= 39) {
                rarity = "Rare"
            } else {
                rarity = "Legendary"
            }
            let sun = <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.changeType(1) }}>
                <span role="img" aria-label="Sun">☀️</span>
                </div>;
            let ocean =  <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.changeType(2) }}>
                <span role="img" aria-label="Tide">🌊</span>
                </div>;
            let life = <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.changeType(3) }}>
                <span role="img" aria-label="Broccoli">🥦</span>
                </div>;
            let death = <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.changeType(4) }}>
                <span role="img" aria-label="Skull">💀</span>
                </div>;
            let astral = <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.changeType(5) }}>
                <span role="img" aria-label="Crystal Ball">🔮</span>
                </div>;

            if(this.state.etherian.eType === 1){
                sun = null;
            }else if(this.state.etherian.eType === 2){
                ocean = null;
            }else if(this.state.etherian.eType === 3){
                life = null;
            }else if(this.state.etherian.eType === 4){
                death = null;
            }else if(this.state.etherian.eType === 5){
                astral = null;
            }
            if(rarity === "Uncommon" || rarity === "Common"){
                return <>
                <br />
                <p>Convert etherian to type</p>
                {sun} {ocean} {life} {death} {astral}
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                </>
            }
        }
    }
    renderOwnerControls() {
        if (window) {
            if (window.web3) {
                if (window.ethereum && this.state.etherian) {
                    if (this.props.drizzleState) {
                        if (this.props.drizzleState.accounts) {
                            if (this.props.drizzleState.accounts[0] && this.state.etherian.owner) {
                                if (this.props.drizzleState.accounts[0].toLowerCase() === this.state.etherian.owner.toLowerCase()) {
                                    return <div style={{ minHeight: 120 }}>
                                        <br />
                                        <p>Use Astral token to power up</p>
                                        <input className="someInp alt"
                                            value={this.state.tokenCount}
                                            onChange={(e) => this.changeCount(e)}
                                        />
                                        <div className="w-button" style={{
                                            float: 'left',
                                            minWidth: 0,
                                            width: 30,
                                            paddingLeft: 10,
                                            paddingRight: 10
                                        }} onClick={() => { this.astralPowerUp() }}>
                                            <span role="img" aria-label="Crystal Ball">🔮</span>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        {this.renderOceanButton()}

                                        <p>SafeTransfer Etherian</p>
                                        <input className="someInp"
                                            value={this.state.targetWallet}
                                            onChange={(e) => this.changeWallet(e)}
                                            placeholder='0x123...' />
                                        <div className="w-button" style={{ float: 'left' }} onClick={() => { this.safeXfer() }}>Send</div>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div>;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    renderEtherian() {
        if (this.state.loading) {
            return <h2>Loading...</h2>
        } else if (!this.state.hasValue) {
            return <h2>Etherian not found</h2>
        } else {
            let x = this.state.etherian;
            let name;
            let species = x.species;
            let faction = "sun";
            if (x.eType === 2) {
                species = species + 40;
                faction = "ocean";
            } else if (x.eType === 3) {
                species = species + 80;
                faction = "life";

            } else if (x.eType === 4) {
                species = species + 120;
                faction = "death";

            } else if (x.eType === 5) {
                species = species + 160;
                faction = "astral";
            }
            let rarity;
            if (x.species === 0) {
                rarity = "Legendary"
            } else if (x.species < 22) {
                rarity = "Common"
            } else if (x.species < 33) {
                rarity = "Uncommon"
            } else if (x.species <= 39) {
                rarity = "Rare"
            } else {
                rarity = "Legendary"
            }
            name = this.props._globals.getName(species);
            let gold = x.isGold ? "gold" : null;
            let goldStr = x.isGold ? "g" : "";
            return (
                <div style={{ float: "left", height: 180, }}>
                    <br />
                    <br />
                    <h2>#{x.id} {name} </h2>
                    <img src={`https://woe2.s3.amazonaws.com/monsters/${String(species).padStart(3, '0')}${goldStr}/avatar.png`} alt="avatar" />
                    <p>{x.power} atk </p>
                    <p>{rarity} {faction} </p>
                    <p>{gold}</p>
                    <p>owner: {x.owner}</p>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="whiteout" >
                <Link to="/inventory">Go Back</Link>
                <h2 style={{ fontSize: 35, marginTop: 50 }}>Etherian View Page</h2>
                {this.renderOwnerControls()}
                {this.renderEtherian()}
                <br />
                <br />
                <br />
                <br />
            </div>
        )
    }
}