// https://translatr.varunmalhotra.xyz/

export interface langs {
    en: string;
    jp: string;
    kr: string;
    ru: string;
    ch: string;
    sp: string;
};
/*

    &.ru {}
    &.kr {}
    &.ch {}
    &.jp {}
    &.sp {}

export var _: langs = {
    en: "",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

*/

export var aside_1: langs = {
    en: "Aside from backing a team that pioneered cryptogaming in 2018. 40% of minted sales will go to growing and maintaining the World of Ether and it’s future projects. We want this to be a community driven project.",
    jp: "2018年にクリプトゲーミングを開拓したチームを支援することは別として、ミント販売の40％は、World ofEtherとその将来のプロジェクトの成長と維持に費やされます。これをコミュニティ主導のプロジェクトにしたいと考えています。",
    kr: "2018년에 크립토게이밍을 개척한 팀을 지원하는 것 외에도 발행된 판매의 40%는 World of Ether와 미래 프로젝트의 성장 및 유지에 사용될 것입니다. 우리는 이것이 커뮤니티 주도 프로젝트가 되기를 바랍니다.",
    ru: "Помимо поддержки команды, которая стала пионером криптогейминга в 2018 году, 40% продаж будут направлены на развитие и поддержку World of Ether и его будущих проектов. Мы хотим, чтобы это был общественный проект.",
    ch: "除了支持 2018 年开创加密游戏的团队之外。40% 的铸造销售额将用于发展和维护以太世界及其未来的项目。我们希望这是一个社区驱动的项目。",
    sp: "Además de respaldar a un equipo que fue pionero en el criptojuego en 2018, el 40 % de las ventas acuñadas se destinarán al crecimiento y mantenimiento del Mundo de Ether y sus proyectos futuros. Queremos que este sea un proyecto impulsado por la comunidad."
};

export var whyjoinj: langs = {
    en: "WHY JOIN THE JOURNEY?",
    jp: "なぜ旅に参加するのか?",
    kr: "여행에 참여해야 하는 이유?",
    ru: "ЗАЧЕМ ПРИСОЕДИНЯТЬСЯ К ПУТЕШЕСТВИЮ?",
    ch: "为什么加入旅程?",
    sp: "POR QUÉ UNIRSE AL VIAJE?"
};

export var pvpserver: langs = {
    en: "Join a faction and rob other players of their newly earned tokens. A percentage of their earnings will fill your pocket, the rest will go to our vault that funds the World of Ether metaverse.",
    jp: "派閥に参加して、PVPで新しく獲得したトークンを他のプレイヤーから奪ってください。彼らの収入の一部はあなたのポケットを埋め、残りはWorld ofEtherユニバースに資金を提供する私たちの金庫に行きます。",
    kr: "파벌에 가입하고 PVP에서 새로 얻은 토큰을 다른 플레이어에게 강탈하십시오. 수입의 일부는 귀하의 주머니를 채우고 나머지는 World of Ether 세계에 자금을 제공하는 금고로 갈 것입니다.",
    ru: "Присоединяйтесь к фракции и отнимайте у других игроков только что заработанные жетоны в нашем PVP. Часть их доходов наполнит ваш карман, остальное пойдет в наше хранилище, которое финансирует вселенную World of Ether.",
    ch: "加入一个派系并在我们的 PVP 中抢夺其他玩家新获得的代币。他们收入的一部分将填满您的口袋，其余的将进入我们为以太世界提供资金的金库。",
    sp: "Únete a una facción y roba a otros jugadores sus fichas recién ganadas en nuestro JcJ. Un porcentaje de sus ganancias llenará tu bolsillo, el resto irá a nuestra bóveda que financia el universo World of Ether."
};

export var gameplaydeets: langs = {
    en: "MORE ON GAMEPLAY",
    jp: "ゲームプレイ",
    kr: "게임 플레이",
    ru: "Геймплей",
    ch: "游戏玩法",
    sp: "Como se Juega"
};

export var relaxf: langs = {
    en: "Relax friend, they match the Etherian types. Each faction member can birth Etherians of their type and each Etherian mints a special tokens when you stake them.",
    jp: "友達をリラックスさせてください、彼らはEtherianタイプと一致します。各派閥メンバーはそのタイプのイーサリアンを誕生させることができ、各イーサリアンはあなたがそれらを賭けるときに特別なトークンをミントします。",
    kr: "릴랙스 친구, 그들은 Etherian 유형과 일치합니다. 각 파벌 구성원은 해당 유형의 Etherians를 생성할 수 있으며 각 Etherian은 스테이크할 때 특별한 토큰을 발행합니다.",
    ru: "Расслабься, друг, они соответствуют этерианскому типу. Каждый член фракции может рождать Этерианцев своего типа, и каждый Этерианец чеканит специальные жетоны, когда вы их ставите.",
    ch: "放松的朋友，它们与 Etherian 类型相匹配。每个派系成员都可以生成他们类型的以太人，并且每个以太人在您投入时都会铸造一个特殊的代币。",
    sp: "Relájate amigo, coinciden con los tipos Etherian. Cada miembro de la facción puede generar Etherians de su tipo y cada Etherian acuña una ficha especial cuando los apostas."
};

export var fcpvp: langs = {
    en: "FACTION BASED PVP",
    jp: "派閥ベースのPVP",
    kr: "진영 기반 PVP",
    ru: "ФРАКЦИОННОЕ PVP",
    ch: "基于派系的 PVP",
    sp: "JcJ BASADO EN FACCIONES"
};

export var waitwtf1: langs = {
    en: "WAIT",
    jp: "WAIT",
    kr: "WAIT",
    ru: "WAIT",
    ch: "WAIT",
    sp: "WAIT"
};
export var waitwtf2: langs = {
    en: "WTF ARE FACTIONS?",
    jp: "WTF ARE FACTIONS?",
    kr: "WTF ARE FACTIONS?",
    ru: "WTF ARE FACTIONS?",
    ch: "WTF ARE FACTIONS?",
    sp: "WTF ARE FACTIONS?"
};
export var w_gamer: langs = {
    en: "A token sale with gamification! Buy NFTs and stake your wallet to earn crystals that can increase the rarity or change the species of any Etherian in your collection.",
    jp: "ゲーミフィケーション付きのトークンセール！ NFTを購入し、財布を賭けてクリスタルを獲得すると、コレクション内のモンスターの希少性を高めたり、種を変更したりできます。",
    kr: "게임화를 통한 토큰 세일! NFT를 구매하고 지갑을 스테이킹하여 희귀도를 높이거나 컬렉션에 있는 모든 몬스터의 종을 변경할 수 있는 수정을 얻으십시오.",
    ru: "Продажа токенов с геймификацией! Покупайте NFT и делайте ставки в своем кошельке, чтобы заработать кристаллы, которые могут увеличить редкость или изменить вид любого монстра в вашей коллекции.",
    ch: "带有游戏化的代币销售！购买 NFT 并质押您的钱包以赚取水晶，这些水晶可以增加稀有度或改变您收藏中任何怪物的种类。",
    sp: "¡Una venta de fichas con gamificación! Compre NFT y apueste su billetera para ganar cristales que pueden aumentar la rareza o cambiar la especie de cualquier Monstruo en su colección."
};

export var monstake: langs = {
    en: "STAKE YOUR ETHERIANS",
    jp: "モンスターを賭ける",
    kr: "당신의 괴물을 스테이크",
    ru: "Ставьте своих монстров",
    ch: "赌上你的怪物",
    sp: "Apuesta tus monstruos"
};

export var vcollection: langs = {
    en: "VIEW COLLECTION",
    jp: "コレクション",
    kr: "컬렉션 보기",
    ru: "Коллекция",
    ch: "查看收藏",
    sp: "Ver colección"
};

export var eachtype: langs = {
    en: "Each Etherian type has 4 Rarities <br/> Common, Uncommon, Rare and Legendary. Our scientists found that rarer the monster the stronger it is!",
    jp: "各モンスタータイプには、コモン、アンコモン、レア、レジェンダリーの4つのレアリティがあります。私たちの科学者たちは、希少なものもより強力であることを発見しました！",
    kr: "각 몬스터 유형에는 4가지 희귀도가 있습니다. 일반, 희귀, 희귀 및 전설. 우리 과학자들은 희귀한 것들도 더 강하다는 것을 발견했습니다!",
    ru: "У каждого типа монстров есть 4 редкости: обычные, необычные, редкие и легендарные. Наши ученые обнаружили, что более редкие из них также сильнее!",
    ch: "每种怪物类型都有 4 种稀有度，普通、罕见、稀有和传奇。我们的科学家发现，稀有的也更强！",
    sp: "Cada tipo de monstruo tiene 4 rarezas comunes, poco comunes, raras y legendarias. ¡Nuestros científicos descubrieron que los más raros también son más fuertes!"
};

export var mintprice: langs = {
    en: "Etherians mint COST is 0.08 ETH",
    jp: "モンスターは0.08ETHの費用がかかります",
    kr: "몬스터 비용은 0.08 ETH입니다.",
    ru: "Монстры будут стоить 0,08 ETH",
    ch: "怪物将花费 0.08 ETH",
    sp: "Los monstruos costarán 0.08 ETH"
};

export var joinus: langs = {
    en: "Join the World of Ether a pioneer in cryptogaming. 5000 Etherians will be sold. Each Etherian will be playable in a PVP experience. An NFT sale with unique artwork that will start a cryptogaming universe.",
    jp: "私たちの暗号ゲームの世界に参加してください。 5000体のモンスターが販売されます。各モンスターはPVP体験でプレイ可能になります。クリプトゲーミングの世界を開始するユニークなアートワークを備えたNFTセール。",
    kr: "우리의 암호 게임 세계에 참여하십시오. 5000 몬스터가 판매됩니다. 각 몬스터는 PVP 경험에서 플레이할 수 있습니다. 암호 게임 세계를 시작할 독특한 삽화가 포함된 NFT 판매.",
    ru: "Присоединяйтесь к нашему миру криптогейминга. Будет продано 5000 монстров. Каждого монстра можно будет сыграть в режиме PVP. Распродажа NFT с уникальными произведениями искусства, которая положит начало вселенной криптогейминга.",
    ch: "加入我们的密码游戏世界。 5000个怪物将被出售。每个怪物都可以在 PVP 体验中玩。带有独特艺术品的 NFT 销售将开启密码游戏世界。",
    sp: "Únete a nuestro mundo de criptojuegos. Se venderán 5000 monstruos. Cada Monstruo se podrá jugar en una experiencia JcJ. Una venta de NFT con obras de arte únicas que iniciarán un universo de criptojuegos."
};


export var newhome: langs = {
    en: "MONSTERS ON THE BLOCKCHAIN",
    jp: "ブロックチェーン上のモンスター",
    kr: "블록체인의 괴물",
    ru: "МОНСТРЫ НА БЛОКЧЕЙНЕ",
    ch: "区块链上的怪物",
    sp: "Monstruos en el blockchain"
};


export var takemeback: langs = {
    en: "Take me back",
    jp: "連れ帰ってください",
    kr: "다시 데려다",
    ru: "Верни меня",
    ch: "带我回去",
    sp: "Llévame de vuelta"
};

export var seemstobeerror: langs = {
    en: "OOPS THERE SEEMS TO BE AN ERROR",
    jp: "エラーのようです",
    kr: "죄송합니다. 오류가 발생한 것 같습니다.",
    ru: "OOPS, ВИДЕТСЯ ОШИБКОЙ",
    ch: "哎呀好像有错误",
    sp: "OOPS, PARECE HABER UN ERROR"
};

export var faq_q1: langs = {
    en: "When can I buy NFTs for the game?",
    jp: "ゲーム用のNFTはいつ購入できますか？",
    kr: "게임용 NFT는 언제 구매할 수 있나요?",
    ru: "Когда я могу купить NFT для игры?",
    ch: "我什么时候可以为游戏购买 NFT？",
    sp: "¿Cuándo puedo comprar NFT para el juego?"
};

export var faq_a1: langs = {
    en: "Sale day will be announced shortly",
    jp: "発売日はまもなく発表されます",
    kr: "판매일은 곧 공지됩니다",
    ru: "День распродажи будет объявлен в ближайшее время",
    ch: "销售日期将很快公布",
    sp: "El día de la venta se anunciará en breve."
};

export var faq_q2: langs = {
    en: "How can I find out about future projects?",
    jp: `どうすれば将来のプロジェクトを知ることができますか？`,
    kr: "향후 프로젝트에 대해 어떻게 알 수 있습니까?",
    ru: "Как я могу узнать о будущих проектах?",
    ch: "我如何了解未来的项目？",
    sp: "¿Cómo puedo obtener información sobre proyectos futuros?"
};

export var faq_a2: langs = {
    en: "For updates, progress and visions for the franchise please read our roadmap",
    jp: "フランチャイズの最新情報、進捗状況、ビジョンについては、ロードマップをお読みください",
    kr: "프랜차이즈에 대한 업데이트, 진행 상황 및 비전은 로드맵을 참조하십시오.",
    ru: "Чтобы узнать об обновлениях, прогрессе и видении франшизы, ознакомьтесь с нашей дорожной картой.",
    ch: "有关特许经营的更新、进展和愿景，请阅读我们的路线图",
    sp: "Para actualizaciones, avances y visiones de la franquicia, lee nuestro Roadmap"
};

export var faq_q3: langs = {
    en: "What will my NFT give me?",
    jp: "私のNFTは私に何を与えますか？",
    kr: "NFT는 나에게 무엇을 줄까요?",
    ru: "Что мне даст мой NFT?",
    ch: "我的 NFT 会给我什么？",
    sp: "¿Qué me dará mi NFT?"
};

export var faq_a3: langs = {
    en: "Check our discord for full NFT details and giveaways",
    jp: "NFTの詳細と景品については、ソーシャルメディアを確認してください",
    kr: "전체 NFT 세부 정보 및 경품은 소셜 미디어를 확인하세요.",
    ru: "Посетите наши социальные сети для получения полной информации о NFT и розыгрышах",
    ch: "查看我们的社交媒体以获取完整的 NFT 详细信息和赠品",
    sp: "Consulte nuestra discordia para obtener detalles completos de NFT y Giveaways"
};

export var faq_q4: langs = {
    en: "Can I play for free?",
    jp: "無料でプレイできますか？",
    kr: "무료로 플레이할 수 있나요?",
    ru: "Могу ли я играть бесплатно?",
    ch: "我可以免费玩吗？",
    sp: "¿Puedo jugar gratis?"
};
// 


export var faq_a4: langs = {
    en: "World of Ether is a play to earn game, contest and free giveaways may be available in the future",
    jp: "WOEはゲーム、コンテストを獲得するための遊びであり、将来的には無料の景品が利用可能になる可能性があります",
    kr: "WOE는 게임, 콘테스트 및 무료 경품을 미래에 얻을 수 있는 게임입니다.",
    ru: "WOE - это игра, чтобы заработать игру, в будущем могут быть доступны конкурсы и бесплатные раздачи.",
    ch: "WOE 是一种赚钱游戏，未来可能会提供竞赛和免费赠品",
    sp: "WOE es un juego en la modalidad Play-to-Earn, concursos y obsequios gratuitos pueden estar disponibles en el futuro"
};

export var faqexplain: langs = {
    en: "Frequently asked questions will be posted here. You can also reach out to the team on our discord.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa vel placerat pharetra nullam. Elementum nunc, ipsum diam vitae nunc, dui.",
    jp: "よくある質問はこちらに掲載されます。また、私たちの不和でチームに連絡することもできます。Loremipsumdolor sit amet、consectetur adipiscingelit。 Massa vel placerat pharetranullam。 Elementum nunc、ipsum diam vitae nunc、dui。",
    kr: "자주 묻는 질문을 여기에 게시합니다. 우리의 불화에 대해 팀에 연락할 수도 있습니다.Lorem ipsum dolor sitmet, consectetur adipiscing elit. Massa vel placerat pharetra nullam. Elementum nunc, ipsum diam vitae nunc, dui.",
    ru: "Здесь будут размещены часто задаваемые вопросы. Вы также можете обратиться к нашей команде через Discord. Lorem ipsum dolor sit amet, Conctetur adipiscing elit. Massa vel placerat pharetra nullam. Elementum nunc, ipsum diam vitae nunc, dui.",
    ch: "常见问题将在此处发布。您也可以通过我们的 discord 联系团队。Lorem ipsum dolor sat amet, consectetur adipiscing elit。 Massa vel placerat pharetra nullam。 Elementum nunc, ipsum diam vitae nunc, dui。",
    sp: "Preguntas frecuentes van a ser posteadas en este espacio. Por el momento puedes contactarnos directamente vía Discord"
};

export var helpcenter: langs = {
    en: "Help Center",
    jp: "ヘルプセンター",
    kr: "지원 센터",
    ru: "Центр помощи",
    ch: "帮助中心",
    sp: "Centro de ayuda"
};

export var needtoknow: langs = {
    en: "Everything you need to know about the World of Ether.",
    jp: "WOEについて知っておくべきことすべて。",
    kr: "WOE에 대해 알아야 할 모든 것.",
    ru: "Все, что вам нужно знать о WOE.",
    ch: "您需要了解的有关 WOE 的所有信息。",
    sp: "Todo lo que necesitas saber sobre el WOE"
};

export var collection: langs = {
    en: "WOEDEX",
    jp: "コレクション",
    kr: "수집",
    ru: "Коллекция",
    ch: "收藏",
    sp: "colección"
};

export var soundtrack: langs = {
    en: "Soundtrack",
    jp: "音楽",
    kr: "음악",
    ru: "Музыка",
    ch: "音乐",
    sp: "Banda sonora"
};
export var view_all: langs = {
    en: "VIEW THE FULL COLLECTION",
    jp: "すべて表示",
    kr: "모두보기",
    ru: "Увидеть все",
    ch: "查看全部",
    sp: "VER LA COLECCIÓN COMPLETA"
};

export var exp_1: langs = {
    en: "On the World of Ether we have identified 205 unique Etherians that each have power and properties. There are 5 types of Etherians Sun, Ocean, Life, Death and Astral.",
    jp: "この新しい世界で、それぞれが力と特性を持っている205のユニークなモンスターを特定しました。太陽、海、生、死、星の5種類があります。",
    kr: "이 새로운 세계에서 우리는 각각 힘과 속성을 가진 205개의 독특한 몬스터를 식별했습니다. 태양, 바다, 생명, 죽음, 아스트랄의 5가지 유형이 있습니다.",
    ru: "В этом новом мире мы определили 205 уникальных монстра, у каждого из которых есть сила и свойства. Есть 5 типов Солнца, Океана, Жизни, Смерти и Астрала.",
    ch: "在这个新世界中，我们已经确定了 205 个独特的怪物，每个怪物都有力量和属性。有太阳、海洋、生命、死亡和星光五种类型。",
    sp: "En este nuevo mundo, hemos identificado 205 monstruos únicos, cada uno de los cuales tiene poder y propiedades. Hay 5 tipos de Sol, Océano, Vida, Muerte y Astral."
};

export var monsters: langs = {
    en: "ETHERIANS",
    jp: "モンスター",
    kr: "몬스터",
    ru: "Монстры",
    ch: "怪物",
    sp: "Etherians (Monstruos)"
};
export var buy_nft: langs = {
    en: "PURCHASE NFTS",
    jp: "NFTを購入する",
    kr: "NFT 구매",
    ru: "Купить NFT",
    ch: "购买 NFT",
    sp: "COMPRA NFTS"
}

export var join_us: langs = {
    en: "JOIN THE FLIGHT",
    jp: "参加しませんか",
    kr: "우리와 함께",
    ru: "Присоединиться",
    ch: "加入我们",
    sp: "Entra a la pelea"
};

export var mint_price: langs = {
    // en: "Etherians mint at <span>0.3 ETH</span> on launch",
    // jp: "トークンは発売時に <span>0.3 ETH</span> でミントしま",
    // kr: "출시 시 <span>0.3 ETH</span> 에서 토큰 발행",
    // ru: "Токены чеканят по <span>0,3 ETH</span> при запуске",
    // ch: "代币发行时以 <span>0.3 ETH</span> 铸造",
    // sp: "El precio de minteo de los Etherians es <span>0.3 ETH</span> en el lanzamiento",
    en: "Etherian mint price will be announced near launch",
    jp: "に発表されます",
    kr: "민트 가격은 출시 직후 발표될 예정입니다.",
    ru: "Цена в монетном дворе будет объявлена ​​перед запуском",
    ch: "薄荷价格将在发布前公布",
    sp: "El precio de minteo se anunciará cerca del lanzamiento."
};

export var carddesc: langs = {
    en: "World of ether Play to earn 2022 release.",
    jp: "World of Ether 2022リリースを獲得するためにプレイしてください。",
    kr: "World of Ether 플레이하여 2022 릴리스를 획득하세요.",
    ru: "World of Ether Играйте, чтобы заработать релиз 2022 года.",
    ch: "World of Ether 玩以获得 2022 版本。",
    sp: "World of Ether P2E estará disponible en 2022."
};

export var cardsubdesc: langs = {
    en: "Owning NFTs from the sale will be key to receiving airdrops for the World of Ether game launching early next year. <br /> <br /> The sale will also allow governence in the WOE metaverse for all future endeavors.",
    jp: "販売からNFTを所有することは、来年初めに発売されるWorld ofEtherゲームのエアドロップを受け取るための鍵となります。 <br /> <br />この売却により、WOEユニバースでの将来のすべての取り組みに対する統治も可能になります。",
    kr: "판매에서 NFT를 소유하는 것은 내년 초 출시되는 World of Ether 게임의 에어드랍을 받는 열쇠가 될 것입니다. <br /> <br /> 매각은 또한 미래의 모든 노력에 대해 WOE 세계의 거버넌스를 허용할 것입니다.",
    ru: "Получение NFT от продажи будет ключом к получению бонусов для игры World of Ether, которая будет запущена в начале следующего года. <br /> <br /> Продажа также позволит управлять вселенной WOE во всех будущих начинаниях.",
    ch: "从销售中拥有 NFT 将是获得明年初推出的 World of Ether 游戏空投的关键。 <br /> <br /> 此次出售还将允许在 WOE 世界中为所有未来的努力进行治理。",
    sp: "Ser propietario de los NFT de la venta será clave para recibir airdrops para el lanzamiento del juego World of Ether a principios del próximo año. <br /> <br /> La venta también permitirá la gobernanza en el universo WOE para todos los proyectos futuros."
};

export var land_descrip: langs = {
    en: "World of ether will release <span>5000</span> NFTs on the ethereum blockchain. Jump into the game with one of our custom painted creatures.",
    jp: "World of etherは、イーサリアムブロックチェーンで<span>5000</span>のNFTをリリースします。カスタムペイントされたクリーチャーの1つでゲームに飛び込みます。",
    kr: "World of ether는 이더리움 블록체인에서 <span>5000</span>개의 NFT를 출시합니다. 커스텀 페인팅 생물 중 하나와 함께 게임에 뛰어드세요.",
    ru: "World of ether выпустит <span>5000</span> NFT на блокчейне Ethereum. Окунитесь в игру с одним из нарисованных нами существ. ",
    ch: "World of ether 将在以太坊区块链上发布 <span>5000</span> 个 NFT。与我们定制的彩绘生物之一一起进入游戏。",
    sp: "World of ether lanzará <span> 5000 </span> NFTs en ethereum. Entra en el juego con una de nuestras criaturas."
};

export var the_greatest: langs = {
    en: "THE GREATEST COLLECTION EVER ASSEMBLED",
    jp: "史上最高のコレクション",
    kr: "역대 최대 규모의 컬렉션",
    ru: "САМАЯ БОЛЬШАЯ КОЛЛЕКЦИЯ, КОГДА-либо СОБИРАЕМАЯ",
    ch: "有史以来最伟大的集合",
    sp: "LA COLECCIÓN MÁS GRANDE JAMÁS CREADA"
};

export var roadmap: langs = {
    en: "ROADMAP",
    jp: "ロードマップ",
    kr: "로드맵",
    ru: "ДОРОЖНАЯ КАРТА",
    ch: "路线图",
    sp: "ROADMAP"
};

export var connected: langs = {
    en: "CONNECTED",
    jp: "接続済み",
    kr: "연결됨",
    ru: "СВЯЗАНЫ",
    ch: "连接的",
    sp: "CONECTADO"
};

export var menu_1: langs = {
    en: "Home",
    jp: "ホーム",
    kr: "집",
    ru: "Дом",
    ch: "家",
    sp: "Home"
};

export var menu_2: langs = {
    en: "Buy",
    jp: "購入",
    kr: "구입",
    ru: "купить",
    ch: "购买",
    sp: "Comprar"
};

export var menu_3: langs = {
    en: "Inventory",
    jp: "在庫",
    kr: "목록",
    ru: "инвентарь",
    ch: "库存",
    sp: "Inventario"
};

export var foot_2: langs = {
    en: "Privacy",
    jp: "個人情報保護方針",
    kr: "개인 정보 정책",
    ru: "Конфиденциальности",
    ch: "隐私政策",
    sp: "Intimidad"
};

export var foot_3: langs = {
    en: "Guide",
    jp: "ガイド",
    kr: "안내서",
    ru: "Руководство",
    ch: "指南",
    sp: "Guía"
};

export var foot_1: langs = {
    en: "Terms",
    jp: "利用規約",
    kr: "서비스 약관",
    ru: "Условия",
    ch: "服务条款",
    sp: "Condiciones"
};

export var eadd: langs = {
    en: "Ethereum Address",
    jp: "イーサリアムアドレス",
    kr: "이더 리움 주소",
    ru: "Эфириум Адрес",
    ch: "以太坊地址",
    sp: "Billetera Ethereum"
};

export var please_install_metamask: langs = {
    en: "Please install Metamask",
    jp: "Metamaskをインストールしてください",
    kr: "Metamask를 설치하십시오",
    ru: "Пожалуйста, установите Metamask",
    ch: "请安装Metamask",
    sp: "Instale Metamask"
};


export var unlockwallet: langs = {
    en: "Please unlock your <span class='accent'>wallet</span>",
    jp: "<span class='accent'>財布</span>のロックを解除してください",
    kr: "<span class='accent'>지갑</span>을 잠금 해제하십시오",
    ru: "Пожалуйста, разблокируйте свой <span class='accent'>кошелек</span>",
    ch: "请解锁您的<span class='accent'>钱包</span>",
    sp: "Desbloquee su <span class='accent'> billetera </span>"
};

export var wrongnetwork: langs = {
    en: "Please connect to <span class='accent'>rinkeby</span>",
    jp: "<span class='accent'>rinkeby</span>に接続してください",
    kr: "<span class='accent'>rinkeby</span>에 연결하십시오",
    ru: "Пожалуйста, подключитесь<span class='accent'>rinkeby</span>",
    ch: "请连接到<span class='accent'>rinkeby</span>",
    sp: "Conéctese a <span class='accent'> rinkeby </span>"
};


export var rep_sun: langs = {
    en: "Sun tokens increase your wallet’s defense for a limited time.",
    jp: "サントークンは、限られた時間だけウォレットの防御力を高めます。",
    kr: "Sun 토큰은 제한된 시간 동안 지갑의 방어력을 높입니다.",
    ru: "Токены Sun повышают защиту вашего кошелька на ограниченное время.",
    ch: "Sun 代币在有限的时间内增加你的钱包的防御。",
    sp: "Los tokens Sun aumentan la defensa de su billetera por un tiempo limitado."
};
export var rep_ocean: langs = {
    en: "Ocean tokens can be used to switch the type of Etherian.",
    jp: "オーシャントークンを使用して、Etherianのタイプを切り替えることができます。",
    kr: "Ocean 토큰은 Etherian의 유형을 전환하는 데 사용할 수 있습니다.",
    ru: "Жетоны океана можно использовать для переключения типа эфириана.",
    ch: "海洋代币可用于切换以太币的类型。",
    sp: "Las fichas de océano se pueden usar para cambiar el tipo de Etherian."
};
export var rep_life: langs = {
    en: "Life tokens can be used to birth new Etherians.",
    jp: "ライフトークンは、新しいイーサリアンを誕生させるために使用できます。",
    kr: "생명 토큰은 새로운 이더리안을 탄생시키는 데 사용할 수 있습니다.",
    ru: "Жетоны жизни можно использовать для рождения новых эфирианцев.",
    ch: "生命代币可用于诞生新的以太人。",
    sp: "Las fichas de vida se pueden usar para dar a luz nuevos Etherians."
};
export var rep_death: langs = {
    en: "Death tokens can be used to attack other players.",
    jp: "デストークンは、他のプレイヤーを攻撃するために使用できます。",
    kr: "죽음 토큰은 다른 플레이어를 공격하는 데 사용할 수 있습니다.",
    ru: "Жетоны смерти можно использовать для нападения на других игроков.",
    ch: "死亡标记可用于攻击其他玩家。",
    sp: "Las fichas de muerte se pueden usar para atacar a otros jugadores."
};
export var rep_astral: langs = {
    en: "Astral tokens increase Etherian power or create one with specific rarity.",
    jp: "アストラルトークンは、イーサリアンパワーを増加させるか、特定のレア度を持つトークンを作成します。",
    kr: "아스트랄 토큰은 Etherian의 힘을 증가시키거나 특정 희귀도를 가진 토큰을 생성합니다.",
    ru: "Астральные жетоны увеличивают силу эфириана или создают его с определенной редкостью.",
    ch: "Astral 代币增加了 Etherian 的力量或创造了一个具有特定稀有度的代币。",
    sp: "Las fichas astrales aumentan el poder de Etherian o crean uno con una rareza específica."
};

export var each_rarer: langs = {
    en: "Each type is more powerful than the last.",
    jp: "それぞれのタイプは、最後のものよりも強力です。",
    kr: "각 유형은 마지막 유형보다 더 강력합니다.",
    ru: "Каждый тип могущественнее предыдущего.",
    ch: "每种类型都比最后一种更强大。",
    sp: "Cada tipo es más poderoso que el anterior."
};

export var str_typ: langs = {
    en: "TYPES",
    jp: "タイプ",
    kr: "유형",
    ru: "ТИПЫ",
    ch: "类型",
    sp: "TIPOS"
};

export var has_obs: langs = {
    en: "Etherians have been observed in 5 elemental types: Sun, Ocean, Life, Death and the mysterious Astral.",
    jp: "モンスターは5つの元素タイプで観察されています：Sun Ocean Life Death Astral",
    kr: "괴물은 5가지 원소 유형으로 관찰되었습니다: Sun Ocean Life Death Astral",
    ru: "Монстры наблюдаются в 5 типах элементалей: Sun Ocean Life Death Astral",
    ch: "在 5 种元素类型中观察到了怪物： Sun Ocean Life Death Astral",
    sp: "Se han observado monstruos en 5 tipos elementales: Sol, Océano, Vida, Muerte y el misterioso Astral."
};

export var use_formulas: langs = {
    en: "Use formulas, Trade, and combine to discover more.",
    jp: "数式、取引、組み合わせを使用して、詳細を確認してください。",
    kr: "공식을 사용하고 거래하고 결합하여 더 많은 것을 발견하십시오.",
    ru: "Используйте формулы, торгуйте и комбинируйте, чтобы узнать больше.",
    ch: "使用公式、交易和组合来发现更多。",
    sp: "Utilice fórmulas, intercambie y combine para descubrir más."
};

export var sub_mid_t: langs = {
    en: "Advances in technology have allowed our scientists to combine etherian DNA without breeding.",
    // Advances in technology have allowed our scientists to combine monster DNA without breeding.
    jp: "技術の進歩により、私たちの科学者は繁殖せずにモンスターDNAを組み合わせることができました。",
    kr: "기술의 발전으로 과학자들은 번식 없이 괴물 DNA를 결합할 수 있었습니다.",
    ru: "Достижения в области технологий позволили нашим ученым объединить ДНК монстров без разведения.",
    ch: "技术的进步使我们的科学家能够在不进行繁殖的情况下结合怪物 DNA。",
    sp: "Los avances en la tecnología han permitido a nuestros científicos combinar ADN etherian sin criar."
};

export var see_form: langs = {
    en: "SEE FORMULAS",
    jp: "式",
    kr: "방식",
    ru: "ФОРМУЛЫ",
    ch: "公式",
    sp: "VER FÓRMULAS"
};

export var sc_t: langs = {
    en: "Smart Contract",
    jp: "Smart Contract",
    kr: "Smart Contract",
    ru: "Smart Contract",
    ch: "Smart Contract",
    sp: "Smart Contract"
};

export var faqq: langs = {
    en: "FAQ",
    jp: "FAQ",
    kr: "FAQ",
    ru: "FAQ",
    ch: "FAQ",
    sp: "FAQ"
};

export var woe_org: langs = {
    en: "World of Ether Origin",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

export var get_char: langs = {
    en: "GET FLIGHT CHARTER",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

export var stay_dat: langs = {
    en: "Stay up to date on the second voyage to the World of Ether",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

export var enlist: langs = {
    en: "ENLIST",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

export var guidance: langs = {
    en: "GUIDANCE",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

export var ult_med: langs = {
    en: "Ultimate Medium Article",
    jp: "ガイド",
    kr: "가이드",
    ru: "Руководство",
    ch: "指导",
    sp: "Artículo"
};

export var how_buy: langs = {
    en: "How to Buy NFTs",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

export var shop_1: langs = {
    en: "Shop",
    jp: "店",
    kr: "가게",
    ru: "Магазин",
    ch: "店铺",
    sp: "Tienda"
};

export var press_1: langs = {
    en: "Press",
    jp: "押す",
    kr: "누르다",
    ru: "Нажмите",
    ch: "按",
    sp: "Prensa"
};

export var hm_main_1: langs = {
    en: "Beat the odds and discover the legendary etherians.",
    jp: "伝説のモンスターを発見して勝利しましょう。",
    kr: "전설적인 몬스터를 발견하여 승리하십시오.",
    ru: "Побеждайте, открывая легендарных монстров.",
    ch: "通过发现传说中的怪物来获胜。",
    sp: "Vence las probabilidades y encuentra los monstruos legendarios"
};

export var hm_main_2: langs = {
    en: "Our researchers have determined the rarities of each of the species and categorized them accordingly.",
    jp: "モンスターの各種は、希少性によって分類されています。",
    kr: "몬스터의 각 종은 희귀도에 따라 분류되었습니다.",
    ru: "Каждый вид монстров классифицирован по редкости.",
    ch: "每种怪物都按稀有程度进行了分类。",
    sp: "Nuestros investigadores han determinado las rarezas de cada una de las especies y las han categorizado en consecuencia."
};

export var hm_main_3: langs = {
    en: "Know the probability as you capture, collect and combine monsters of the World of Ether!",
    jp: "モンスターを集めて組み合わせるときに確率を学びます。",
    kr: "포획하여 몬스터를 수집하고 결합하면서 확률을 배웁니다.",
    ru: "Узнавайте вероятности захвата, собирайте и объединяйте монстров.",
    ch: "在捕获收集和组合怪物时了解概率。",
    sp: "¡Conoce la probabilidad al capturar, recolectar y combinar monstruos del World of Ether!"
};
export var hm_main_4: langs = {
    en: "Note: Six Hundred Etherians are being withheld from the sale. These will be reserved for the families of the first trip to WOE. Also for marketing, giveaways, rewards—and for the creators' seats on the ship.",
    jp: "注：600個の卵が保持されています。これらは最初のゲームのプレイヤーのために予約されます。マーケティングや景品にも。",
    kr: "참고: 600개의 알이 보관 중입니다. 이것은 첫 번째 게임의 플레이어를 위해 예약됩니다. 또한 마케팅 및 경품을 위해.",
    ru: "Примечание: хранится 600 яиц. Они будут зарезервированы для игроков из первой игры. Также для маркетинга и бесплатных подарков.",
    ch: "注：600 鸡蛋被举行。这些将保留给第一场比赛的球员。也用于营销和赠品。",
    sp: "Nota: 600 monstruos se retiene de la venta. Estos estarán reservados para las familias del primer viaje a WOE. También para marketing, obsequios, recompensas y para los asientos de los creadores en la nave."
};
export var email: langs = {
    en: " EMAIL ADDRESS",
    jp: "電子メールアドレス",
    kr: "이메일 주소",
    ru: "адрес электронной почты",
    ch: "电子邮件地址",
    sp: "dirección de correo electrónico"
};
export var common: langs = {
    en: "Wild Etherians",
    jp: "一般的なモンスター",
    kr: "일반 몬스터",
    ru: "Обычные монстры",
    ch: "常见怪物",
    sp: "Monstruos comunes"
};
export var rare: langs = {
    en: "Rare Etherians",
    jp: "レアモンスター",
    kr: "희귀 몬스터",
    ru: "Редкие монстры",
    ch: "稀有怪物",
    sp: "Monstruos raros"
};
export var uncommon: langs = {
    en: "Hidden Etherians",
    jp: "珍しいモンスター",
    kr: "희귀 몬스터",
    ru: "Необычные монстры",
    ch: "不常见的怪物",
    sp: "Monstruos escondidos"
};
export var legendary: langs = {
    en: "Legendary Etherians",
    jp: "伝説のモンスター",
    kr: "전설적인 몬스터",
    ru: "Легендарные монстры",
    ch: "传奇怪物",
    sp: "Monstruos legendarios"
};
export var golden: langs = {
    en: "Golden Etherians",
    jp: "ゴールドモンスター",
    kr: "골드 몬스터",
    ru: "Золотые монстры",
    ch: "黄金怪物",
    sp: "Monstruos de oro"
};
/*

export var _: langs = {
    en: "",
    jp: "",
    kr: "",
    ru: "",
    ch: "",
    sp: ""
};

https://translatr.varunmalhotra.xyz/


*/
