import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Helmet } from "react-helmet";
import * as lang from '../../lang';

export default class WoeDexPage extends React.Component {
    constructor(props) {
        super(props);
        let f = props.routeProps.location.pathname;
        let startingState = this.whichOne(0);

        if (f.length > 7) {
            let d = this.parseNum(f)
            let z = parseInt(d);
            if (z) {
                if (z < 203 && z !== 0) {
                    startingState = this.whichOne(z);
                }
            }
        } else {
            this.props.routeProps.history.push(`/woedex/0`)
        }
        this.state = {
            ...startingState
        }
    }

    componentDidMount() {
        $('.slide-controler').animate({
            scrollTop: (41.736 * this.state.num)
        }, 500);
    }

    parseNum(str) {
        return str.replace(/[^.\d]/g, '');
    }

    changeEtherian = (e, x) => {
        this.setState({ ...this.whichOne(x) })
        this.props.routeProps.history.push(`/woedex/${x}`)
    }

    whichOne(_num) {
        let name = "";
        let description = "";
        let num = parseInt(_num);
        let color;
        let rarity;
        let d;
        let padded = String(_num).padStart(3, '0');
        // let image = `https://woe2.s3.amazonaws.com/monsters/${padded}/full.png`;
        // let image2 = `https://woe2.s3.amazonaws.com/monsters/${padded}g/full.png`;
        let image = `https://woe2.s3.amazonaws.com/newmonsters/${padded}.png`;
        let image2 = `https://woe2.s3.amazonaws.com/newmonsters/${padded}g.png`;
        if (num === 0 || num === 21 || num === 193 || num === 188 || num === 184 || num === 177 || num === 171 || num === 161 || num === 26 || num === 126 || num === 81 || num === 41 || num === 43 || num === 57 || num === 61 || num === 72 || num === 79) {
            image2 = image;
        }
        let typez;
        if (num === 0) {
            color = "astral-bg";
            typez = "Astral type";
        } else if (num <= 40) {
            color = "sun-bg";
            typez = "sun type";
        } else if (num <= 80) {
            color = "ocean-bg";
            typez = "ocean type";
        } else if (num <= 120) {
            color = "life-bg";
            typez = "life type";
        } else if (num <= 160) {
            color = "death-bg";
            typez = "death type";
        } else if (num <= 200) {
            color = "astral-bg";
            typez = "Astral type";
        } else if (num === 201) {
            color = "sun-bg";
            typez = "sun type";
        } else if (num === 202) {
            color = "death-bg";
            typez = "death type";
        } else {
            return;
        }

        let tempNum = 0;

        if (num >= 40) {
            tempNum = num % 40;
        } else {
            tempNum = num;
        }

        if (tempNum === 0) {
            rarity = "Legendary";
        } else if (tempNum >= 33) {
            rarity = "Rare";
        } else if (tempNum >= 22) {
            rarity = "Hidden";
        } else {
            rarity = "Wild";
        }

        switch (num) {
            case 0:
                d = "Discovered By Alain The Creator"
                name = 'Genesis';
                description = 'While exploring the unknown reaches of the universe we encountered this being. Living on an uninhabitable planet alone, this creature was thought to be the creator of all life or at the very least the creator of something ...different. It possessed the ability to create eggs without mating, with its offspring having DNA very unique from one another, but none like itself. After relentless testing and experimentation, this creature was rendered infertile. Since then we have set on a journey to find more.';
                break;
            case 1:
                d = "Discovered By rawr"
                name = 'Chary';
                description = 'Chary\'s flames appear hotter than they are in order to intimidate predators, and when trust is built, it will extinguish itself to show affection. Under the flames, it has a soft, lightly fur covered gray body. Occasionally this species\' flames burn blue.';
                break;
            case 2:
                // d = "Discovered By rawr"
                d = 'unknown';
                name = 'Lizen';
                description = 'This etherian is great to have as a pet and enjoys playing in the sun. The species has a keen sense of its surroundings. It will warn its owner of incoming threats which are hard to otherwise detect. It is very dog-like in its nature.';
                break;
            case 3:
                d = "Discovered By phil"
                name = 'Bitor';
                description = 'A parasitic insect, this species absorbs the heat from its surrounding environment. A single colony of these can turn a spaceship into an uninhabitable cold shell. They have adapted to a multitude of habitats and have been rumored to infest the whole planet.';
                break;
            case 4:
                d = "Discovered By monster merchant"
                name = 'Pixie';
                description = 'This creature has an average height of two feet. Pixie are mischievous, stealing whatever they can from settlements. Often enamored by shiny objects, you\'ll sometimes see them melting screwdrivers or other shiny metal tools.';
                break;
            case 5:
                name = 'Croakie';
                d = "Discovered By bowie999"
                description = 'Traveling with these etherians has been outlawed due to their destructive nature. A screech from this species can shatter eardrums and collapse rib cages. It can also damage space suits as well.';
                break;
            case 6:
                d = "Discovered By monster merchant"
                name = 'Pinnoria';
                description = 'Not all pinnoria have stingers, but the ones that do are known as soldiers. Stings from pinnoria soldiers have a 100% mortality rate. Rather than lethal venom, soldier pinnoria inject 200 degree Celsius bile into their victim\'s bloodstream. ';
                break;
            case 7:
                name = 'Furriel';
                d = "Discovered By monster merchant"
                description = 'This creature\'s metabolism works similar to a combustion engine. It devours large amounts of fat and oil to sustain the flames within its body. Because of this, it has an insatiable love of rocket fuel or gasoline. Keep them away from your spaceship\'s fuel reserves at all costs. ';
                break;
            case 8:
                name = 'Deway';
                d = "Discovered By excalibur"
                description = 'A harmless but very annoying etherian, this creature is characterized by its small hands and red dorsal side spikes. It contains a vocal box that is only capable of producing clicks. It makes this sound when it identifies others who do not belong in its habitat. Soon a swarm of this etherian surrounds the trespasser, making a clicking chorus. The females of this species are roughly twice the size of males. They will usually show you de way out of their habitat.';
                break;
            case 9:
                d = "Discovered By rawr"
                name = 'Solquent';
                description = 'This etherian is capable of flying at extremely high speeds. However, it can only soar through the air in warm climates. During the cold or heavy rain, this species is stuck on the ground. Left earthbound it slithers like a snake making it easy to capture or eat.';
                break;
            case 10:
                d = "Discovered By monster merchant"
                name = 'Cindercat';
                description = 'A Cindercat\'s body is comprised of molten lava. In self-defense, this creature can lower its body temperature, solidifying into stone. If it remains hardened too long, it will be unable to warm itself, relying then on other Cindercats to heat it up.';
                break;
            case 11:
                d = "Discovered By rawr"
                name = 'Matchstick';
                description = 'This etherian is characterized by red fur appearing in patterns across its body, and boasts several striking surfaces, similar to the back of a matchbook. Though its omnivorous it mostly survives on a diet of fruits and berries. It\'s a friendly species that brings edible fruits to nearby creatures in need.';
                break;
            case 12:
                d = "Discovered By bowie999"
                name = 'Parradone';
                description = 'This happy creature waddles around on thin legs without much care for its surroundings, bumping into people, rocks, or even legs of predators. It eats indiscriminately, usually getting fatter as it gets older. There is a 1:1 ratio between its age and its weight. Parradone are extremely easy to hunt but have not gone extinct due to their high breeding rate.';
                break;
            case 13:
                // d = "Discovered By onedayplay"
                d = 'unknown';
                name = 'Radacopter';
                description = 'Radacopter hovers in the trees of dense forest regions, using their tails to remain suspended and safe from danger. Staying in the air proves easy for these etherians as their energy is derived from the sun.';
                break;
            case 14:
                name = 'Zenon';
                d = 'unknown';
                d = "Discovered By monster yeoldmama"
                description = 'This etherian loses its vision with age, but gains a pronounced mastery of its other senses. Its keen sense of smell helps it forage for food and the heat that engulfs its teeth allow it to cut through the thickest of vegetation.';
                break;
            case 15:
                d = "Discovered By rawr"
                name = 'Salahan';
                description = 'These creatures impress mates by building structures with rocks. The cleaner and more symmetrical these structures, the better, and rare valuables or crystals in the interior greatly assist in impressing potential mates. ';
                break;
            case 16:
                // d = "Discovered By monster merchant"
                d = 'unknown';
                name = 'Spinsnak';
                description = 'Spinsak venom wields a toxin that hardens the cells in its victims\' blood. If left untreated the infected area will turn to stone. A bite of a marrberry, though very bitter, can be used as an antivenom. ';
                break;
            case 17:
                d = "Discovered By phil"
                name = 'Flameep';
                description = 'A harmless but very annoying etherian, this creature is characterized by its small hands and red dorsal side spikes. It contains a vocal box that is only capable of producing clicks. It makes this sound when it identifies others who do not belong in its habitat. Soon a swarm of these etherians surround the trespasser, making a clicking chorus. The females of this species are roughly twice the size of males. They will usually show you de way out of their habitat.';
                break;
            case 18:
                d = "Discovered By rawr"
                name = 'Hermes';
                description = 'Characterized by hardened exteriors, these small creatures reside in volcanic rock or ash. They stand on their hind legs, using their tails for balance, as they survey the land.';
                break;
            case 19:
                // d = "Discovered By rawr"
                d = 'unknown';
                name = 'Owlire';
                description = 'This nocturnal land dweller sports a silky pearlescent fur with a protective heat coating. Its head can turn a full 360 degrees, and its eyes are able to see in the utmost dark though it cannot see color.';
                break;
            case 20:
                d = "Discovered By rawr"
                name = 'Dracolire';
                description = 'Dracolire spends most of its time lounging on molten rocks, sunbathing, and relaxing. It spends very little time hunting. When it does, it dislocates it large, flame filled maw and swallows its prey whole.';
                break;
            case 21:
                d = "Discovered By bowie999"
                name = 'Estufara';
                description = 'Estufara hunt for sport. If you find yourself being hunted by this etherian, play dead as they get bored easily. If you are able-bodied and fleeing, you become fair game.';
                break;
            case 22:
                d = "Discovered By buy-bitcoin"
                name = 'Pyrefox';
                description = 'Pyrefox are some of the most aggressive etherians and have been known to kill one another. They hunt and live alone. The only time two of these species are ever together is when they\'re in heat.';
                break;
            case 23:
                d = "Discovered By monster merchant"
                name = 'Maraptor';
                description = 'This etherian feeds primarily on plants and insects, and gives off a comfortable warmth. It can be tamed and ridden and is capable of moving very long distances without much need for food or water.';
                break;
            case 24:
                d = "Discovered By iliketurtles"
                name = 'Fyrecrun';
                description = 'These bug-like etherians reside burrowed in the crust of the planet. Fyrecrun only need water once every 3 years. This allows them to permanently have a home in the crust. They predate all other species on the planet. Their shells are also excellent at reflecting UV rays. Emulsified versions of the shells are used in specialty space suits.';
                break;
            case 25:
                d = "Discovered By monster merchant"
                d = 'unknown';
                name = 'Malflame';
                description = 'Appearing in packs, this etherian uses strategy to capture its prey. Each pack implores different tactics and has different success rates. Territorial wars between packs can occur and severely trim the population. Operating altruistically for other Malflame in the pack, these creatures organize so that weaker members eat just as much as star hunters.';
                break;
            case 26:
                name = 'Crait';
                d = "Discovered By monster merchant"
                description = 'The white exterior of this creature is comprised mainly of salts and is hard like a rock to dissuade predators. The interior of the creature is red and ruby like, following a very jagged and symmetrical pattern. Etherians of the species will band together, forming impenetrable spheres used to traverse harsh environments and climates. ';
                break;
            case 27:
                d = "Discovered By phil"
                name = 'Mcram';
                description = 'This etherian feeds mostly on grass and berries. The males produce two and sometimes three horns on their heads. There are only a handful of etherians which can communicate with us, and in first contact, this species believed it was of utmost importance that we know Mcrams are the tastiest creatures on the planet.';
                break;
            case 28:
                d = "Discovered By frozen"
                name = 'Corgei';
                description = 'This stubby etherian stands at four feet tall and three feet wide on average. Their powerful jaws and arms make them formidable. Corgei have several rows of spikes under their fur. A hug from this etherian will kill you in two ways; one from the force, the other from stabbing.';
                break;
            case 29:
                d = "Discovered By monster merchant"
                name = 'Knifator';
                description = 'An ancestral etherian, this creature is characterized by a sharp blade on its posterior side. This blade starts growing at about 2 weeks of age. In self-defense, it will whip around its body to maim a predator. A sliced throat will meet any attacker who approaches its blind side. ';
                break;
            case 30:
                d = "Discovered By onedayplay"
                name = 'Poundro';
                description = 'This etherian has two circulatory systems. One for the distribution of blood, the other for lava. Healthy Poundros will glow red, empowered by lava. The veins of unhealthy Poundros will run grey. Poundro can be found around volcanoes and is very territorial.';
                break;
            case 31:
                name = 'Griffendal';
                d = "Discovered By onedayplay"
                description = 'This etherian produces extremely beautiful songs and wanderers find themselves drawn in, seduced into a hypnotic state. The wing patterns on each Griffendal are just as hypnotic as their songs. A meat eating species, these characteristics evolved to trap prey. Under no circumstances should you follow this etherian to its nest, as you will be the next meal for its family.';
                break;
            case 32:
                name = 'Obsidian';
                d = "Discovered By monster merchant"
                description = 'When this etherian reaches sexual maturity it produces a small glass like container in the jug of its neck. This container heats a concrete-like compound to 900 Celsius making it molten. It gains its obsidian-like skin by excreting this material from its pores onto prey which it feeds to its offspring.';
                break;
            case 33:
                d = "Discovered By chang"
                name = 'Cerebros';
                description = 'Tectonic shifts have produced a line of cracks across the planet, feeding three volcanoes on the surface. Out of these volcanoes, only one remains active, with its last eruption estimated 10,000 years ago. This volcano produces a river of fiery lava. That river is the homeplace of Cerebros. Not much else is known about this etherian as it simply wanders back and forth before the volcano, waiting, or possibly guarding something.';
                break;
            case 34:
                name = 'Snared';
                d = "Discovered By kiuerty"
                description = 'This etherian remains in a state of perpetual flight, surveying the ground and taking note of the land dwellers\' affairs. It is thought to be very intelligent and even has a form of morality. On one occasion a Snared has been recorded to have killed a particular individual who was premeditating a crime.';
                break;
            case 35:
                d = "Discovered By chang"
                name = 'Devilora';
                description = 'This etherian on average grows anywhere from six to seven feet tall. Making its habitat deep in the forest, this omnivore will hunt anything it sees. Fortunately for other creatures, Devilora is very rare, and due to an infestation of Bitors, their numbers have been dwindling for years. They are almost extinct. ';
                break;
            case 36:
                d = "Discovered By monoico"
                name = 'Spikernal';
                description = 'Perched atop the highest mountains, this predator uses its many eyes to survey its prey. Tracking multiple creatures at once, Spikernal scans miles into the distance with pinpoint accuracy.';
                break;
            case 37:
                d = "Discovered By chang"
                name = 'Tuem';
                description = 'A powerful Sun etherian. Tuem stores excess energy in the form or gem like orbs. At any given moment 3-8 orbs can be seen hovering around a member of this species. These orbs get charged by the sun, and because of this, Tuem tend to hunt only in the daytime.';
                break;
            case 38:
                d = "Discovered By cynwilliams"
                name = 'Bloodfang';
                description = 'After a millennium of hunting in the desert, this etherian has evolved the ability to retain water. So much that it can go months without a single drop. It mainly feeds on creatures that reside under the desert floor namely Bitors and Fyrecrun.';
                break;
            case 39:
                d = "Discovered By chang"
                d = 'unknown';
                name = '火の龍 (Hi no ryū)';
                description = 'このイセリアンは、本当に珍しく、生きている姿は一度も見られたことがありません。これまでにこのクリーチャーの死体が海岸まで流された記録が2例があります。 当初、死体は海に住むクリーチャーのものだと考えられていました。しかし、鰓がないことと、足が五本あることにより、議論されています。したがって、 このイセリアンは、海の上を飛び、水中の魚を食べているという仮説が上がっています。';
                break;
            case 40:
                d = "unknown"
                name = 'Ashbringer';
                description = 'Laying on a bed of destroyed starships and vanquished foes, this etherian sleeps deep inside the only active volcano on the planet. Ashbringer is immortal and cannot breed. It only serves one purpose, destruction. Faced with planetary threats, only a Cerebros can awaken this monstrosity from its slumber. When it is awoken, it leaves onhly ash in its wake.';
                break;
            case 41:
                d = "Discovered By onedayplay"
                name = 'Doefan';
                description = 'After spending a millennia selectively breeding Doefan, Kragvern created one of the cutest etherian species. Originally these creatures were pack hunters, equipped with deadly horns and large tails for efficiency. These traits have been bred out, and their personalities made more friendly.';
                break;
            case 42:
                d = "Discovered By YOOSHIMITZU"
                d = 'unknown';
                name = 'Bleab';
                description = 'Bleabs are cute pond dwelling etherians which sleep most of the day. They can usually be found floating together in small groups. As they rest, they have the appearance of unbloomed flowers. They are particularly active during light rainstorms during which they will raise their ears, blossom, and hop around the water and into puddles that form nearby.';
                break;
            case 43:
                d = "Discovered By onedayplay"
                name = 'Bobuggar';
                description = 'Bobuggar have no eyes and identify their surroundings using echolocation. They\'re able to easily detect us in water, but fortunately they are kind and affectionate. They tend to come to the aid of drowning creatures and can survive both on land and in water.';
                break;
            case 44:
                d = "Discovered By WAZUPDUDE"
                d = 'unknown';
                name = 'Seajell';
                description = 'Seajells are peaceful species that survive solely on plankton. Their blood serves as an antibacterial similar to penicillin, but also works as an anti-inflammatory. Other creatures have been observed nibbling on these etherians and using their juices as ointments to prevent sickness.';
                break;
            case 45:
                d = "Discovered By MONSTER MERCHANT"
                name = 'CoralCandy';
                description = 'Characterized by its bright colors, CoralCandy can be either hot pink or orange. This etherian is renowned for its seemingly grumpy face. In actuality, it is light-hearted and happy and has no control over its facial expressions. Its body is comprised mostly of air, allowing it to shrink and fit into impossibly small spaces.';
                break;
            case 46:
                d = "Discovered By MILLHOUSE"
                name = 'Floam';
                description = 'This etherian should be avoided at all costs. The spikes of these creatures produce an oil-like substance which is so foul and odorous it can harden the receptors of the nose and eyes, barely categorizing the substance as a neurotoxin. Floams are fairly common around saltwater shores, making bodies of water dangerous to traverse without headgear.';
                break;
            case 47:
                d = "Discovered By ONEDAYPLAY"
                name = 'Piranum';
                description = 'These large fish make their habitat in muddy rivers. When hunting other ocean etherians these fish use their strong fins to stir up dirt and silt, blinding prey. Piranum have enormous mouths and powerful jaws, and also hunt land-based creatures if they venture near enough to the shore.';
                break;
            case 48:
                d = "Discovered By ONEDAYPLAY"
                name = 'Popfish';
                description = 'Popfish hatch without their parents around and imprint on the first creature they see, no matter the etherian\'s size or species. Some etherians take a liking to young Popfish and will feed them. Other etherians however, find Popfish to be a nuisance and will eat them immediately.';
                break;
            case 49:
                d = "Discovered By ONEDAYPLAY"
                name = '监护人 (Jiān hù rén)';
                description = '以太有如岩石般的外观，它的身体并不透明，呈蓝绿色。它会拒绝接触土地，因为它不想伤害任何一块的树叶。这种生物会在云层中穿梭，又会在水中移动。这个生物会决定一个人的道德，消灭任何它认为邪恶的人。';
                break;
            case 50:
                d = "Discovered By ONEDAYPLAY"
                name = 'Mordreel';
                description = 'A species of scavengers, the Mordreel travels behind large ocean etherians in the hopes to catch scraps. They are incredibly feisty and aggressive creatures, often battling each other for the smallest pieces of meat. A weak Mordreel will more than liley be eaten by their own kind.';
                break;
            case 51:
                d = "Discovered By YOOSHIMITZU"
                name = 'Deepsting';
                description = 'This etherian tracks down thunderstorms with the intention of capturing the energy from lightning strikes. They will then expel this energy in a variety of ways from battles or even communication. They are friendly with most other etherians and will only fight in self-defense.';
                break;
            case 52:
                d = "Discovered By YOOSHIMITZU"
                name = 'Lobtra';
                description = 'Spending most of their time on seafloors and among CoralCandy, Lobtra are foragers, The small receptor on their heads is a means of instant communication between members of their species. This enables alpha males to send offensive and defensive commands to fellow ocean etherians. Lobtra are one of the etherians that are able to communicate with Deepsting and will call it into battle if need be.';
                break;
            case 53:
                d = "Discovered By ONEDAYPLAY"
                name = 'Muramasa';
                description = 'It is unclear how or when Muramasa came into existence, as their exoskeletons are made from a metal alloy only obtainable through cardinal refinement, a process sentient beings alone can carry out. Muramasa use their tails to swim at high speeds and pierce foes. Some etherians with hands can wield this creature as a weapon.';
                break;
            case 54:
                d = "Discovered By YOOSHIMITZU"
                name = 'Sharapanel';
                description = 'With its extendable hand-like upper fins, the Sharapanel grabs slow or weak etherians and impales them on its dorsal spikes. It will sometimes swim with a carcass impaled on its back to attract scavengers which other Sharapanel in the pack can pursue. It\'s often spotted around ocean trenches, where it grabs heavy boulders and lobs them down onto prey passing below.';
                break;
            case 55:
                d = "Discovered By MONSTER MERCHANT"
                name = 'Starvore';
                description = 'Living on the floor of freshwater oceans, Starvore are a peaceful species. They survive on the naturally occurring mold and mildew of the sea and slowly make their way into saltwater to breed. If a Starvore is pestered or provoked it can become aggressive, using its sharp teeth, meant for scraping mold off ocean floors, as an effective weapon.';
                break;
            case 56:
                d = "Discovered By MONSTER MERCHANT"
                name = 'Whagnevrone';
                description = 'Dwelling in underwater caverns at the bottom of deep forest lakes, Whagnevrone emerge to forage for healthy green trees. Their powerful vice-like jaws are able to down even the largest trees with ease, the leaves of which are used for food. Once a trunk is stripped bare, they will often drag it back to their sunken caves, where the logs are used to create maze-like labyrinths to keep predators from finding their young. Any predators that have the misfortune of getting lost in a Whagnevrone labyrinth are likely to starve to death.';
                break;
            case 57:
                d = "Discovered By MONSTER MERCHANT"
                name = 'Emberon';
                description = 'Found in polluted seas, Emberons gain energy from using complex bodily filtration systems on oils and land runoff. Oil ends up being purified and enriched as they pass through its body. Any pollutants they are able to absorb are stored as crystals on the top of their rigid exoskeleton. They are known to wreak havoc on deep-sea rigs and as a result, are captured en masse so their crystals can be harvested for use as space fuel.';
                break;
            case 58:
                d = "Discovered By YOOSHIMITZU"
                name = 'Aqualos';
                description = 'Aqualos is a largely passive etherian, able to survive off the energy orbs dropped from fallen or injured Tuem. Several charged Tuem orbs can sustain a colony of Aqualos for up to a year. With their amorphous nature, Aqualos are able to trade orbs amongst each other so that there\'s more in proximity to weak or injured members of the colony. They spend their days floating on the surface of warmer oceans, and near coasts where a Tuem can sometimes be found. Their bodies clean pollutants out of the ocean as they float over it.';
                break;
            case 59:
                d = "Discovered By MONSTER MERCHANT"
                name = 'Seafu';
                description = 'The Seafu had at one time been enmeshed in a brutal interspecies war lasting thousands of years. Their population was nearly wiped out by the time the war had ended. The surviving individuals vowed to remain peaceful and focus only on the prosperity of their own kind. In the modern day, they can be seen standing on the tops of sunken mountains, admiring the complexity of the ecosystem around them. They rarely move and rarely blink, as they never want to lose sight of the ocean\'s beauty as they had in the past.';
                break;
            case 60:
                d = "Discovered By MONSTER MERCHANT"
                name = 'Baquosos';
                description = 'Despite it being one of the smallest ocean etherians, Baquosos are not to be taken lightly. Where there is one, there are surely hundreds more. These vicious carnivores travel in packs and are capable of taking down much larger etherians. Their pectoral fins are in fact small arms that enable them to grab and tear at the skin of their prey.';
                break;
            case 61:
                d = "Discovered By MONSTER MERCHANT"
                name = 'Amoeblu';
                description = 'Amoeblu are a very large, very slow moving sea slug. They are often targeted by carnivorous ocean etherians. These etherians defend themselves with an extremely thick slime covering their body. This slime has a potent neuroinhibitor that temporarily reduces the speed and reflexes of whoever encounters it.';
                break;
            case 62:
                d = "Discovered By CHANG"
                d = 'unknown';
                name = 'Octograw';
                description = 'An extremely intelligent and extremely dangerous species, the Octograw is both a physical and psychological threat to any living being near it. Using the amorphous membrane on its face, it can form a vocal box to mimic any etherian, usually for the purpose of luring weak etherians into its incredibly strong tentacles. The Octograw is a sadistic creature in that it will mimic distress calls of the various colony based etherians for days and days, creating a frantic search for a creature that isn\'t there.';
                break;
            case 63:
                d = "Discovered By WAZUPDUDE"
                name = 'Goldfee';
                description = 'For its miniature size, this etherian is very powerful and defends itself by creating large vibrations in the surrounding water. If this etherian is captured you must always keep it in a jar. It doesn\'t much like captivity.';
                break;
            case 64:
                d = "Discovered By CHANG"
                name = 'Whaleed';
                description = 'Whaleed are lonely, gentle, and very large. Due to their tough hides, they\'ve become a favorite target of poachers, who will use their outer shells for spaceship insulation. Most Whaleed now inhabits the lower depths of the seas, where seeds from deep-sea forests will sprout on their backs. Occasionally, Whagnevrone can be found eating the leaves on a Whaleed\'s trees. Whaleed that make it to the surface have been confused for islands.';
                break;
            case 65:
                d = "Discovered By CHANG"
                name = 'Seen';
                description = 'Characterized by a single large eye, this etherian has unparalleled vision. It can see indefinitely in any direction, be used to detect gas leaks, detect other planes of existence, and even feelings. This Ocean etherian has no mouth and does not consume food. Instead, it feeds off the sun. Usually, in the ocean, this etherian surrounds itself with seaweed, but if it is on land it finds itself curiously attracted to clothing.';
                break;
            case 66:
                d = "Discovered By CHANG"
                name = 'Kragvern';
                description = 'Despite its tall menacing appearance, the Kragvern is a friend to most ocean creatures. It uses its large claws to plow the sea floor and create acres of underwater farmland to feed itself and smaller etherians. Kragvern are known to keep small fish and etherians as pets to entertain them during long hours of working their farms.';
                break;
            case 67:
                d = "Discovered By WAZUPDUDE"
                name = 'Unifish';
                description = 'Both male and female Unifish have a virtually indestructible horn used for carving out caves in glaciers and frozen continents. In these caves, they make their homes. Occasionally a Unifish horn will be curved backwards, indicating it has a high level of combat skill. Unifish with backwards curved horns are not to be taken lightly.';
                break;
            case 68:
                d = "Discovered By ONEDAYPLAY"
                name = 'Semount';
                description = 'Semounts are capable of running along the surface of the ocean thanks to the buoyancy of the cushioning on their feet. Offshore, they\'ll gallop in herds as they hunt for near surface fish to bring back to their offspring being raised in cliff dens. The fins on their head are used for precision diving when prey is spotted.';
                break;
            case 69:
                d = "Discovered By ONEDAYPLAY"
                d = 'unknown';
                name = 'Twindee';
                description = 'With two heads this etherian is an apex predator. This species is so voracious it will hunt its prey to extinction if given the chance. It has evolved the ability to stop its own heart to remain completely silent, allowing it to get an additional edge on its unsuspecting prey.';
                break;
            case 70:
                d = "Discovered By ONEDAYPLAY"
                name = 'Puffist';
                description = 'Born without a brain, the Puffist is only capable of basic motor functions. Puffists are meant for symbiotic relationships with host fishes which attach to the head and assume control. Many fish can assume the role of host with a more intelligent fish turning the Puffist into a more capable being.';
                break;
            case 71:
                d = "Discovered By ONEDAYPLAY"
                name = 'Gragle';
                description = 'The Gragle survives through means of parasitic infection, using the spikes on its back to inject eggs into a host unlucky enough to come into contact with it. The hatchlings then eat the body of the host from the inside out. Graggle are most often found under a thin layer of mud in swampland as etherians that have evolved there provide ideal host bodies to Gragle eggs.';
                break;
            case 72:
                d = "Discovered By YOOSHIMITZU"
                name = 'Cryozal';
                description = 'The Cryozal is a passive etherian roaming surfaces of frozen lakes. They travel in small herds in search of the purest glacial meltwater they can find, which is then stored in crystals that form on their backs. They can withstand immense cold and use this meltwater as a source of fuel during hibernation. ';
                break;
            case 73:
                d = "Discovered By YOOSHIMITZU"
                name = 'Donofrio';
                description = 'This etherian is characterized by its icy exoskeleton. On a monthly basis, it crafts different vessels for itself usually for the purpose of completing particular tasks. Once the vessels are used they\'re not discarded, instead, they\'re kept in a skeleton graveyard. Other members of these species will come across its collection and judge the individual Donofrio based on its craftsmanship. If a large enough threat is brought to the species, they will band together and make a single legendary exoskeleton for the purposes of warfare.';
                break;
            case 74:
                d = "Discovered By WAZUPDUDE"
                name = 'Scorphand';
                description = 'Once a deep sea etherian, Scorphands only left the ocean to lay eggs on icebergs. These icebergs were dominated by Illustrion, leading to violent battles. Under evolutionary pressure, Scorphand slowly evolved the ability to fly. Along with a stinger which can inject a powerful paralyzing venom, the Scorphand is now one of the most versatile and dangerous Ocean etherians.';
                break;
            case 75:
                d = "Discovered By CHANG"
                name = 'Illustrion';
                description = 'A bitter rival, this species will spare no expense to destroy their mortal enemy, the Scorphand. Often atop icy mountains, these species can be seen nosediving and grappling each other in a fight to the death. The Illustrion\'s aim is to attach to a Scorphand\'s back. When mounted it will tear Scorphand\'s wings from its frame and let it fall in the mountains below, looking on gleefully.';
                break;
            case 76:
                d = "Unknown"
                name = 'Fridgorn';
                description = 'Fridgorn can be found roaming ice caps and crushing boulders. Their population is spread out due to scarce resources and harsh cold climates. When in their natural habitat, these creatures mate for life, usually only producing two offspring, making them a rare species. Fridgorn are very powerful and very aggressive, and it\'s warned that explorers stay away from these beasts.';
                break;
            case 77:
                d = "Discovered By CHANG"
                name = 'Tyrafin';
                description = 'The Tyrafin is a gargantuan etherian with an appetite even larger than its size. In order to satiate its hunger, it stands with its massive mouth open at the bottom of waterfalls and at the mouth of rivers allowing fish and other wildlife to drift inside. Thousands of gallons of water are passed through its gills, known as filter-feeders, while every creature that can be digested as food is directed into its cavernous stomach.';
                break;
            case 78:
                d = "Discovered By STOTO"
                name = 'Banshee';
                description = 'Banshees produce a powerful screech, providing them cover from most etherians. Their large skin flaps are connected to strong muscles enabling them to cross oceans in short amounts of time. As a result, Banshees do not have a preferred area to breed or hunt in - they can be anywhere at virtually any time. In battle, their near indestructible wings can easily take down most opponents.';
                break;
            case 79:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Maxilla';
                description = 'The impressive maw of Maxilla introduces its prey to a conundrum. Once its trap like mouth snaps shut, it\'s near impossible to escape. Subsequent harder pulls cause parts of the maw to unravel, spiraling and burrowing deep into its prey\'s flesh. The question its prey has to ask itself is, "Do I push deeper into the mouth of this savage creature, attempting to break free, or do I simply gnaw off my own limb?"';
                break;
            case 80:
                d = "Discovered By STOTO"
                d = 'unknown';
                name = 'Neleus';
                description = 'Believed to have existed for thousands of millennia, the Neleus species are the architects of the ocean carving out vast canyons and trenches that would become deep oceans and lakes. Their numbers have dwindled dramatically, as these gladiatorial etherians would do battle against one another in their newly created oceans. The victors of these duels would wield a Muramasa placed into stasis as their prized weapons. These endless duels have dwindled the number of living Neleus to just a small handful.';
                break;
            case 81:
                d = "Discovered By WAZUPDUDE"
                name = 'Baambe';
                description = 'Baambes have seethrough bulbs on their back comprised of a series of thin vines.  Deep within the web of vines and thorns, a flower-shaped heart lies beneath. The older this etherian gets the more its heart wilts. There are noticeable changes to the heart during times of stress.';
                break;
            case 82:
                d = "Discovered By YOOSHIMITZU"
                name = 'Viperon';
                description = 'These sneaky etherians lay camouflaged in mushroom patches and flower beds. They feed on insects and very small etherians. Please be careful; Viperon\'s deadliest defense is its paralyzing venom concealed within the fake petals around its neck.';
                break;
            case 83:
                d = "Discovered By YOOSHIMITZU"
                d = 'unknown';
                name = 'Lawnmauler';
                description = 'During the last millennia, this etherian has developed bark-like skin and grass on its back. The evolution of this omnivorous etherian is thought to be advantageous when hunting its prey. Though there is a remarkable resemblance between Toro and Lawnmauler; they do not share any recent common ancestors.';
                break;
            case 84:
                d = "Discovered By YOOSHIMITZU"
                name = 'Deecamp';
                description = 'This adorable etherian is often unaware of its surroundings. Deecamps only defense is that it can put anything in its vicinity to sleep. When it isn\'t sleeping it feeds on flies and other small insects.';
                break;
            case 85:
                d = "Discovered By YOOSHIMITZU"
                name = 'Rosie';
                description = 'On first sight, this beautiful etherian appears to be a flower. Rosie is two separate creatures in a symbiotic relationship. Receiving energy from the sun and nourishment from water; they have no reason to move. Whenever separated these creatures become erratic and violent.';
                break;
            case 86:
                d = "Discovered By YOOSHIMITZU"
                name = 'Breegle';
                description = 'Breegle is an amphibious creature that likes floating in the swamps. They move slow both in water and land and feed primarily on plankton and kelp.';
                break;
            case 87:
                d = "Discovered By YOOSHIMITZU"
                name = 'Droggo';
                description = 'Droggo hunt in packs using high pitch frequencies to communicate with each other. These high pitched messages are rich in information letting others know the exact location of their prey.  Droggo have incredible stamina, chasing its prey until their inevitable collapse of exhaustion.';
                break;
            case 88:
                d = "Discovered By YOOSHIMITZU"
                name = 'Cauliagon';
                description = 'Cauliagon can be seen gliding from tree to tree across all habitats on the planet. These etherians can glide but do not possess the ability to fly. The trees are filled with beautiful mating calls when they are present.';
                break;
            case 89:
                d = "Discovered By YOOSHIMITZU"
                name = 'Catcus';
                description = 'Catcus make their habitat in dry climates like the outskirts of deserts. They are affectionate etherians yearning to embrace strangers. They seem to be oblivious to the fact that they are covered in painful spikes. ';
                break;
            case 90:
                d = "Discovered By CHANG"
                name = 'Sirshone';
                description = 'Sirshones are sneaky etherians with a fascination with shiny objects and materials. If you encounter a Sirshone, its nest is usually nearby. This nest will be laden with golds, diamonds and the finest of jewelry. However, in heavily polluted areas only bolts and plastics can be found.';
                break;
            case 91:
                d = "Discovered By YOOSHIMITZU"
                name = 'Zeeburus';
                description = 'A genetic mutation causes this etherian to be born with a variety of heads. Zeeburus are born with as many as 2 to 5 heads. Be wary of feeding Zeeburus as they become very dependent, following you everywhere you go.';
                break;
            case 92:
                d = "Discovered By YOOSHIMITZU"
                name = 'Cuteberry';
                description = 'Cuteberries use their appearance to attract flies and smaller insects. Unfortunately, hanging from trees, they are often mistaken for real fruit. They have a very bitter taste but can contain medicinal properties. ';
                break;
            case 93:
                d = "Discovered By CHANG"
                name = 'Krythe';
                description = 'Often kept as pets, Krythe make excellent groundskeepers, handling all gardening tasks. They need to have the sky above them at all times to survive. Though Krythe can be domesticated nothing can undo its instincts. It cannot refrain from eating  Cuteberries, another domesticated etherian. A forgetful owner will find themselves with a well-fed Krythe and a dead Cuteberry.';
                break;
            case 94:
                d = "Discovered By YOOSHIMITZU"
                name = 'Flauron';
                description = 'Flaurons gain energy from the sun storing them in specialized cells. Once filled to the brim it can survive off its surplus power. It can also use this power to expel energy through the gaze of its lone eye. The severity of the beam can be anywhere from blinding to disintegrating.';
                break;
            case 95:
                d = "Discovered By YOOSHIMITZU"
                name = 'Rindosan';
                description = 'Rindosans can change the colors of its scales to blend in with its surroundings. Their scales can even be manipulated to resemble other creatures. Its best defense is to curl into an impenetrable ball. The scales on its back are as hard as steel.';
                break;
            case 96:
                d = "Discovered By YOOSHIMITZU"
                name = 'Spade';
                description = 'Spades can possess an IQ that rival or even surpass that of a human. This etherian can learn any sentient language and master all mathematics. However, it cannot speak or write due to its physical limitations. The higher their IQ, the more aware they are of this shortcoming; which leaves most Spades angry. ';
                break;
            case 97:
                d = "Discovered By YOOSHIMITZU"
                name = 'Scolosect';
                description = 'This sponge-like etherian is born without eyes and navigates the planet using its keen sense of smell. Scolosects spend their entire lives absorbing poisons and toxins, making its environment safe for other etherians. When they die, other Scolosects will eat their corpse. This cannibalistic ritual helps them determine the cause of death and allows them to adapt accordingly. Scolosects create a counter to all poisons and predators, setting them on a course to evolve into the perfect species. ';
                break;
            case 98:
                d = "Discovered By YOOSHIMITZU"
                name = 'Mossey';
                description = 'This soft and furry etherian spends its days lounging around and gorging itself on berries in the forest. It has an extreme curiosity for other foods, especially those brought by travelers. ';
                break;
            case 99:
                d = "Discovered By YOOSHIMITZU"
                name = 'Clouwly';
                description = 'Cloulys are peaceful etherians that closely resemble the moss family. They surround inanimate objects and bring them to life. Using their new appendages, they search for better environments to make their home. The white flower above their base is their spore center. Every spring they shoot spores into the air in an attempt to spread their kind.';
                break;
            case 100:
                d = "Discovered By YOOSHIMITZU"
                name = 'Chloropan';
                description = 'Chloropans excrete a protein that allows them to store water in hardened crystalline form. They use this to hoard water supplies for a dry season. The water is not only used to nourish itself but can also be a source of power. Chloropans become much stronger when they consume their water supply. These etherians wear crystals around their necks similar to a soldier keeping extra ammo in their satchel. ';
                break;

            case 101:
                d = "Discovered By YOOSHIMITZU"
                name = 'Verchiro';
                description = 'You can find this sickly looking etherian perched on the tallest trees with some fungal growth on their head. Most would expect that these creatures are dying or weak, on the contrary, they are almost always in peak physical condition. When this creature reaches a certain age, it will forage for a specific fungus and intentionally infect themselves. This fungus hijacks the motor center of the brain allowing it to have complete control of its physiology; pushing the boundaries of what is physically possible.';
                break;
            case 102:
                d = "Discovered By WAZUPDUDE"
                name = 'Gumbie';
                description = 'Gumbies are capable of stretching their flexible bodies, helping them appear larger than they are. Territorial fights between male members of the species often amount to size contests. They puff out their chests, size each other up and eventually, the loser decides to run away. ';
                break;
            case 103:
                d = "Discovered By YOOSHIMITZU"
                d = 'unknown';
                name = 'Snakocile';
                description = 'Snakociles are amphibious creatures that swim in the depths of the swamp. The edge of its large jaw resembles an eel. Snakociles use this part of their bodies as bait as they lay beneath the water. Something much larger awaits for any predator that strikes the surface. ';
                break;
            case 104:
                d = "Discovered By YOOSHIMITZU"
                name = 'Mistadon';
                description = 'The black fur of Mistadons traps all light that would reflect off of it, giving it the appearance of a black cloud. Mistadons hunt sun etherians exclusively, especially those engulfed in fire. Their animosity towards these etherians stems from a hatred for all sources of light.  ';
                break;
            case 105:
                d = "Discovered By YOOSHIMITZU"
                name = 'Carrotar';
                description = 'Carrotars are omnivorous creatures that feed off of berries and insects. To fend off predators they have evolved into the most unpleasant tasting plant in the forest. ';
                break;
            case 106:
                d = "Discovered By YOOSHIMITZU"
                name = 'Mistpod';
                description = 'Faced with limited mobility, Mistpods create a sweet nectar smell to lure in their prey. A Mistpod will wrap its neck around its victims, clamping shut, creating an airtight seal. It then releases digestive enzymes, dissolving its prey over the next nine days. They release anything that couldnt be digested, as it prepares for its next meal.';
                break;
            case 107:
                d = "Discovered By WAZUPDUDE"
                name = 'Compost';
                description = 'Coming into physical contact with a Compost can cause severe illness. This condition leads to coma or death in more than fifty percent of its instances. Notable deaths include Dr. Amirah Shipwright, one of the early discoverers of the World of Ether. ';
                break;
            case 108:
                d = "Discovered By CHANG"
                name = 'Radakitty';
                description = 'To the best of its ability this large plant-like etherian remains hidden. Radakitty produce long tendril vines that span many yards away. These vines will capture passers-by in an attempt to murder them and pull them in. The Radakitty will then plant the corpses near its base and use them as fertilizer. The color of this etherian reflects what it ate, making the more dangerous ones red, based on its constant supply of blood. Radakitty would be a tasty meal, but most etherian don\'t go through the trouble of hunting them. Most etherians wait until the Radakitty die to dine on its juicy carcass.';
                break;
            case 109:
                d = "Discovered By WAZUPDUDE"
                name = 'Savageoccoli';
                description = 'A Savageoccoli\'s seven-foot frame is armored with a great deal of muscle mass. With its sharp claws and rows of predatory teeth, this creature is adept at hunting. Savageoccolis will tear their victims to shreds in a matter of minutes. They have long been in a territorial feud with Terrafangs. With their numbers dwindling they have been forced out of the forest.  Savaggeoccolis are found in the grassy plains now.';
                break;
            case 110:
                d = "Discovered By WAZUPDUDE"
                name = 'Woodmort';
                description = 'Woodmorts would be identical to trees if not for their humanoid arms, legs, and characteristics. They are born into slumber and can go their entire life without being awake. When they are conscious, they have the choice of uprooting themselves; although most find no need to roam. Woodmorts are entirely aware of their surroundings because they are in tune with nature. They can use their hulking masses to block or protect the forest from danger. In many circumstances Woodmorts block the lairs of Terrorfangs, protecting the environment from its would-be victims. ';
                break;
            case 111:
                d = "Discovered By WAZUPDUDE"
                d = 'unknown';
                name = 'Slidesap';
                description = 'A Slidesap\'s shell has reflective capabilities; it can deflect anything including bullets. They also secrete a potent psychedelic causing strong hallucinations, euphoria, and forgetfulness to anyone who comes in contact. Whether or not the slime is made in self-defense or for the pleasure of others is still unknown. Owning Slidesap is illegal due to the potentially addictive nature of the ooze.';
                break;
            case 112:
                d = "Discovered By YOOSHIMITZU"
                name = 'Cabbash';
                description = 'The need for powerful weaponry in the universe has shown a boom in rare elements trade. Cabbash are adversely affected by this new trend. Their ability to smell elements and see in low lighting make these creatures valuable. Because of this, they are often kept in indentured servitude. This trade is usually frowned upon, but not illegal.';
                break;
            case 113:
                d = "Discovered By YOOSHIMITZU"
                name = 'Demascus';
                description = 'Gangsters and bounty hunters favor this etherian. The protein casing covering this etherian makes it impervious to penetration by bullets knives or anything else. Its sharp claws, coated with the same material, allow it to make quick work of ships or their crew. However, Demacus are very prideful and will only work with a human if it respects them. Due to this, gangsters and bounty hunters imprison them against their will.';
                break;
            case 114:
                d = "Discovered By YOOSHIMITZU"
                name = 'Versillion';
                description = 'Travelers can find Vesillion on cliffs and other high areas. They are agile and considered intelligent. If reared at a young age, about two weeks old, it will gain trust and allow a sentient being to ride it as a mount. However, they are carnivorous etherians and require twenty pounds of meat per day to stay fed. Please be warned; they will destroy buildings and other structures as they see them as an insult to nature. ';
                break;
            case 115:
                d = "Discovered By STOTO"
                d = 'unknown';
                name = 'Terrafang';
                description = 'In the depths of isolated caverns, it roams — a creature with an undying appetitive for violence.  Infested with fungus and ticks, a simple brush with this etherian can spell your demise. It boasts thin spines across its frame, flowing like hair in the wind.  It can breathe fire but chooses to engage in close quarters so it can savor the screams and delightful squirming of its victim.';
                break;
            case 116:
                d = "Discovered By WAZUPDUDE"
                name = 'Toro';
                description = 'Members of this species cultivate flora on their back. A sponge-like material rests atop their spine where plant life can grow. This same plant life serves as a source of nourishment for Toro. Their choice of vegetation varies from one another. Some of these plants are suspected to have medicinal properties; it has yet to be confirmed. As this etherian ages, the bark-like material that coats its body becomes more dense, making it sturdy and hard to kill.';
                break;
            case 117:
                d = "Discovered By YOOSHIMITZU"
                name = 'Serperupt';
                description = 'This etherian best resembles a serpent having two heads and a white and green body. A telepath, Serperupt can communicate with anyone. Many sentient species seek out this rare creature for its supposed breadth of knowledge.  It can give a portion of its life force to bring corpses back from the dead, and it is rumored to grant wishes to travelers. ';
                break;
            case 118:
                d = "Discovered By WAZUPDUDE"
                name = 'Tyranolantern';
                description = 'Most life etherians need access to the sun for a few hours a day to stay alive. Tyranolantern have evolved into etherians that can store energy from the sun. Deep insides its chest cavity it can capture solar energy in a rocklike form. Using this energy at night, it lights up the forest and takes care of weaker life etherians.  It is a common misconception that this creature contains a sun crystal in its chest. Though the crystalline stored energy is similar in some respects to a sun crystal, it is not the same. Regardless, this misinformation has caused poachers to hunt this etherian to near extinction for its faux sun crystal.';
                break;
            case 119:
                d = "Discovered By WAZUPDUDE"
                name = 'Leeninja';
                description = 'Leeninjas have evolved into the fastest etherians on the planet. Their heart beats at an excess of 2000 bpm. They also have several nostrils on their faces which serve as entry points for oxygen and a steady source of air, keeping these etherians bodies cool. The aerodynamics of the creature is also essential, keeping it planted on the ground during high-speed movement. This species needs to spend 80% of the time in the sun with short daily cycles. This creature manages this by traveling faster than the planet is turning, at an insane 1800 mph. To put it in perspective, Leeninjas are faster than a moving train but just short of a speeding bullet.';
                break;
            case 120:
                d = "Discovered By WAZUPDUDE"
                d = 'unknown';
                name = 'Slither';
                description = 'Deep in the vegetation of the forest lives a very peculiar tree. This tree is sensitive to the state of the woods. It is so in tune that any disturbance in the habitat will cause it to wilt; only on that day can this etherian be born. Born with wings of leaves, this serpent-like etherian seeks to annihilate whatever harmed the forest. When its murderous rampage has ceased the leaves on its wings wilt, and it slithers back from whence it came. It removes its heart and plants it like a seed, and dies on that spot. From this seed grows the most peculiar tree. ';
                break;
            case 121:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Bonemouth';
                description = 'Not all death etherians are part of the brood. However, you should avoid every death etherian at all costs. Bonemouths are the only non-nefarious death etherian. This cute and friendly creature would make for a fantastic pet as it can never die from old age. They bring you bones from nearby graves to show their affection.';
                break;
            case 122:
                d = "Discovered By KOISURUMAJYO 2"
                d = 'unknown';
                name = 'Spinackle';
                description = 'Spinackles have bodies comprised of just a spinal cord with several bones attached. As they soar above the sky, these creatures spin making their bones produce a shrill, dry grinding noise. The sound creates agitation and discomfort to any listener, similar to nails on the chalkboard. It has driven many to insanity, some tear their ears out to escape the awful noise.';
                break;
            case 123:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Latroxx';
                description = 'A Latroxx may be one of the most peculiar creatures; its body acts as a reservoir for a multitude of viruses and bacterium. They intentionally contract the deadliest illness from every planet to use later for warfare. They are immune to these ailments as they are so close to death already. This etherian is weak by design; its sole purpose is to die and release terrible plagues upon the world. . ';
                break;
            case 124:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Evicera';
                description = 'Evicera and Agony are the only death etherians that originated from the death etherian home planet. You will encounter Evicera in swamplands and polluted waters. Travelers have also seen them slithering on land. Be wary when faced with an Evicera; they shoot a deadly, acidic venom sprayed from their faces.';
                break;
            case 125:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Deadsteed';
                description = 'Deadsteeds can serve as a mount for their owners. There are far better means for travel, but riding on the backs of these etherians holds a psychological advantage. Nothing destroys the morale of your enemies more than hearing the rhythmic tappings of hooves on the ground as death approaches.';
                break;
            case 126:
                d = "Discovered By KOISURUMAJYO 2v"
                name = 'Nightage';
                description = 'There is a hormone the body releases when you are afraid. This same hormone is released by Nightage females in heat. A Nightage can find its scared prey with lightning speed. If this etherian is stalking you, it is of grave importance to remain calm. It is incredibly thin so it can jump in and out of shadows, slip between cracks, slide under the shade of one\'s eyeballs and lay in the darkness behind a victims brain. ';
                break;
            case 127:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Dermathesis';
                description = 'Dermathesises are a blight to all death etherians.  They are a cancerous growth that live beneath the skin of nearly 8% of the death etherians. Maintainers of the death brood take much care to pluck these out of the surface of the larvae. If this etherian is left unchecked, it eventually grows to take over and engulf the body of its host. Ironically, a fully developed Dermathesis can die by having an even smaller Dermathesis growing inside of it.';
                break;
            case 128:
                d = "Discovered By KOISURUMAJYO 2"
                d = 'unknown';
                name = 'Nepharaf';
                description = 'Nepharaf is a general term for any nonspecified creature raised from the dead by a Wyrm. As water leaves the system the blood of these creatures becomes thick and black with the stench of death. Nepharafs do not need nourishment to survive. However, water can keep them "healthy" otherwise their muscle will become stiff. The only way to kill these etherians is to destroy its heart or circulatory system. It is speculated that genetic material from the other types can slip into the death etherians when this creature breeds.';
                break;
            case 129:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Akamaltachi';
                description = 'Akamaitachis are parasitic death etherians. Their boneless bodies are comprised of cartilage. They can excete nearly all its fluids, waste or undigested food. This mass detox allows it to become very thin, making it possible to enter small lacerations on a potential host. They also produce potent anesthesia from its skin allowing it to enter its host unnoticed.';
                break;
            case 130:
                d = "Discovered By KOISURUMAJYO 2"
                d = 'unknown';
                name = 'Agony';
                description = 'The death etherians home planet is a world engulfed in a perpetual fog, pollution, decaying bodies, and rotting marsh. Its unyielding precipitation feeds its swamp-like terrain. Swarms of creatures are locked in a constant battle over what little resources this planet holds. The high turnover rate for life on this planet is what forced evolution to create one of the first two death etherians.';
                break;
            case 131:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Unseen';
                description = 'The death brood goes to great lengths to find the ocean etherian Seen and bring it to the death side.  This etherian\'s unparalleled ability to see into other planes of existence is an excellent tool for Broodmother to thwart attacks from astral etherians. ';
                break;
            case 132:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Chilomori';
                description = 'Explorers may find Chilomori lurking around burial grounds. They feast on the dead flesh of all creatures even other death etherians. As long as there is meat around the bones or at the very least marrow inside of them, Chilomori will feed on a decaying carcass. They are subterranean and often camouflage themselves with gravestones on their backs.';
                break;
            case 133:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Akamanto';
                description = 'Akamantos graze on dead or decaying plant life, including certain life etherians. They are capable of both making and using tools. Male Akamotos fashion logs into make-shift longswords and ambush their rivals in the depths of the forest.';
                break;
            case 134:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Hemosite';
                description = 'The death brood colonies are layers of larvae pools. Hermosites serve as custodians, roaming each section feeding blood and nutrients to the larvae and watching for parasites. They determine which species of offspring to produce. Hermosites maintain the Broodmother as well. If forced, they will defend the colony with their lives.';
                break;
            case 135:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Killamungo';
                description = 'Killamungo does not exist naturally outside of the brood colony. Their species operate as a guard to the Broodmother herself. Their large arms, filled with immense strength, are used for travel. ';
                break;
            case 136:
                d = "Discovered By CORTANA"
                name = 'Chort';
                description = 'Chorts are sadistic death etherians that enjoy toying with their prey. On the hunt, they will maim a member of the pack to slow it down. They do not do this for strategic purposes, but to cause chaos and study their victim. Chorts will slice a creature down the middle and gaze as it takes its final breaths. These gruesome kills are less for nourishment, but more to feed a Chort\'s curiosity of its preys warm fleshy insides.';
                break;
            case 137:
                d = "Discovered By STOTO"
                name = 'Cauldromorte';
                description = 'Cauldromortes are sentient slime, birthed by Notre, and considered to be the lifeblood of the brood colony.  Cauldromortes help with many tasks; they operate as an irrigation system, repair broken structures and even ferry larvae. When combined with another Cauldromorte they fuse into one being, a single large mass. A pool of Cauldromorte will offer up their liquid flesh to feed larvae and other members of the colony. ';
                break;
            case 138:
                d = "Discovered By KOISURUMAJYO 2"
                d = 'unknown';
                name = 'Plight';
                description = 'A parasitic species, Plight eat and burrow into the guts of its host. They can remain in their body long after its death. Plight are highly susceptible to freezing in cold weather, so they leave the brain motor functions intact for the body to produce heat. It will remain in its new home for weeks or at least until it can grow tendrils and crawl to its next victim. It is cursed to search and occupy more prominent hosts as it becomes larger itself. ';
                break;
            case 139:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Polpetta';
                description = 'Polpettas are the oldest entry in the Woedex. Their red spherical shape resembles balls of raw meat. Their round figures are framed by a pair of small wings and a series of flagella. A young Polpetta is agile and formidable; their bodies are comprised of 80% muscle. Not many creatures can summon the willpower to escape the sinister grip of this dangerous opponent. However, the older they get and the more they eat, they continually add more meat to their bodies. In an attempt to stay afloat, an older, heavier Polpetta will spawn new wings. After Polpetta get larger they are limited to ground movement, rolling around flailing their tentacles. Older Polpettas find themselves prey to the more youthful members of their species. ';
                break;
            case 140:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Wyrm';
                description = 'Wyrms attach themselves to the heart of a living creature. They usually choose larger hosts, ideally a Terrafang. No harm will come to their new home. Instead, Wyrms courteously wait for their host to reach a natural demise. Afterward, they will reanimate the corpse and use its body for all sorts of foul acts.';
                break;
            case 141:
                d = "Discovered By PRINCESS NAKAMOTO"
                name = 'Maggoth';
                description = 'The death brood produces these larvae that eventually get differentiated into a variety of creatures, based on whatever role is needed. They are relatively weak, having limited sight and mobility. They are omnivorous, but their diet usually consists of the blood that is fed to it by Hemosite.';
                break;
            case 142:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Trilatinous';
                description = 'Trilatinouses are a jelly-like slime etherian. Males of the species are tetrahedrons and females are pentahedrons. They do not possess eyes, so they are unable to see. They map their surroundings by detecting small vibrations — the sound of a heartbeat or the murmer of conversation help to guide these blind etherians. They shun the light and prefer residing in dark tight spaces where they can trap their prey. Trilatinouses are translucent making them hard to see. Once they engulf you, Trilatinouses will dissolve your body and spit out all metals. They turn red with blood as they digest their victims.';
                break;
            case 143:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Osciliator';
                description = 'Oscillators serve as a hard drive for all knowledge attained by the death brood. They have a brain to body weight ratio of 8:3, allowing them to be one of the most intelligent death etherians. They are found hovering around the Broodmother providing consult and reference. Oscillators keep detailed records of all etherians and species in existence, including their weaknesses and origins. They also track the history of the brood since its originating member. ';
                break;
            case 144:
                d = "Discovered By STOTO"
                name = 'Konsruct';
                description = 'Konsructs can observe their environment through multiple means. A multitude of highly specialized hairs on its frame allows it to examine humidity and vibrations. A dozen eyes cover its body capturing light in the dimmest of nights. An exoskeleton of muscle supports a Konstructs frame. Similar to their relative Dreaden their chest cavity is a mouth. They can also spread themselves flat on their ventral side. In a full sprint, a Konstruct can leap, flatten its body and envelope its prey, all while not losing momentum.';
                break;
            case 145:
                d = "Discovered By CORTANA"
                name = 'Kermy';
                description = 'When death etherians take over etherians from another type, there is often a "mix" of genetic material. Kermy has been speculated to be a mix of life and death and etherians. This abomination can control the weather. ';
                break;
            case 146:
                d = "Discovered By YOOSHIMITZU"
                name = 'Belzar';
                description = 'This creature finds the taste of skin so irresistible that the first few weeks of its life it\'s in agony.  Belzars are born with skin; then they see themselves surrounded by the best-tasting meal. Eventually, they cave in and eat their skin, itching for more... it cannot wait to eat yours.';
                break;
            case 147:
                d = "Discovered By KOISURUMAJYO 2"
                d = 'unknown';
                name = 'Sturm';
                description = 'For every one hundred thousand death etherians, the brood produces just one Sturm. Ruling as the generals of the death brood Sturms wields the force of the entire army. Sometimes escorted by the even rarer Diskord etherian, Sturms are considered the strategist of the brood, patrolling the universe deciding which species to wipe out or which space station to plunder. It is rumored, that within 5 hours from contact, this etherian will skip over your species if a sizable sacrifice is made in its honor.';
                break;
            case 148:
                d = "Discovered By STOTO"
                name = 'Orcrotte';
                description = 'Orcrottes are terrible creatures. They are prone to murder, frequently reek of feces and are plagued with diseases. They have two heads, one on their tail, they often argue. Orcottes have their own native language, comprised of hisses and guttural sounds. Though they can converse with a sentient being when taught the language, it is ill-advised. Their native lexicon is solely made up of insults, threats, and words to describe murder. These are the only subjects an Orcrotte\'s brain can understand. So a conversation with this rodent will be rich with expletive insults and its desire to kill you. ';
                break;
            case 149:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Azalee';
                description = 'When a new etherian is encountered, the Broodmother dispatches this curious creature. An Azalee\'s primary role is to gather information about the strength of a species for the brood. Their powerful eyes, located inside of the mouth, operate similarly to a microscope. This unique trait allows them to peer into the DNA of its prey as it devours them. ';
                break;
            case 150:
                d = "Discovered By YOOSHIMITZU"
                name = 'Notre';
                description = 'The bile spewed by Notre has two purposes. It can harden creating a concrete-like composition that used to build brood walls and floors for their nests. The bile can also be used to taint the oceans of a planet, destroying marine life and forcing the planet to take a turn for the worse. ';
                break;
            case 151:
                d = "Discovered By YOOSHIMITZU"
                name = 'Thane';
                description = 'This monstrosity can be found slithering along graveyards looking for a meal, alive or dead. While these etherians are abundant, they rarely come into existence on their own. Death larvae are bred with the express intent of becoming Thane for the sake of wielding. If you find one alone, its owner has long since been deceased. Thane will happily serve any handler, as long as you feed it regularly.';
                break;
            case 152:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Oratorum';
                description = 'Oratorum can mimic the sounds of anything it encounters. It memorizes phrases and calls for help in a variety of languages. Scientists are unaware as to whether Oratorum understands the words they speak or simply repeats the noise. What is known is that they prey on the insecurities of its listener; mimicking the sound of a crying baby to a mother or a call for help from an already deceased comrade.';
                break;
            case 153:
                d = "Discovered By KOISURUMAJYO 2"
                d = 'unknown';

                name = 'Draith';
                description = 'Draiths soar above the sky, laying in dark clouds, surveying the landscapes below. With incredible stealth, they float down quietly to remove heads clean from shoulders. Draith can extend its whispy body to bolster its size and notably their range. When paired with Thane, they become a formidable duo. They cannot communicate, but in flight, air gets caught in its body cavity and leaves in spurts, which if you listen carefully, could sound like maniacal laughter';
                break;
            case 154:
                d = "unknown"
                name = 'Dreaden';
                description = 'Dreaden and Konsruct share a common evolutionary ancestor. They both have mouths in the middle of their bodies, allowing them to consume massive amounts of food without chewing. Dreadens have wings built of bone with tips lined in a metallic alloy. While on a hunt, Dreaden will swoop down on their game engulfing them in a single action.   ';
                break;
            case 155:
                d = "Discovered By PRINCESS NAKAMOTO"
                name = 'Amalgamation';
                description = 'A representation of this etherian is hard to create as it is just an amalgamation of limbs and flesh. Amalgamations are another death etherian\'s attempt to bring a creature into existence. This mix match of eyes, flesh, and bone do not resemble anything in the known universe. It is an attempt to create life by a creature that has never seen life.';
                break;
            case 156:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Necronomica';
                description = 'Death etherians have had a lot of evolutionary pressure to camouflage themselves to live amongst sentient life. Necronomicas are highly successful in blending in. In the heat of battle, no one pauses to peer into the pages of a book. Peering into these pages would be of no use, no one has ever gazed upon its content and left with their sanity.';
                break;
            case 157:
                d = "unknown"
                name = 'Blackwing';
                description = 'A deafening roar from this etherian can compel the dead to forsake the grave and do its bidding. A Blackwing\'s roar will reanimate any corpse, but for only 24 hours. Ultimately, these cadavers return to their afterlife, until another roar beckons it to service.';
                break;
            case 158:
                d = "unknown"
                name = 'Rabid';
                description = 'This etherian is a simple-minded creature, but what it lacks in intelligence, it makes up in power. Rabid sits atop a body of pure muscle and hard bone. These etherians have been coined the "Horse of Death," due to its pointy ears, blinding speed and lust for violence. They\'ve eviscerated all that stand before them,which is why their moniker is inaccurate. They resemble rabbits far more than they do horses.';
                break;
            case 159:
                d = "Discovered By PRINCESS NAKAMOTO"
                name = 'Broodmother';
                description = 'Broodmothers are the supreme rulers of the death etherians and the controllers of the brood\'s migration. Their grand mission is to end all life in the universe. The lifespan of this etherian is 80,000 years. Female Broodmothers produce death larvae; if there are no females present in the brood, a male can too. They require a consistent flow of blood to produce children.';
                break;
            case 160:
                d = "unknown"
                name = 'Diskord';
                description = 'An insult to nature, this horrendous creature is only formed when a humanoid strikes a deal with the Broodmother. To escape sickness or death, a humanoid\'s lifespan extends by 70 years. The pact grants them ungodly speed and strength. When the time on their deal expires, the humanoid surrenders his or her freedom to the Broodmother; doomed to follow her lead for the rest of eternity, or until its fortunate demise.';
                break;
            case 161:
                d = "Discovered By WAZUPDUDE"
                name = 'Cryslist';
                description = ' Picking a fight with a Cryslist is ill-advised. They possess a \'temporal bite.\'  When bitten the victim becomes \'weaker,\' this does not damage its 3rd-dimensional body but instead damages its stability in the universe. Subsequent attacks from this creature will cause its prey to vanish, disappearing into nothingness. Fortunately, this creature will not pick fights; it will only do this if you attempt to corner or catch it.';
                break;
            case 162:
                d = "Discovered By WAZUPDUDE"
                name = 'Smeeley';
                description = 'Having a pet Smeeley can prove to be problematic. In all actuality, you become more of a pet than it is. It feeds on memories. A maintained Smeeley will only nibble on your memories, leaving them intact but omitting specific details. A voracious, starved Smeely can devour one\'s complete mental history, leaving them with the equivalent of severe Alzheimers, or even a vegetative state. ';
                break;
            case 163:
                d = "Discovered By WAZUPDUDE"
                name = 'Flubi';
                description = 'Members of this species primarily reside on the astral plane, feeding on dark matter to survive. Researches on the material plane believe astral creatures to be immortal, that is not the case. Flubis have finite lifespans, living up to 20 years of age. Time is experienced at a different pace on the astral plane; so Flubi, and beings like it, age 1000 times slower. However, if something from the astral plane is brought to the material plane, age will catch up to it. A Flubi brought on this journey would seem to disappear; but what you are witnessing is them dying instantly. The average age of a Flubi in the astral plane far exceeds what it should be on the material plane.';
                break;
            case 164:
                d = "Discovered By WAZUPDUDE"
                name = 'Ephimrir';
                description = 'Ephimrirs have slight control over matter. Their bodies excrete oxygen and water.  You can find colonies of Ephimrirs on uninhabitable planets because they are not bound by the laws of the material plane. Researchers are unsure if they knowingly make planets more habitable for future civilizations. They are leaving both oxygen and water on these planets nonetheless. ';
                break;
            case 165:
                d = "Discovered By WAZUPDUDE"
                name = 'Andromador';
                description = 'This etherian has an absence of intellect from its birth. Its brain is completely blank; instead, it captures the thoughts and minds of those around it, coalescing it. This excellent evolutionary tactic is used to learn from its own kind or circling predators. However, if surrounded by the vilest of creates it will succumb to evil. It would seem the mind can be a terrible thing to gain.';
                break;
            case 166:
                d = "Discovered By WAZUPDUDE"
                name = 'Azimutt';
                description = 'Azimutts, like all astral beings, rarely need nourishment to survive. When they do have the urge, every 100 years, they hunt in packs. Jumping from world to world looking for prey, they try not to harm the ecosystem. They only kill members of a species whose population is in excess. They then store any surplus food in the astral plane for it to remain fresh; as things on the astral plane decay at a slower rate.';
                break;
            case 167:
                d = "Discovered By WAZUPDUDE"
                name = 'Dritzy';
                description = 'Dritzys can listen for sounds from different planes of existence. They appear to any sentient being that is producing beautiful music. A crowd of these will eventually surround the gifted musician and sit in a concert. If they are pleased, they will leave a gift. The astral plane is abundant in rare materials. It is nothing for a Dritzy to leave a nugget of gold, a handful of diamonds and on one reported instance a chunk of uranium. ';
                break;
            case 168:
                d = "Discovered By WAZUPDUDE"
                d = 'unknown';
                name = 'Ora';
                description = 'In the middle of an Ora\'s torso, sits a sphere that resembles a glass orb filled with star-light material. These creatures don\'t eat to survive, instead survive off of the antimatter they store it in their glass-like bellies. When the antimatter interacts with ordinary matter, it produces an unusual amount of energy that this creature can use to do remarkable things. ';
                break;
            case 169:
                d = "Discovered By WAZUPDUDE"
                name = 'Bazu';
                description = 'You are in the astral plane when you aren\'t anywhere else. It is the road between everywhere. It is the home to thought, belief, and will. Bazus act as ferrymen that bring material beings (us) to their plane. However, traveling to the astral plane isn\'t without risk. If something were to happen to your Bazu, you could find yourself tumbling through space, for what would seem like the rest of eternity. Also, if you aren\'t cautious, an Eon etherian can slip through to our side, right when you enter.';
                break;
            case 170:
                d = "Discovered By WAZUPDUDE"
                name = 'Klahk';
                description = 'If the astral plane is the home of thought and belief, then Klahk hail from somewhere much different. These etherians are native to the Nightmarescape. It is a plane related to the astral realm; except it is filled with the thoughts of fear, hate, and vileness. Most astral etherians do not dare to cross that domain. Words cannot adequately describe a Klahks diet. They do not feed on the physical form of a creature. Instead, they feed on their essence or daresay their soul.';
                break;
            case 171:
                d = "Discovered By WAZUPDUDE"
                name = 'Obeigh';
                description = 'This curious, playful etherian is solely found playing on the surface of dead stars. It does this by skipping in and out of our plane of existence, escaping gravitational pressures and radiation. It is only ever viewed through powerful microscopes for short bursts of time. Initially, it was thought to be some form of lens distortion.';
                break;
            case 172:
                d = "Discovered By WAZUPDUDE"
                name = 'Horus';
                description = 'An anomaly to our universe, this etherian is a two-dimensional being. The lines on the side of its body, when viewed from the third dimension, are its digestive track. Horuses use sound and touch to navigate, as sight in the second dimension is virtually useless. These creatures have a hard time dealing with three dimensional beings as they cannot understand our composition. This creature seems to disappear and reappear at times, almost at random. Scientist hypothesize that this etherian can use the fourth dimension, time, to overcome obstacles. However, it is hard for us to determine that, as three-dimensional beings ourselves.';
                break;
            case 173:
                d = "Discovered By WAZUPDUDE"
                name = 'Peliquinn';
                description = 'The astral plane has no gravity, yet things can stay in motion. There is no "up" nor "down" in the astral plane. In the astral plane, you can push off floating objects to get to your destination. So many etherian forms are irrelevant, their legs and wings aren\'t a necessity. This creature, however, glides on an ocean in the astral plane and it\'s small wings to maneuver. Peliquinns reach high speeds in their realm, but on our plane of existence, they move rather slow.';
                break;
            case 174:
                d = "Discovered By WAZUPDUDE"
                name = 'Frinn';
                description = 'Tasked as guardians of all life on the material plane, Frinns attempt to annihilate death etherians. Their enemy does not follow the natural order. They also find themselves pitted against etherians from the Nightmarescape on a regular basis, but they are ill-suited to defeat any of them. ';
                break;
            case 175:
                d = "Discovered By WAZUPDUDE"
                name = 'Space-Saver 3000';
                description = 'After learning about astral etherians, scientists created a box with the same physical capabilities.  This box can hold a seemingly infinite amount of regular matter by partially storing it in alternate planes. Sometime later these boxes became sentient, and things started to come through them. Then, these boxes began to breed creating their own species, the first and only etherian a human has created.';
                break;
            case 176:
                d = "Discovered By WAZUPDUDE"
                name = 'Flameaw';
                description = 'Swarms of twisted creatures haunt the Nightmarescape, most will go undiscovered. However, we have collected information on five of them, and they are documented here in the Woedex. Flameaw is one of these five horrid species. These etherians abduct the innocent and drag them to alternate planes of existence. These places do not follow our rules of physics and are impossible for our brains to understand. They leave them there for months on end leading to insanity. They then feed on the beings destroyed mental state. Frinn hunt Flameaws, but it would take a large number of them to dispatch just one Flameaw. ';
                break;
            case 177:
                d = "Discovered By WAZUPDUDE"
                name = 'Era';
                description = 'This etherian feeds on what would be alcohol when in the Astral realm. When visiting the material plane, Eras are ecstatic with the selections of spirits produced by humans. They will drink beer, whiskey, and even rocket engine fuel. They never get drunk in their realm, but can get pretty inebriated here. Be wary of having one of these near a gas supply.';
                break;
            case 178:
                d = "Discovered By WAZUPDUDE"
                name = 'Theta';
                description = 'Reigning from the Nightmarescape, this monstrosity aims to be the most perfect being in existence. Thetas are slimy, wet creatures that have a rectangular shape and four fins that help traverse the planes. Thetas can fly in the material realm. Its body is composed of soft flesh, making it physically weak. It will track the most intelligent sentient beings and enslave them, forcing them to divulge all of their scientific knowledge. It will disassemble technology and learn from it. Afterward, it will combine that creatures DNA with its own, making Theta increasingly more intelligent. However, this new addition causes their physical form to get weaker and stranger. They are a terrible threat to the universe.';
                break;
            case 179:
                d = "Discovered By WAZUPDUDE"
                name = 'Shadowcree';
                description = 'Able to manipulate atoms and change matter, it is thought that this etherian is the creator of most of the planets in the universe. Whimsical and cute, this creature cannot age. Only extreme circumstances can cause this etherian to die, because of this most will not experience death. The only threat to its mortality is when it befriends a human, and they die. There are multiple documented instances where a Shadowcree has moved heaven and earth to reconstitute a human and bring them back to life.';
                break;
            case 180:
                d = "Discovered By WAZUPDUDE"
                name = 'Seemsam';
                description = 'Seemsam is one of the five known Nightmarescape abominations. This creature lurks in the nightmares of the young. Seemsams trick their victims to think they are awake when they are still sleeping and vice versa. This illusion helps lure the unsuspecting into the Nightmarescape. Once there it feeds on their torment and does many unsavory things.';
                break;
            case 181:
                d = "Discovered By WAZUPDUDE"
                d = 'unknown';
                name = 'Quilevo';
                description = 'Under the mounds of Quilevo\'s fur lay milky eyes. Their infrared eyes provide them with enough detail as visible light. However, visible light will be as pitch black to them rendering them helpless. They detest the sunlight because of this, preferring the safety of night.';
                break;
            case 182:
                d = "Discovered By WAZUPDUDE"
                name = 'Sheldon';
                description = 'These creatures have psychic abilities that are so potent that older, wiser members of their species must train them. Overuse of their powers can result in severe brain damage. If you find yourself in possession of one of these etherians, be mindful that its power has limits.';
                break;
            case 183:
                d = "Discovered By CORTANA"
                name = 'Krung';
                description = 'Krungs are an enormous leviathan-like slug. They leave a poisonous trail of mucus in their wake. They breathe a flesh-melting fire from their maws. Though it is formidable, most etherians are off the menu for this apex predator. Krungs solely hunt abominations,  things that don\'t belong or shouldn\'t be in existence. Luckily we have yet to see their prey as Krungs remain in the astral plane and are undiscovered.';
                break;
            case 184:
                d = "Discovered By CORTANA"
                name = 'Beholdant';
                description = 'A controversial addition to the Woedex this description is thought to be incorrect. A group of scientific explorers in their journey to find life on other worlds, fell victim to a virus that affected their memory. A rescue crew found their ship, and each passenger was in a semi-vegetative state, with all their minds and most motor function lost. The only thing these scientists could do was to sketch one image over and over.  It was a floating eye, with several tentacles protruding from it.';
                break;
            case 185:
                d = "Discovered By WAZUPDUDE"
                name = 'Murdran';
                description = 'Murdrans possess all the abilities of the other Nightmarescape astral etherians but stronger. They can banish any creature to an alternate plane just by willing it so. They do have one weakness; a Murdran cannot leave its native plane for too long. They will become weaker and eventually can never return. Considered the dominant species of the Nightmarescape, as the other members follow it\'s lead.';
                break;
            case 186:
                d = "Discovered By CORTANA"
                d = 'unknown';
                name = 'Seibon';
                description = 'This five-headed etherian whispers in the minds of all sentient beings. They\'ve had a hand in shaping the life of all the creatures in the material plane. Their primary intent is good. They operate as a conscious for all beings and are thought to have at least had a slight presence in all good thoughts. ';
                break;
            case 187:
                d = "Discovered By WAZUPDUDE"
                d = 'unknown';
                name = 'Crouth';
                description = 'Unlike most astral etherians Crouths are uninterested with the affairs of the universe and planes of existence. They spend most of the day either feeding on antimatter or travel far and wide for it. After ten years of feeding, this creature can produce one astral crystal, but it hides it away deep in the recesses of the plane.';
                break;
            case 188:
                d = "Discovered By CORTANA"
                name = 'Knell';
                description = 'Knells are born blind, but they compensate by viewing other beings in an emotional spectrum. They read the hormones living beings excrete. If a single hair of a being comes in contact with a Knell, the history of that being is read through it\'s absorbed hormones. The record is limited up until that hair first grew. This doubles as a communication method between all Knells as tiny little hairs cover each one. ';
                break;
            case 189:
                d = "Discovered By WAZUPDUDE"
                name = 'Mindsnire';
                description = 'Each single Mindsnire shares consciousness with each other. Each operates as a single processing unit for data. This group-think allows the network of all Mindsnires to perform mind-bending calculations. Mindsnires are likely the smartest living being in the known universe. Its body is composed 100% of neurons, with tougher neurons on its topside to shield it from UV rays. Staring into this etherians eyes will slowly reduce your heart rate until it eventually stops. They are often confused with the etherian Beholdant.';
                break;
            case 190:
                d = "Discovered By CORTANA"
                name = 'Eon';
                description = 'This etherian is a counterweight to the planes of existence. Whenever a material plane being, like a human, enters the astral plane one Eon etherian gets to escape. There is not an etherian on our plane matching the strength of an Eon. While an Eon is present in the material realm, its human counterpart is trapped on the astral plane. The only hope of escape is to catch this creature and return it from whence it came. The world will be balanced, as all things should be.';
                break;
            case 191:
                d = "Discovered By KOISURUMAJYO 2"
                name = 'Cymian';
                description = 'Cymians bodies feed off of light.  Their metabolism interacts with light photons supplying it with nutrients. It can store this excess light inside of Its body kind of like how fat stores energy in most creatures. When they need to access this storage, their eyes glow and burn their fat stores.';
                break;
            case 192:
                d = "Discovered By CORTANA"
                d = 'unknown';
                name = 'Magnivoid';
                description = 'This large, boisterous being is just formidable intellectually.  It spends its existence frantically feeding Maldragon. They continue this servitude in perpetuity as to not feel her wrath. Magnivoids hunt evil creatures for this daily sacrifice. They wish for nothing more than to destroy Maldragon and free themselves from its servitude, but they are not foolish enough to think they can. ';
                break;
            case 193:
                d = "Discovered By CHANG"
                name = 'Throddar';
                description = 'Throddar\'s wings are a vestigial appendage, similar to an appendix on a human.  The ancestors of this etherians were large sprawling dragons with wingspans four times their body length. After three million years of evolution Throddars can teleport; rendering their wings useless. They have reduced in size ever since.';
                break;
            case 194:
                d = "Discovered By PRINCESS NAKAMOTO"
                name = 'Collectra';
                description = 'Collectras can physically see possibilities. Their bright swirling eyes peer into time and see what measurable outcomes could be. They mostly live a secluded life. However, they venture out once a decade to collect all etherians. Keeping each egg unhatched in their lairs.';
                break;
            case 195:
                d = "Discovered By PRINCESS NAKAMOTO"
                name = 'Nebule';
                description = 'This etherian presents itself as an illusion or puzzle to be solved. If it ever reveals itself to you, its true form is not the body in front of you — instead, a cube appearing over the bodies head. The form below is a physical manifestation of its personality.  A brave and willful Nebule appears as a toad, it can also look like a snake or a human if it so chooses. Although, they find humans to be rather disgusting creatures.';
                break;
            case 196:
                d = "Discovered By CORTANA"
                name = 'Garf';
                description = 'Writing about this creature causes a great deal of confusion. It does not want its presence known in the universe. Its most astounding quality is its ability to.. blrrr urrbruu rburrrrbb urbb urbbb.... 42.';
                break;
            case 197:
                d = "Discovered By CHANG"
                name = 'Egoh';
                description = 'The imagination of all sentient beings caused the astral plane to create this being. It is a wholesome creature that attempts to grant the wishes of those who dare to imagine, of those who dare to have a dream and those who want to accomplish something grand. ';
                break;
            case 198:
                d = "Discovered By CORTANA"
                d = 'unknown';
                name = 'Maldragon';
                description = 'This sprawling dragon lays in wait somewhere in the astral plane. It is considered a genius-level species. Its whole purpose is to assimilate all creatures under it. It can split it\'s consciousness onto beings it touches, wiping their individuality and doing it\'s bidding. Coined a hive-mind. It doesn\'t necessarily do this with evil intent. Instead, it just strives to be one with the universe, to uniform all creatures. ';
                break;
            case 199:
                d = "Discovered By PRINCESS NAKAMOTO"
                name = 'Geosauria';
                description = 'Prophecies tell of an immortal Geosauria that resides at the edge of the astral realm. It is said to have created a lush paradise for all good beings. There it has collected all the knowledge of the universe across all planes. It will gladly teach these secrets to whoever can find it. The only caveat is that once you possess this knowledge, you cannot leave its side. You will be trapped in its paradise. It is impossible to escape. Even if you were to manage it, the amount of time it would take to truly understand this secret is much much longer than all lifespans. The time difference would kill you upon leaving the astral plane.';
                break;
            case 200:
                d = "unknown"
                name = 'Astrend';
                description = 'The existence of this etherian has been mathematically proven. However, it has never been seen nor observed. Some scientists choose to believe that this etherian can form wormholes; explaining it\'s sporadic movement across the universe. Others even doubt it\'s existence because a being with this much power should not exist.';
                break;
            case 201:
                d = "unknown"
                name = 'Jolide';
                rarity = 'Rare'
                description = 'This entity has only been sighted in winter times, caught with the sound of ringing bells and sticking out like a poinsettia in the snow. This Etherian is attracted to lights and sweets, making toy weapons from to attack those who approach it. They tend to be skiddish around people but Otherwise timid and friendly! (Filled with High spirits.)';
                break;
            case 202:
                d = "unknown"
                name = 'Maxalar';
                rarity = 'Rare'
                description = "Initially a weak species, one of Maxalar's only natural redeeming traits is its impeccable hearing. It can hear your terror, as you flee from its sight. It can hear you whimpering, cowering, and hiding in your ship. Fearing you'll give away your position you grasp your mouth shut to stop your breath from escaping. You know it's coming for you. But your breath won't betray you. It's your heart, it can hear it calling out as it pounds frantically, beating through your chest. a Maxalar's goal is simple, for every being it can devour the heart of. It will gain immense strength. Every heart, regardless of species or faction. A Maxalar will even hunt one another just to gain strength.";
                break;
            default:
                name = '';
                description = '';
        }
        if (
            padded === "039" || padded === "040" ||
            padded === "079" || padded === "080" ||
            padded === "119" || padded === "120" ||
            padded === "157" || padded === "159" || padded === "160" ||
            padded === "199" || padded === "200"
        ) {
            image = `https://woe2.s3.amazonaws.com/shadows/${padded}/shadow.png`;
            image2 = image;
            d = ""
            name = "UNKNOWN"
            description = "There is no information on this Etherian. Once discovered, this entry will be updated.";
        }
        let discoveredby = d;
        return {
            name, color, rarity, description, image, image2, typez, discoveredby, num
        }
    }

    sliderCreate() {
        let z = [];
        for (let i = 0; i < 203; i++) {
            let padded = String(i).padStart(3, '0');
            let isact = ""
            if (i === this.state.num) {
                isact = " active";
            }
            z.push(<li key={i} onClick={(e) => this.changeEtherian(e, i)} className={`slide-nav${isact}`} data-slide={i}>{padded}</li>)
        }
        return z;
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.name} | {this.state.rarity} {this.state.typez}</title>
                    <meta name="description" content="World of Ether Monster WoeDex" />
                    <meta property="og:image" content={this.state.image} />
                </Helmet>
                <div className="wrap">
                    <div id="navigation">
                        <div id="logo">
                            <Link to="/">
                                <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                            </Link>
                        </div>
                        <div id="menu-right">
                            {/* <div id="lang-drop" className="isActive">
                                <img src="https://woe2.s3.amazonaws.com/english.png" onClick={() => { this.onTouched('en') }} alt="language icon" />
                                <img src="https://woe2.s3.amazonaws.com/ch.png" onClick={() => { this.onTouched('ch') }} alt="language icon" />
                                <img src="https://woe2.s3.amazonaws.com/jp.png" onClick={() => { this.onTouched('jp') }} alt="language icon" />
                                <img src="https://woe2.s3.amazonaws.com/ru.png" onClick={() => { this.onTouched('ru') }} alt="language icon" />
                                <img src="https://woe2.s3.amazonaws.com/kr.png" onClick={() => { this.onTouched('kr') }} alt="language icon" />
                            </div> */}
                            <Link to="/roadmap">
                                <div className={`mask ${this.props.lang}`}>
                                    <div className="mask-inner">
                                        {lang.roadmap[this.props.lang]}
                                    </div>
                                </div>
                            </Link>
                            {/* <Link to="/inventory">
                                <div className={`rmmobile  mask2 ${this.props.lang}`} style={{ width: 117 }}>
                                    <div className="mask-inner">
                                        INVENTORY
                                    </div>
                                </div>
                            </Link> */}
                            {/* <Link to="/buy">
                                <div className={`rmmobile  mask2 ${this.props.lang}`} style={{ width: 117 }}>
                                    <div className="mask-inner">
                                        PURCHASE
                                    </div>
                                </div>
                            </Link> */}
                        </div>
                    </div>
                </div>
                <div className={`woedexwrap ${this.state.color}`}>
                    <div id="woe-image-capture">
                        <img className="main" alt="main" src={this.state.image} />
                        <img className="gold" alt="golden main" src={this.state.image2} />
                    </div>
                    <div className="dex-wrap">
                        <div className="dex-cont">
                            <h1 className="woedex-type">{this.state.rarity} {this.state.typez}</h1>
                            <h1 className="woedex-name">{this.state.name}</h1>
                            {/* {this.state.discoveredby ? <h1 className="woedex-disc">{this.state.discoveredby}</h1> : null} */}
                            <div className="woedex-info">
                                {this.state.description}
                            </div>
                        </div>
                    </div>
                    <h1 id="watermark">{this.state.name}</h1>
                    <div id="woe-image-capture">
                        <img className="main" alt="main" src={this.state.image} />
                        <img className="gold" alt="golden main" src={this.state.image2} />
                    </div>
                </div>
                <div className="slide-controler">
                    <div className="slidnav">
                        {this.sliderCreate()}
                    </div>
                </div>
                <div className="arrow-container">
                    <img alt="down arrow" className="arrowme" src="https://woe2.s3.amazonaws.com/chevron-down.png" />
                </div>
            </>
        );
    }
}