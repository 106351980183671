import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import * as lang from '../../lang';

export default class NoMatchPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false
        }

    }

    render() {
        return (
            < div id="noMatch" >
                <Helmet>
                    <title>404 | World of Ether</title>
                </Helmet>
                <div className="jumbo smalljumbo">
                    <div className="starlight">
                        <div className="wrap">
                            <div id="navigation">
                                <div id="logo">
                                    <Link to="/">
                                        <img src="https://woe2.s3.amazonaws.com/logo.png" alt="logo" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="bigztext">
                    <h1 className="maintxtare">404</h1>
                    <img src="https://woe2.s3.amazonaws.com/monsters/184g/full.png" alt="etherian num 184" />
                    <h1 className={`subtxtare ${this.props.lang}`}>
                        {lang.seemstobeerror[this.props.lang]}
                    </h1>
                    <Link to="/"><div className={`viewbutton nomatch ${this.props.lang}`}>{lang.takemeback[this.props.lang]}</div></Link>
                </div>
            </div >
        )
    }
}