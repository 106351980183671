import React from 'react';
import { Link } from 'react-router-dom';

export default class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenCount: 100,
            targetWallet: "",
        }
    }

    changeWallet(e) {
        let val = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        this.setState({ targetWallet: val.toLowerCase() })
    }

    changeCount(e) {
        let val = e.target.value.replace(/[^0-9]/g, '');
        this.setState({ tokenCount: val });
    }


    async woeSetState(quant, buffer) {
        await this.props.drizzle.contracts.Woe.methods.setState(1).send();
    }

    async adminMint(tokenId) {
        await this.props.drizzle.contracts.Crystals.methods.adminMint(
            this.state.targetWallet,
            tokenId,
            this.state.tokenCount
        ).send();
    }

    render() {
        return (
            <div className="whiteout">
                <Link to="/">Page list</Link>
                <h2 style={{ fontSize: 35, marginTop: 50 }}>Admin Page</h2>
                <br />
                <br />
                <p>Admin mint tokens</p>
                <input className="someInp"
                    value={this.state.targetWallet}
                    onChange={(e) => this.changeWallet(e)}
                    placeholder='0x123...' />
                <input className="someInp alt"
                    value={this.state.tokenCount}
                    onChange={(e) => this.changeCount(e)}
                />
                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.adminMint(1) }}>
                    <span role="img" aria-label="Sun">☀️</span>
                </div>
                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.adminMint(2) }}>
                    <span role="img" aria-label="Tide">🌊</span>
                </div>
                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.adminMint(3) }}>
                    <span role="img" aria-label="Broccoli">🥦</span>
                </div>
                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.adminMint(4) }}>
                    <span role="img" aria-label="Skull">💀</span>
                </div>
                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.adminMint(5) }}>
                    <span role="img" aria-label="Crystal Ball">🔮</span>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="w-button" onClick={() => this.woeSetState()}>Set Contract state: Live</div>
            </div>
        )
    }
}