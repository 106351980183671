import React from 'react';
import { Link } from 'react-router-dom';
import { isNullOrUndefined } from 'util';

var loadingTimer;

export default class CreationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faction: isNullOrUndefined,
            sunTokens: 0,
            oceanTokens: 0,
            lifeTokens: 0,
            deathTokens: 0,
            astralTokens: 0,
            playerFaction: 0,
            loading: true,
        }
    }

    renderPlayerData() {
        return (
            <>
                <h2>Token Balance</h2>
                <br />
                <h2>
                    <span role="img" aria-label="Sun">☀️</span>
                    {this.state.sunTokens} &nbsp;

                    <span role="img" aria-label="Tide">🌊</span>
                    {this.state.oceanTokens} &nbsp;

                    <span role="img" aria-label="Broccoli">🥦</span>
                    {this.state.lifeTokens} &nbsp;

                    <span role="img" aria-label="Skull">💀</span>
                    {this.state.deathTokens} &nbsp;

                    <span role="img" aria-label="Crystal Ball">🔮</span>
                    {this.state.astralTokens}</h2> &nbsp;

                <br />
            </>
        )
    }

    async getData() {
        if (window) {
            if (window.web3) {
                if (window.ethereum) {
                    try {
                        let playerFaction = await this.props.drizzle.contracts.Woe.methods.faction(this.props.drizzleState.accounts[0]).call();
                        let sunTokens = await this.props.drizzle.contracts.Crystals.methods._balanceOf(this.props.drizzleState.accounts[0], 1).call();
                        let oceanTokens = await this.props.drizzle.contracts.Crystals.methods._balanceOf(this.props.drizzleState.accounts[0], 2).call();
                        let lifeTokens = await this.props.drizzle.contracts.Crystals.methods._balanceOf(this.props.drizzleState.accounts[0], 3).call();
                        let deathTokens = await this.props.drizzle.contracts.Crystals.methods._balanceOf(this.props.drizzleState.accounts[0], 4).call();
                        let astralTokens = await this.props.drizzle.contracts.Crystals.methods._balanceOf(this.props.drizzleState.accounts[0], 5).call();
                        this.setState({
                            sunTokens: sunTokens,
                            oceanTokens: oceanTokens,
                            lifeTokens: lifeTokens,
                            deathTokens: deathTokens,
                            astralTokens: astralTokens,
                            playerFaction: playerFaction,
                            loading: false
                        })
                    } catch (error) {
                        // console.log(error)
                    }
                }
            }
        }
    }

    async componentDidMount() {
        loadingTimer = setInterval(async () => {
            this.getData();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(loadingTimer);
    }

    async lifeBirth(faction) {
        await this.props.drizzle.contracts.Crystals.methods.life(faction, 1).send();
    }

    renderSingleButton() {
        if (this.state.playerFaction === "1") {
            return <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth(1) }}>
                <span role="img" aria-label="Sun">☀️</span>
            </div>
        } else if (this.state.playerFaction === "2") {
            return <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth(2) }}>
                <span role="img" aria-label="Tide">🌊</span>
            </div>
        } else if (this.state.playerFaction === "3") {
            return <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth(3) }}>
                <span role="img" aria-label="Broccoli">🥦</span>
            </div>
        } else if (this.state.playerFaction === "4") {
            return <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth(4) }}>
                <span role="img" aria-label="Skull">💀</span>
            </div>
        } else if (this.state.playerFaction === "5") {
            return <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth(5) }}>
                <span role="img" aria-label="Crystal Ball">🔮</span>
            </div>
        }
    }

    async astralButton(x){
        await this.props.drizzle.contracts.Crystals.methods.astral(x, parseInt(this.state.playerFaction ), 1).send();
    }

    renderButtons(){
        if(this.state.playerFaction === "0"){
            return(<>
            <h1>Mint with <span role="img" aria-label="Crystal Ball">🔮</span> Tokens </h1>
            <p> It should be below but just choose a faction first makes it easier...</p>
            </>);
        }

        return (<>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>Mint with <span role="img" aria-label="Crystal Ball">🔮</span> Tokens (price varies) </h1>
            <br/>
            <div className="w-button" style={{ float: 'left',  paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.astralButton(1) }}>
                Uncmn 300
            </div>
            <div className="w-button" style={{ float: 'left',  paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.astralButton(2) }}>
                Rare 1000
            </div>
            <div className="w-button" style={{ float: 'left',  paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.astralButton(3) }}>
                Lgnd 10000
            </div>
            </>)
    }
    
    render() {
        return (
            <div className="whiteout">
                <Link to="/">Page list</Link>
                <h2 style={{ fontSize: 35, marginTop: 50 }}>Creation Page</h2>
                <br />
                {this.state.loading ?
                    <><h2>loading...</h2></> :
                    <>
                        {this.renderPlayerData()}
                        <br />
                        <br />
                        <h1>Mint with <span role="img" aria-label="Broccoli">🥦</span> Tokens (200 needed)</h1>
                        {this.state.playerFaction === "0" ?
                            <>  
                                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth("1") }}>
                                    <span role="img" aria-label="Sun">☀️</span>
                                </div>
                                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth("2") }}>
                                    <span role="img" aria-label="Tide">🌊</span>
                                </div>
                                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth("3") }}>
                                    <span role="img" aria-label="Broccoli">🥦</span>
                                </div>
                                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth("4") }}>
                                    <span role="img" aria-label="Skull">💀</span>
                                </div>
                                <div className="w-button" style={{ float: 'left', minWidth: 0, width: 30, paddingLeft: 10, paddingRight: 10 }} onClick={() => { this.lifeBirth("5") }}>
                                    <span role="img" aria-label="Crystal Ball">🔮</span>
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>

                            </> :
                            <>{this.renderSingleButton()}</>
                        }
                        {this.renderButtons()}

                    </>
                }
                <br />
                <br />
                <br />
                <br />

                <div className="w-button" onClick={() => { console.log(this.state) }}>State Dump</div>
            </div>

        )
    }
}