import React from 'react';
import { Link } from 'react-router-dom';

export default class VaultPage extends React.Component {

    render() {
        return (
            <div className="whiteout">
                <Link to="/">Page list</Link>
                <h2 style={{ fontSize: 35, marginTop: 50 }}>Vault Page</h2>
            </div>
        )
    }
}