import React from 'react';
import { Link } from 'react-router-dom';

export default class PageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false
        }
    }

    render() {
        return (
            <>
                <div style={{ width: 1000, marginLeft: "auto", marginRight: "auto", marginBottom: 100 }}>
                    <h2 style={{ fontSize: 35, marginTop: 100 }}>Page list</h2>
                    <br /><hr />
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/home"> home</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/roadmap"> roadmap</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/woedex"> woedex</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/faq"> faq</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/whitelist"> whitelist</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/whitelistsubmit"> whitelistsubmit</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/legacy"> legacy</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/priv"> priv</Link></u></div>
                    <br /><hr />
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/buy"> buy</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/inventory"> inventory</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/staking"> staking</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/activity"> activity</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/admin"> admin</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/creation"> creation</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/pvp"> pvp</Link></u></div>
                    <div style={{ fontSize: 25, marginTop: 20 }}><u><Link to="/vault"> vault</Link></u></div>
                </div>
            </>
        );
    }
}