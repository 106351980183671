import React from "react";
import ScrollToTop from './components/common/ScrollToTop';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import MyDrizzle from './components/MyDrizzle';
import Woe from './contracts/Woe.json'
import Crystals from './contracts/Crystals.json'
import Game from './contracts/Game.json'
import { createMemoryHistory } from "history";
import { DrizzleContext } from "drizzle-react";
import { Drizzle, generateStore } from "drizzle";

const options = {
    contracts: [
        Woe,
        Crystals,
        Game
    ]
};

const drizzleStore = generateStore(options);
var drizzle;
var loadingTimer;

export default class SiteWrap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLocked: true,
        }
    }

    componentDidMount = async () => {
        loadingTimer = setInterval(async () => {
            if (window) {
                if (window.web3) {
                    if (window.ethereum) {
                        try {
                            drizzle = new Drizzle(options, drizzleStore);
                            clearInterval(loadingTimer);
                            this.setState({ isLocked: false });
                        } catch (error) {
                            if (error.code === 4001) {
                                console.log('user rejected to connect')
                            } else {
                                // console.log(error)
                            }
                        }
                    }
                } else {
                    if (window.gtag) {
                        window.gtag('set', 'user_properties', { hasMetamask: false, ethereumAddress: 'null' })
                    } else {
                        // console.log("cannot set BLANK properties as there is no window.gtag");
                    }
                }
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(loadingTimer);
    }

    async unlockAcct() {
        if (window) {
            if (!window.web3) {
                return false;
            }
            return await window.ethereum.request({ method: 'eth_requestAccounts' }).then((f) => {
                return f;
            }).catch((x) => {
                return false;
            });
        }
    }

    render() {
        const hashHistory = createMemoryHistory()
        if (this.state.isLocked) {
            return (
                <Router history={hashHistory}>
                    <ScrollToTop>
                        <AppRouter
                            isLocked={this.state.isLocked}
                            unlockAcct={() => this.unlockAcct()} />
                    </ScrollToTop>
                </Router>
            )
        } else {
            return (
                <DrizzleContext.Provider drizzle={drizzle}>
                    <Router history={hashHistory}>
                        <ScrollToTop>
                            <MyDrizzle
                                isLocked={this.state.isLocked}
                                unlockAcct={() => this.unlockAcct()} />
                        </ScrollToTop>
                    </Router>
                </DrizzleContext.Provider>
            )
        }
    }
}