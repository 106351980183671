// import * as serviceWorker from './serviceWorker';
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
import React from 'react';
import './styles/styles.scss'; //Webpack can import CSS files too!
import { render } from 'react-dom';
import SiteWrap from './SiteWrap';

render(<SiteWrap />, document.getElementById('root'));
